import { singularAndPlural } from "utils/plural-utils";
import { isArray, upperFirst } from "lodash";

export const createStamper = () => {
  const variations =  new Map();

  const configure = (context = '', config = {}) => {
    Object.keys(config).forEach(configKey => {
      const getVal = () => {
        const currentVal = config[configKey];
        if (isArray(currentVal)) {
          return currentVal
        }
        return singularAndPlural(currentVal);
      }

      const mapKey = `${context}.${configKey}`;
      variations.set(mapKey, getVal());

    })
  };

  const fallbackLabel = (label, key) => {
    if (!label) {
      console.log('Rótulo não encontrado:', `"${key}"`)
      return '__RÓTULO_NÃO_ENCONTRADO__';
    }
    return label ;
  };

  const getVariations = (key, shouldBeInPlural = false) => {
    const [singular, plural] = variations?.get?.(key) ?? ['', ''];
    
    return fallbackLabel(shouldBeInPlural ? plural : singular, key) ;
  };

  const concatenate = (keys = [], shouldBeInPlural = false) => {
    return keys.map((key) => getVariations(key, shouldBeInPlural)).join(' ');
  }

  const stamp = (...keys) => concatenate(keys);
  const Stamp = (...keys) => upperFirst(concatenate(keys));
  const STAMP = (...keys) => concatenate(keys).toUpperCase() ;

  const stamps = (...keys) => concatenate(keys, true);
  const Stamps = (...keys) => upperFirst(concatenate(keys, true));
  const STAMPS = (...keys) => concatenate(keys, true).toUpperCase() ;

  return {
    configure,
    stamp,
    Stamp,
    STAMP,
    stamps,
    Stamps,
    STAMPS
  };
}
