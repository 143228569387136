import { deviceBreakpoints, deviceList } from 'ksk/styledComponents/mixins';
import { useEffect, useState } from 'react';
import { capitalize } from 'lodash';

const isDevice = name => `is${capitalize(name)}`

const deviceChecklist = deviceList.reduce((accumulator, actual) => 
  ({...accumulator, 
    [isDevice(actual)]: (width) => {
      const [min, max] = deviceBreakpoints[actual];
      return width >= min && width < max;
    }
  }), {});

  const devicesInitialState = deviceList.reduce((accumulator, actual) => 
  ({...accumulator, 
    [isDevice(actual)]: false
  }), {});

// Hook
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
    ...devicesInitialState
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      const devices = deviceList.map(device => ({[isDevice(device)]: deviceChecklist[isDevice(device)](window.innerWidth)}))
                .reduce((accumulator, actual) => ({...accumulator, ...actual}), {})


      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        ...devices
        /* devices: deviceList.map(device => [device, deviceChecklist[device](window.innerWidth)])
                           .filter(([,match]) => match)
                           .map(([name]) => name).join(' ') */
      });
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default useWindowSize;