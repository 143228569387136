import { useEffect } from "react";
import useStamper from "./useStamper";
import appLocales from "./appLocales";
import { useUpdate } from "react-use";


function useLocalizeStamper({locale = appLocales.PT_BR, context, config}) {
  const { localize } = useStamper();
  const update = useUpdate();

  useEffect(() => {
    localize(locale, context, config);
    update();
  }, [locale, context]); 

  return (null);
}

export default useLocalizeStamper
