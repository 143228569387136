import { isFunction } from "lodash";

export const createMessageStore = () => {
  const store =  new Map();

  const configure = (context = '', config = {}) => {
    Object.keys(config).forEach(configKey => {
  
      const mapKey = `${context}.${configKey}`;
      const messageFn = config[configKey]; 

      if (!isFunction(messageFn)) {
        throw new Error(`MessageStore: mensagem '${mapKey}' deve ser uma função.`);
      }

      store.set(mapKey, messageFn);
    })
  };

  const getMessage = (key, args) => {
    const msgFn = store?.get?.(key);

    if (!msgFn) {
      return '__MENSAGEM_NÃO_ECONTRADA__'
    }

    return msgFn(args);
  };

  const message = (key, args) => getMessage(key, args);

  return {
    configure,
    message
  };
}
