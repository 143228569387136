
import React, { useContext, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import useKskId from 'ksk/hooks/useKskId';
import { isFunction } from 'lodash';

// Cria um contexto para o KskForm
const KskFormContext = React.createContext();

/**
 * Hook para capturar o contexto do KskForm.
 * @returns 
 */
export const useKskForm = () => {
  return useContext(KskFormContext);
}

/**
 * Hook para a construção de um id dinâmico a partir do id do form.
 * @param {*} param0 
 * @returns 
 */
export const useBuildInputId = ({id, name}) => {
  const { formId } = useContext(KskFormContext) || {};
  return  formId ? `${formId}__${ id ? id : name}` : id;  
}

/**
 * Método que evita que o Hook form dispare eventos de submit nativos.
 * @param {*} handler 
 * @returns 
 */
const isolateFormSubmit = (handler) => (e) => {
  // Matar a submissão do form. Evita situações não previstas.
  e.preventDefault();
  e.stopPropagation();

  
  // Dispara a submissão do ReactHookForm. Todo o tratamento
  // fica a cargo do ReactHookForm.
  if(isFunction(handler)) {
    handler(e);
  }  
}

/**
 * KskForm é um formulário em que os inputs ksk devem estar vinculados.
 * Possui um contexto com informações e utilitários que os descendentes
 * podem utilizar.
 * @param {*} param0 
 * @returns 
 */
const KskForm = ({children, id = '', onKeyDown, onSubmit, submitOnShiftEnter, ...otherProps}) => {
  const submitHandler = isolateFormSubmit(onSubmit);
  const formRef = useRef();

  // Método que permite os descendentes do contexto a chamar o submit
  // do form
  const dispatchSubmit = () => {
    if (!formRef.current) return;

    const formElement = formRef.current;
  
    // Crie um evento de submit
    const submitEvent = new Event('submit', {
      bubbles: true,
      cancelable: true,
    });
  
    // Dispare o evento no elemento form
    formElement.dispatchEvent(submitEvent);
  };

  // Id dinâmico que será usado caso não seja passado id
  const fallbackId = useKskId('ksk-form'); 

  // Id será o passado como parâmetro, caso não exista será o fallbackid
  const _id = id || fallbackId;

  // Valor do contexto memoizado.
  const [contextValue] = useState({
    id: _id, 
    formId: _id,
    formRef,
    submitOnShiftEnter,
    dispatchSubmit,
  }, [_id]);

  return( 
    <KskFormContext.Provider value={contextValue}> {/* id e formId são sinônimos */}
      <form ref={formRef} id={id} onSubmit={submitHandler} /* onKeyDown={onKeyDownHandler} */ {...otherProps}>
        {children}
      </form>
    </KskFormContext.Provider>
  );
}

// Texto padrão a ser colocado nos formulários que tiverem suporte ao submit com
// shift + enter
export const ShiftEnterHelper = React.memo(() => {
  return (
    <div className='ksk-form__helper'>
      <FontAwesomeIcon icon={faInfoCircle} className='ksk-form__helper-info' />
      <span>Atalho para o botão Pesquisar: pressione <span className="bold">Shift + Enter</span> em qualquer campo.</span>
    </div>
  )
})


export default KskForm;