import { lazy } from 'react';
import { AUT_EMIT, ADMIN, CONDICAO, DEM_JUD, FINALIDADE, LIMITE, MODELO_QUADRO, NORMATIVO, PARAM_CALCULO, PERFIL, PROGRAMA, SIM_LIMITE, TP_ANALISE, COC, LISTAS, CONSULTA_DOCUMENTO, PVL } from './backend-features.config';
import { CON_AUT_EMIT, CON_CONDICAO, CON_DEM_JUD, CON_FINALIDADE, CON_LIMITE, CON_MODELO_QUADRO, CON_NORMATIVO, CON_PARAM_CALCULO, CON_PERFIL, CON_PROGRAMA, CON_SIM_LIMITE, CON_TP_ANALISE, CON_COC, CON_LISTAS, ALT_PERFIL, CON_CONSULTA_DOCUMENTO, CON_PUBLICO_NORMATIVO, CON_PUBLICO_DEM_JUD, CON_PUBLICO_ELEMENTO, CON_PVL } from './backend-siglas.config';

const lazyLoader = {
  Home: lazy(() => import('pages/home/Home')),
  Login: lazy(() => import('pages/login/Login')),
  Logout: lazy(() => import('pages/logout/Logout')),
  LoginErro: lazy(() => import('pages/login_erro/LoginErro')),
  PageNotFound: lazy(() => import('pages/page_notfound/PageNotFound')),
  Finalidade: lazy(() => import('pages/finalidade/Finalidade')),
  AutoridadeEmitente: lazy(() => import('pages/autoridade_emitente/AutoridadeEmitente')),
  Normativo: lazy(() => import('pages/normativo/Normativo')),
  Admin: lazy(() => import('pages/admin/Admin')),
  Condicao: lazy(() => import('pages/condicao/Condicao')),
  Limite: lazy(() => import('pages/limite/Limite')),
  Programa: lazy(() => import('pages/programa/Programa')),
  TipoAnalise: lazy(() => import('pages/tipo_analise/TipoAnalise')),
  DemandaJudicial: lazy(() => import('pages/demanda_judicial/DemandaJudicial')),
  ParametroCalculo: lazy(()=> import('pages/parametro_calculo/ParametroCalculo')),
  ModeloQuadroAnalise: lazy(()=> import('pages/modeloquadroanalise/ModeloQuadroAnalise')),
  LimiteSimulacao: lazy(()=> import('pages/limite_simulacao/LimiteSimulacao')),
  Perfil: lazy(()=> import('pages/perfil/Perfil')),
  COC: lazy(()=> import('pages/coc/COC')),
  Listas: lazy(() => import('pages/listas/Listas')),
  ConsultaDocumento: lazy(() => import('pages/consulta_documento/ConsultaDocumento')),
  PVL: lazy(() => import('pages/pvl/Pvl')),
  // CONSULTAS PUBLICAS
  ConsultaPublica: lazy(() => import('pages/consulta_publica/ConsultaPublica')),
  ConsultaPublicaDocumento: lazy(() => import('pages/consulta_documento/ConsultaDocumentoPublico')),
}

export const features = [
  {
    id: 'HOME',
    subject: {
      name: 'Página inicial'
    },
    name: 'Página inicial',
    description: 'Página inicial do Módulo Operações de Crédito',
    route: { url: '/', path: '/', element: lazyLoader.Home },
    hasPermission: () => true,
    backendId: '',
    requires: [],
    auditable: false
  },

  {
    id: 'LOGIN',
    subject: {
      name: 'Página login'
    },
    name: 'Página login',
    description: 'Página de login do módulo Operações de Crédito e CDP',
    route: { url: 'Login', path: 'Login/*', element: lazyLoader.Login },
    hasPermission: () => true,
    backendId: '',
    requires: [],
    auditable: false
  },

  {
    id: 'LOGINERRO',
    subject: {
      name: 'Página acesso negado'
    },
    name: 'Página acesso negado',
    description: 'Página de acesso negado do Módulo Operações de Crédito',
    route: { url: 'Negado', path: 'Negado/*', element: lazyLoader.LoginErro },
    hasPermission: () => true,
    backendId: '',
    requires: [],
    auditable: false
  },

  {
    id: 'PAGE_NOT_FOUND',
    subject: {
      name: 'Página não encontrada'
    },
    name: 'Página não encontrada',
    description: 'Página não encontrada no Módulo Operações de Crédito',
    route: { url: 'page-notfound', path: 'page-notfound/*', element: lazyLoader.PageNotFound },
    hasPermission: () => true,
    backendId: '',
    requires: [],
    public:true,
    auditable: false
  },

  {
    id: 'LOGOUT',
    subject: {
      name: 'Página de logout'
    },
    name: 'Página logout',
    description: 'Página de logout do Módulo Operações de Crédito',
    route: { url: 'Logout', path: 'Logout/*', element: lazyLoader.Logout },
    hasPermission: () => true,
    backendId: '',
    requires: [],
    auditable: false
  },

  {
    id: 'FINALIDADE',
    subject: {
      name: 'Finalidade',
      plural: 'Finalidades'
    },
    name: 'Finalidades',
    description: 'Consultar, Criar ou Editar Finalidades',
    route: { url: 'Finalidade', path: 'Finalidade/*', element: lazyLoader.Finalidade },
    hasPermission: () => true,
    backendId: FINALIDADE,
    requires: [CON_FINALIDADE],
    auditable: false
  },

  {
    id: 'AUTORIDADE_EMITENTE',
    subject: {
      name: 'Autoridade emitente',
      plural: 'Autoridades emitentes'
    },
    name: 'Autoridades emitentes',
    description: 'Consultar, Criar ou Editar Autoridade Emitente',
    route: { url: 'AutoridadeEmitente', path: 'AutoridadeEmitente/*', element: lazyLoader.AutoridadeEmitente },
    hasPermission: () => true,
    backendId: AUT_EMIT,
    requires: [CON_AUT_EMIT],
    auditable: false
  },

  {
    id: 'ADMIN',
    subject: {
      name: 'Admin',
      plural: 'Admin',
      detailsMsg: ' e Dispositivos associados'

    },
    name: 'Admin',
    description: 'Admin',
    route: { url: 'Admin', path: 'Admin/*', element: lazyLoader.Admin },
    hasPermission: () => true,
    backendId: ADMIN,
    requires: [ALT_PERFIL],
    auditable: false
  },

  {
    id: 'NORMATIVO',
    subject: {
      name: 'Normativo',
      plural: 'Normativos',
      detailsMsg: ' e Dispositivos associados'

    },
    name: 'Normativos',
    description: 'Consultar, Criar ou Editar Normativos',
    route: { url: 'Normativo', path: 'Normativo/*', element: lazyLoader.Normativo },
    hasPermission: () => true,
    backendId: NORMATIVO,
    requires: [CON_NORMATIVO],
    auditable: false
  },

  {
    id: 'CONDICAO',
    subject: {
      name: 'Condição',
      plural: 'Condições',
      detailsMsg: ', Normativos/Dispositivos, Abrangências e Detalhes associados'
    },
    name: 'Condições',
    description: 'Consultar, Criar ou Editar Condições',
    route: { url: 'Condicao', path: 'Condicao/*', element: lazyLoader.Condicao },
    hasPermission: () => true,
    backendId: CONDICAO,
    requires: [CON_CONDICAO],
    auditable: false
  },

  {
    id: 'LIMITE',
    subject: {
      name: 'Limite',
      plural: 'Limites',
      detailsMsg: ', Normativos/Dispositivos e Abrangências associados'
    },
    name: 'Limites',
    description: 'Consultar, Criar ou Editar Limites',
    route: { url: 'Limite', path: 'Limite/*', element: lazyLoader.Limite },
    hasPermission: () => true,
    backendId: LIMITE,
    requires: [CON_LIMITE],
    auditable: false
  },

  {
    id: 'PROGRAMA',
    subject: {
      name: 'Programa',
      plural: 'Programas',
      detailsMsg: ' e Normativos/Dispositivos associados'

    },
    name: 'Programas',
    description: 'Consultar, Criar ou Editar Programas',
    route: { url: 'Programa', path: 'Programa/*', element: lazyLoader.Programa },
    hasPermission: () => true,
    backendId: PROGRAMA,
    requires: [CON_PROGRAMA],
    auditable: false
  },

  {
    id: 'TIPO_ANALISE',
    subject: {
      name: 'Tipo de análise',
      plural: 'Tipos de análise'

    },
    name: 'Tipos de análise',
    description: 'Consultar, Criar ou Editar Tipos de Análise',
    route: { url: 'TipoAnalise', path: 'TipoAnalise/*', element: lazyLoader.TipoAnalise },
    hasPermission: () => true,
    backendId: TP_ANALISE,
    requires: [CON_TP_ANALISE],
    auditable: false
  },

  {
    id: 'DEMANDA_JUDICIAL',
    subject:{
      name: 'Demanda judicial',
      plural: 'Demandas judicial'
    },
    name: 'Demandas judiciais',
    description: 'Consultar, Criar ou Editar Demandas Judicial',
    route: { url: 'DemandaJudicial', path: 'DemandaJudicial/*', element: lazyLoader.DemandaJudicial },
    hasPermission: () => true,
    backendId: DEM_JUD,
    requires: [CON_DEM_JUD],
    auditable: false
  },

  {
    id:'PARAMETRO_CALCULO',    
    subject:{
      name: 'Parâmetro de cálculo',
      plural: 'Parâmetros de cálculos'
    },
    name:'Parâmetros de cálculo',
    description:'Consultar, criar, editar e remover parâmetros cálculos ',
    route: {url:'ParametroCalculo', path:'ParametroCalculo/*', element:lazyLoader.ParametroCalculo},
    hasPermission: () => true,
    backendId: PARAM_CALCULO,
    requires: [CON_PARAM_CALCULO],
    auditable: false
  },
  {
    id:'MODELOQUADROANALISE',    
    subject:{
      name: 'Quadro de análise',
      plural: 'Quadros de análise'
    },
    name:'Quadros de análise',
    description:'Consultar, criar, editar e remover quadro de análise ',
    route: {url:'ModeloQuadroAnalise', path:'ModeloQuadroAnalise/*', element:lazyLoader.ModeloQuadroAnalise},
    hasPermission: () => true,
    backendId: MODELO_QUADRO,
    requires: [CON_MODELO_QUADRO],
    auditable: false
  },
  {
    id:'LIMITE_SIMULACAO',    
    subject:{
      name: 'Simular limite',
      plural: 'Simular limites'
    },
    name:'Simular limites',
    description:'Simular anáĺise de limite ',
    route: {url:'LimiteSimulacao', path:'LimiteSimulacao/*', element:lazyLoader.LimiteSimulacao},
    hasPermission: () => true,
    backendId: SIM_LIMITE,
    requires: [CON_SIM_LIMITE],
    auditable: false
  },
  {
    id:'PERFIL',    
    subject:{
      name: 'Perfil',
      plural: 'Perfis'
    },
    name:'Permissões',
    description:'Gerenciar perfis ',
    route: {url:'Perfil', path:'Perfil/*', element:lazyLoader.Perfil},
    hasPermission: () => true,
    backendId: PERFIL,
    requires: [CON_PERFIL],
    auditable: false
  },
  {
    id:'COC',    
    subject:{
      name: 'Operação de crédito',
      plural: 'Operações de crédito'
    },
    name:'Operações de crédito',
    description:'Gerenciar cadastro de operações de crédito ',
    route: {url:'COC', path:'COC/*', element:lazyLoader.COC},
    hasPermission: () => true,    
    backendId: COC,
    requires: [CON_COC],
    auditable: true
  },
  {
    id:'PVL',    
    subject:{
      name: 'PVL',
      plural: 'PVLs'
    },
    name:'PVL',
    description:'Pedidos de verificação de limites e condições ',
    route: {url:'PVL', path:'PVL/*', element:lazyLoader.PVL},
    hasPermission: () => true,
    backendId: PVL,
    requires: [CON_PVL],
  },
  {
    id: 'LISTAS',
    subject: {
      name: 'Lista',
      plural: 'Listas',
    },
    name: 'Listas',
    description: 'Consultar ou editar listas',
    route: { url: 'Listas', path: 'Listas/*', element: lazyLoader.Listas },
    hasPermission: () => true,
    backendId: LISTAS,
    requires: [CON_LISTAS],
    auditable: false
  },

  {
    id:'CONSULTA_DOCUMENTO',    
    subject:{
      name: 'Consulta documento',
      plural: 'Consulta documentos'
    },
    name:'Documentos',
    description:'Consultar documentos que estão armazenados',
    route: { url:'ConsultaDocumento', path:'ConsultaDocumento/*', element:lazyLoader.ConsultaDocumento},
    hasPermission: () => true,
    backendId: CONSULTA_DOCUMENTO,
    requires: [CON_CONSULTA_DOCUMENTO],
    auditable: false    
  },

  // CONSULTAS PUBLICAS

  {
    id:'CONSULTA_PUBLICA',    
    subject:{
      name: 'Consulta pública',
      plural: 'Consultas públicas'
    },
    name:'Consultas públicas',
    description:'Consultar entidades que não apresentam restrição de acesso',
    route: { url:'consultas-publicas', path:'consultas-publicas/*', element:lazyLoader.ConsultaPublica},
    hasPermission: () => false,
    backendId: '',
    requires: [CON_PUBLICO_NORMATIVO,CON_PUBLICO_DEM_JUD],    
    public:true,
    auditable: false
  },

  {
    id:'CONSULTA_PUBLICA_DOCUMENTO',    
    subject:{
      name: 'Consulta documento',
      plural: 'Consulta documentos'
    },
    name:'Documentos',
    description:'Detalhar documento a partir do identificador Siconfi',
    route: { url:'ver', path:'ver/:id', element:lazyLoader.ConsultaPublicaDocumento},
    hasPermission: () => false,
    backendId: '',
    requires: [CON_CONSULTA_DOCUMENTO,CON_PUBLICO_ELEMENTO],
    public:true,
    auditable: false
  },

];

export const getFeature = (id) => features.find(f => f.id === id);
export const findFeature = (findFn) => features.find(f => findFn(f));