import Protected from 'auth/Protected';
import accessDeniedIcon from 'ksk/assets/template/access-denied.svg';
import React from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from './AuthContext';

const AcessDenied = styled.div.attrs({className: 'access-denied'})`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4em;

  .access-denied__container {
    margin-top: 6em;
    background: #fff8;
    display: flex;
    grid-gap: 3em;
    justify-content: center;
    height: fit-content;
    padding: 3em;
    max-width: 52em;
    box-shadow: 0.25em 0.25em 0.25em #0005;
  }


  .access-denied__icon {
    width: 10rem;
  }

  .access-denied__h1 {
    font-size: 3rem;
    margin: auto 0;
    font-weight: 100;
  }

  .access-denied__p {
    margin: 1em 0 0 0;
  }
  
`;

const AccessDenied = () => {
  const { credentials, isAuthenticated } = useAuth();
  return(
    <AcessDenied>
      <div className="access-denied__container">
        <img className="access-denied__icon" src={accessDeniedIcon} alt="Ícone de acesso negado" />
        <div>
          <h1 className='access-denied__h1'>Acesso não autorizado</h1>
          <p className='access-denied__p'>O seu perfil {credentials?.nomePerfil} não tem permissão de acesso a esta funcionalidade.</p>
          {!isAuthenticated&&<p className='access-denied__p'>Se você necessita acessar este conteúdo, contate o <a href="https://e-servicos.tesouro.gov.br/#/public/homeUsuario/Area/266361" target="_blank">fale conosco</a></p>}
        </div>
      </div>
    </AcessDenied>
)};

function ProtectedRoute({requires, path,  ...otherRouteProps} = {}) {
  return (
    <Protected 
      fallback={<Route path={path} element={<AccessDenied/>}/>}
      requires={requires}>
      <Route path={path} {...otherRouteProps} />
    </Protected>
  );
}

export default ProtectedRoute;
