import { px2em } from 'ksk/styledComponents/functions';
import { kskTheme } from 'ksk/styledComponents/themes';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const hoverFilter = theme('mode', {
  padrao: 'brightness(.9)',
  altoContraste: 'brightness(.7)'
});

export const Div = styled.div.attrs(({ className }) => ({
  className: `ksk-button-container ${className || ''}`
}))`

    position: relative;
    display: inline-block;

  .ksk-button--primary .ksk-button__label {
    color: #F9FCFB;
  }

  .ksk-button {
    font-family: 'Source Sans Pro', sans-serif;
    position: relative;
    font-size: 1em;
    padding: 0 1.25em;
    min-width: ${px2em(100)};
    margin: 1em 0;
    border: none;
    border-radius: ${kskTheme.borderRadius};
    min-height: 2.75em;
    display: flex;
    align-items: center;
    justify-content: center;

    /** Secondary styles is default */
    background-color: ${kskTheme.secondaryColor};
    color: ${kskTheme.textOverSecondaryColor};

    .ksk-button__icon {
      position: absolute;
      height: 100%;
      width: 2.5em;
      background: rgba(0,0,0,0.15);
      top: 0;
      
      
      display: flex;
      justify-content: center;
      align-items: center;
    } 

    .ksk-button__icon--busy {
      animation: busy-animation .3s steps(6) infinite;
    }

    &--enabled {
      &:hover,
      &:active,
      &:focus {
        filter: ${hoverFilter};
      }

      &:active {
        top: 1px;
      }
    }

    &--disabled {
      opacity: .8;
      filter: saturate(0);
      cursor: not-allowed;
      font-style: italic;
    }

    &--primary {
      background-color: ${kskTheme.primaryColor};
      color: ${kskTheme.textOverPrimaryColor};
    }

    &--warn {
      background-color: ${kskTheme.warnColor};
      color: ${kskTheme.textOverWarnColor};
    }

    &--danger {
      background-color: ${kskTheme.dangerColor};
      color: ${kskTheme.textOverDangerColor};
    }

    &--info {
      background-color: ${kskTheme.infoColor};
      color: ${kskTheme.textInfoColor};
    }

    &--swap-colors {
      &.ksk-button--primary {
      color: ${kskTheme.primaryColor};
      background-color: ${kskTheme.textOverPrimaryColor};
      }

      &.ksk-button--warn {
        color: ${kskTheme.warnColor};
        background-color: ${kskTheme.textOverWarnColor};
      }

      &.ksk-button--danger {
        color: ${kskTheme.dangerColor};
        background-color: ${kskTheme.textOverDangerColor};
      }

      &.ksk-button--info {
        color: ${kskTheme.infoColor};
        background-color: ${kskTheme.textOverInfoColor};
      }
    }

    &--small, &--alert {
      font-size: .8em;
      padding: 0 .65em 0 .75em;
      margin: 0;
      min-width: ${px2em(50)};
      line-height: 1;
      min-height: 2em;

      > .ksk-button__icon {
        width: 2.15em;
      }
    }

    &--mini {
      font-size: .8em;
      padding: 0 .65em 0 .75em;
      margin: 0;
      min-width: ${px2em(50)};
      line-height: 1;
      min-height: 1.75em;

      > .ksk-button__icon {
        width: 2.15em;
      }
    }

    &--large {
      font-size: 1.2em;
    }

    &--with-icon {
      padding-left: 3.25em;

      .ksk-button__icon {
        left: 0;
        border-radius: ${kskTheme.borderRadius} 0 0 ${kskTheme.borderRadius};
      }
    }

    &--with-icon-right {
      padding-right: 3.25em;

      .ksk-button__icon {
        right: 0;
        border-radius: 0 ${kskTheme.borderRadius} ${kskTheme.borderRadius} 0;
      }
    }

    &--icon-button {
      min-width: 1.75em;
      min-height: 1.75em;
      // margin: 0 .25em;
      padding: 0;

      .ksk-button__icon {
        width: 1.75em;
        position: absolute;
      }

      &.ksk-button--icon-button.ksk-button--icon-button.ksk-button--icon-button {
        padding: 0;
      }
    }  
  }
  
  &.ksk-button--small.ksk-button--with-icon {
    padding-left: 2.5em;
  }

  &.ksk-button.ksk-button--mini.ksk-button--with-icon {
    padding-left: 2.5em;
  }

  &.ksk-button--small.ksk-button--with-icon-right {
    padding-right: 2.5em;
  }

  &.ksk-button.ksk-button--mini.ksk-button--with-icon-right {
    padding-right: 2.5em;
  }

  > .ksk-button:hover {
  } 

  &.ksk-button:disabled {
    opacity: .65;
    cursor: not-allowed;
  }
  
   > .ksk-button__effect {
     content: 's';
     position: absolute;
     display: block;
     top: 50%;
     left: 50%;
     border-radius: 50%;
     background-color: red;
     box-shadow: none;
     width: 1px;
     height: 1px;
     color: transparent;
   }  


   

  > .ksk-button__effect {
    content: 's';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background-color: red;
    box-shadow: none;
    width: 1px;
    height: 1px;
    color: transparent;
  } 

  .ksk-button__effect {
    content: 's';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background-color: red;
    box-shadow: none;
    width: 1px;
    height: 1px;
    color: transparent;
  } 

  .ksk-button__error-disclaimer {
    position: absolute;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: .35em;
    background: ${kskTheme.dangerColor};
    color: ${kskTheme.textOverDangerColor};
    bottom: -.95em;
    white-space: nowrap;
    font-size: ${px2em(11)};
    padding: .35em .5em;
    border-radius: ${kskTheme.borderRadius};
    align-items: center;
    line-height: 1;
  }

  .ksk-button__float-icon  {
    position: absolute;
    transform: scale(.65);

    &--top-left {
      top: 0.05em;
      left: 0.05em;
    }

    &--top-right {
      top: 0.05em;
      right: 0.05em;
    }

    &--bottom-right {
      bottom: 0.05em;
      right: 0.05em;
    }

    &--bottom-left {
      bottom: 0.05em;
      left: 0.05em;
    }
  }

  @keyframes busy-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(60deg);
    }
  }
`;

export const S = {
  Div
}


