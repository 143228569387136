import { hasDuplicates, removeEmpty } from './utils';
import { indexOf, isArray } from 'lodash';

export const ignoreNulls = (arrayStrings, ...valores) => {
  return arrayStrings.reduce((acumulador, string, i) => {
   return `${acumulador}${string}${valores[i] != null ? `${valores[i]}` : ''}`;
 }, '');
}

export function makeid(length, {prefix = ''} = {}) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `${prefix}${result}`;
}

export const buildSearchString = (entity) => new URLSearchParams(removeEmpty(entity)).toString();

export const numberToCharSequence = (number) => {
  const charCodeA = 'A'.charCodeAt(0);
  const charCodeB = 'Z'.charCodeAt(0);
  const length = charCodeB - charCodeA + 1;

  let charSequence = "";
  while(number >= 0) {
    charSequence = String.fromCharCode(number % length + charCodeA) + charSequence;
    number = Math.floor(number / length) - 1;
  }
  return charSequence;
}

export const charSequenceToNumber = (charSequence) => {
  const str = charSequence.toUpperCase();
  let number = 0, length = str.length;
  for (let pos = 0; pos < length; pos++) {
    number += (str.charCodeAt(pos) - 64) * Math.pow(26, length - pos - 1);
  }
  return number;
}

export class StringBuilder {
  arr = [];
  constructor(initialValue) {
    //console.log('sb', initialValue)
    this.arr = isArray(initialValue) ? [...initialValue] : [initialValue];
  }

  append(str) {
    this.arr = [...this.arr, str];
    return this;
  }

  prepend(str) {
    this.arr = [str, ...this.arr];
    return this;
  }

  appendIf(test, whenTrue) {
    if (test) {
      this.append(whenTrue);
    }
    return this;
  }

  prependIf(test, whenTrue) {
    if (test) {
      this.prepend(whenTrue);
    }
    return this;
  }

  appendIfOrElse(test, whenTrue, whenFalse) {
    if (test) {
      this.append(whenTrue);
    } else {
      this.append(whenFalse);
    }
    return this;
  }

  prependIfOrElse(test, whenTrue, whenFalse) {
    if (test) {
      this.prepend(whenTrue);
    } else {
      this.prepend(whenFalse);
    }
    return this;
  }

  clear() {
    this.arr = [];
    return this;
  }

  toString() {
    return this.arr.join('');
  }
}

export const normalize = (str = '') => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const isEquivalent = (str1 = '', str2 = '') => normalize(str1) === normalize(str2);

export const isUpperCase = (str = '') => str === str.toUpperCase();

export const containsTerms = ({source = '', terms = ''} = {}) => {
  const words = source?.trim?.()?.split(' ') ?? [];
  const filters = terms?.trim?.()?.split(' ') ?? [];

  const includesSubstring = (word, subString) => (
    normalize(word).toUpperCase()
      .includes(normalize(subString).toUpperCase())
  );
  
  const isContido = (filter) => words.some(word => includesSubstring(word, filter));
  
  return filters.every(filter => isContido(filter))
}

export function convertBase(str, fromBase, toBase) {
  //Credits: https://stackoverflow.com/a/55011290
  
  const DIGITS = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ+/";

  const add = (x, y, base) => {
      let z = [];
      const n = Math.max(x.length, y.length);
      let carry = 0;
      let i = 0;
      while (i < n || carry) {
          const xi = i < x.length ? x[i] : 0;
          const yi = i < y.length ? y[i] : 0;
          const zi = carry + xi + yi;
          z.push(zi % base);
          carry = Math.floor(zi / base);
          i++;
      }
      return z;
  }

  const multiplyByNumber = (num, x, base) => {
      if (num < 0) return null;
      if (num == 0) return [];

      let result = [];
      let power = x;
      while (true) {
          num & 1 && (result = add(result, power, base));
          num = num >> 1;
          if (num === 0) break;
          power = add(power, power, base);
      }

      return result;
  }

  const parseToDigitsArray = (str, base) => {
      const digits = str.split('');
      let arr = [];
      for (let i = digits.length - 1; i >= 0; i--) {
          const n = DIGITS.indexOf(digits[i])
          if (n == -1) return null;
          arr.push(n);
      }
      return arr;
  }

  const digits = parseToDigitsArray(str, fromBase);
  if (digits === null) return null;

  let outArray = [];
  let power = [1];
  for (let i = 0; i < digits.length; i++) {
      digits[i] && (outArray = add(outArray, multiplyByNumber(digits[i], power, toBase), toBase));
      power = multiplyByNumber(fromBase, power, toBase);
  }

  let out = '';
  for (let i = outArray.length - 1; i >= 0; i--)
      out += DIGITS[outArray[i]];

  return out;
}  

export const decompose = (str = '', delimiter = '') => {
  const index = str?.indexOf?.(delimiter) ?? -1;
  return [
    str.substring(0, index),
    str.substring(index + (delimiter?.length ?? 0))
  ];
}

export function blobToStringSync(blob) {
  var url, request;
  url = URL.createObjectURL(blob);
  request = new XMLHttpRequest();
  request.open('GET', url, false); // although sync, you're not fetching over internet
  request.send();
  URL.revokeObjectURL(url);
  return request.responseText;
}

/**
 * Extrai o nome da função e os argumentos de uma string JavaScript no formato "nomeFuncao(args)".
 *
 * @param {string} functionAsString - A string JavaScript no formato "nomeFuncao(args)".
 * @returns {[string, string[]]} Um array contendo o nome da função e uma array de argumentos, ou [undefined, undefined] se a string for inválida.
 */
export function getFunctionNameAndArguments(functionAsString) {
  // Define uma expressão regular para extrair o nome da função e os argumentos
  const regex = /(\w+)(?:\((.*?)\))?/;

  // Executa a expressão regular na string
  const match = functionAsString.match(regex);

  if (match) {
    // O nome da função é capturado no primeiro grupo de captura (match[1])
    const nomeFuncao = match[1];

    // Os argumentos são capturados no segundo grupo de captura (match[2])
    const args = match[2] ? match[2].split(',').map(arg => convertArg(arg.trim())) : undefined;

    // Retorna um array com o nome da função e os argumentos
    return [nomeFuncao, args];
  } else {
    // Retorna [undefined, undefined] se a expressão regular não encontrar correspondência
    return [undefined, undefined];
  }
}

/**
 * Converte um argumento para o tipo apropriado (string, number, boolean).
 *
 * @param {string} arg - O argumento a ser convertido.
 * @returns {string|number|boolean} O argumento convertido.
 */
function convertArg(arg) {
  if (/^(\d+(\.\d*)?|\.\d+)$/.test(arg)) {
    // Converte para número se for um número válido
    return Number(arg);
  } else if (/^['"](.*)['"]$/.test(arg)) {
    // Remove aspas simples ou duplas e mantém como string
    return arg.slice(1, -1);
  } else if (arg.toLowerCase() === 'true' || arg.toLowerCase() === 'false') {
    // Converte para boolean se for uma representação booleana
    return arg.toLowerCase() === 'true';
  } else {
    // Mantém como string por padrão
    return arg;
  }
}

export function getLastSplitFromUrl(url){
  const urlArray = url.split("/");
  return urlArray[urlArray.length-1]
}