import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import 'date-fns';
import { px2em } from './functions';
import { defaultPrimaryColor, defaultSecondaryColor, defaultThemeColor } from './themes';


const muiTheme = createMuiTheme({
  palette: {
    primary: { main: defaultPrimaryColor },
    secondary: { main: defaultSecondaryColor },
    
  },

  overrides: {
    /* MuiTab: {
      // general overrides for your material tab component here
      root: {
        backgroundColor: 'red',
        color: 'blue',
        '&$selected': {
          backgroundColor: 'blue',

        }
      },
    } */
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px) scale(.85)'
      }
    },
    MuiDialogTitle: {
      root: {
        background: defaultThemeColor,
        color: 'white'
      }
    }, 
    MuiChip: {
      root: {
        fontSize: px2em(12)
      }
    },
    MuiAutocomplete: {
      input: {
        minHeight: '20px'
      }
    },
    MuiFormHelperText: {
      root: {
        display: 'flex',
        flexDirection: "column",
        whiteSpace: 'pre-wrap'
      }
    }

    
  },
  
  props: {
    /* MuiTextField: {
      variant: 'filled',

    }, */
  },
}, ptBR);

export const MuiGlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '.MuiChip-root + .MuiChip-root.MuiChip-root': {
      marginLeft: '.5em',
    },

    ".MuiInputBase-input.Mui-disabled, .MuiInputBase-root.Mui-disabled, .MuiFormControlLabel-label.Mui-disabled.Mui-disabled"  : {
      color: 'rgba(0, 0, 0, 0.87)',
      cursor: 'not-allowed',
    },
    
    ".MuiRadio-colorPrimary.Mui-checked.Mui-disabled": {
      color: "hsl(164, 41%, 36%)",
      cursor: 'not-allowed',
    },

    ".MuiFormGroup-root[role=radiogroup] .MuiFormControlLabel-root.Mui-disabled": {
      cursor: 'not-allowed',
    },

    ".Mui-disabled .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input.MuiSelect-withChips > .MuiChip-root": {
      cursor: 'not-allowed',
    },

    ".MuiFormLabel-root.Mui-disabled, .MuiFormHelperText-root.Mui-disabled, .MuiFormControlLabel-label.Mui-disabled" : {
      color: 'rgba(0, 0, 0, 0.55)',
      
    },

    '.MuiChip-root.MuiAutocomplete-tag' : {
      margin: '.25em .25em .25em 0'
    },

    '.MuiChip-root.MuiChip-sizeSmall' : {
      height: '22px'
    },
    

    '.MuiChip-root.MuiChip-sizeXs' : {
      height: '18px',

      '& .MuiChip-label' : {
        paddingLeft: '9px',
        paddingRight: '8px'
      }
    },


    '.MuiChip-root.MuiChip-colorDanger' : {
      background: 'hsl(  7, 71%, 55%)',
      color: 'white'
    },

    '.MuiChip-root.MuiChip-colorPrimary, .MuiChip-root.MuiChip-colorDanger' : {
      '& .MuiChip-icon' : {
        color: 'white'
      }
    },

    '.MuiChip-root.MuiChip-colorDanger' : {
      background: 'hsl(  7, 71%, 55%)',
      color: 'white'
    },

    '.MuiChip-root.MuiChip-colorPrimary, .MuiChip-root.MuiChip-colorDanger' : {
      '& .MuiChip-icon' : {
        color: 'white'
      }
    },

    '.MuiInputBase-input.MuiInput-input[readonly]' : {
     /*  opacity: '.7', */
     cursor: 'not-allowed',
     color: 'rgba(0, 0, 0, 0.87)'
    },

    '.MuiChip-root.Mui-disabled.Mui-disabled': {
      cursor: 'not-allowed',
      opacity: '1'
    },

    '.MuiInput-underline.Mui-disabled:before': {
      'border-bottom-style': 'solid'
    },

    '.MuiSelect-root.Mui-disabled .MuiChip-root': {
      
    },

    '.MuiDialog-paper.MuiDialog-paper': {
      margin: '4px'
    },

    '.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input.MuiSelect-withChips' : {
      'padding-top': '3px',
      'padding-bottom': '4px',
      'white-space': 'unset',
      'text-overflow': 'unset',
      'margin-top': '-4px',

      '& > .MuiChip-root ': {
        'margin': '4px 4px 0 0'
      }
    },

    '.MuiAutocomplete-option.MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: 'hsla(166deg 100% 25% / 8%)',
      

    },

    '.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input.MuiAutocomplete-input:first-child': {
      padding: '4px 4px 5px' 
    },

    '.MuiFormGroup-root.MuiFormGroup-row' : {
      borderBottom: '1px solid #999',
      position: 'relative',
      top: '-2px',

      '& .MuiFormControlLabel-root': {
        marginBottom: '.3em'
      },

      '& .MuiTypography-root.MuiFormControlLabel-label': {
        fontSize: 'calc((13 / 14) * 1rem)'
      }
    },

    '.MuiRadio-root.MuiButtonBase-root.MuiIconButton-root': {
      padding: '0 6px 0 9px'
    }


    /* '.MuiInputBase-input.MuiInput-input.numeric-input-negative-value': {
      color: kskPalette.red
    }, */
  },
})(() => null);


export default muiTheme;
