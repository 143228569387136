
import { isArray, uniqBy } from 'lodash';
import { useEffect, useReducer, useState } from "react";
import { sequence } from 'utils/generators';
import { makeid } from 'utils/stringUtils';
import { linearize } from './treeUtils';
import { toFlatArray } from './treeUtils';
import { traverse } from './treeUtils';
import { hasChildren, positionInParent, fromArray } from "./treeUtils";
class Node {
  constructor({
    index = 0,
    key = null, 
    data = null, 
    attrs = {}, 
    parent = null, 
    children = [],
    isRoot = false,
    height = 0,
    level = 0,
    deepestLevel = 0,
    hierarchy = [],
    leaves = [],
    pos = 0
  }) {
    this.index = index;
    this.hierarchy = hierarchy;
    this.key = key;
    this.data = data;
    this.attrs = attrs;
    this.parent = parent;
    this.children = children;
    this.isRoot = isRoot;
    this.height = height
    this.level = level;
    this.deepestLevel = deepestLevel;
    this.leaves = leaves;
    this.hierarchy = hierarchy;
    this.pos = pos;
  }
}

const actions = {
  SET_STATE: 'TREE_REDUCER.SET_STATE',
  CREATE_ROOT: 'NODE.CREATE_ROOT',
  BUILD_FROM_ARRAY: 'NODE.BUILD_FROM_ARRAY',
  DROP: 'NODE.DROP',
  SET_KEY: 'NODE.SET_KEY',
  SET_DATA: 'NODE.SET_DATA',
  SET_ATTRS: 'NODE.SET_ATTRS',
  SET_PARENT: 'NODE.SET_PARENT',
  SET_CHILDREN: 'NODE.SET_CHILDREN',
  SET_ATTR: 'NODE.SET_ATTR',
  APPEND_CHILD: 'NODE.APPEND_CHILD',
  PREPREND_CHILD: 'NODE.PREPEND_CHILD',
  INSERT_CHILD: 'NODE.INSERT_CHILD',
  SET_CHILDREN: 'NODE.SET_CHILDREN',
  /* SET_SELECTED: 'NODE.SET_SELECTED',
  TOGGLE_SELECTED: 'NODE.TOGGLE_SELECTED', */
  MOVE_UP: 'NODE.MOVE_UP',
  MOVE_DOWN: 'NODE.MOVE_DOWN',
  CHANGE_PARENT: 'NODE.CHANGE_PARENT',
  EDIT_DATA: 'NODE.EDIT_DATA',
  
};

const gardenRoot = (state) => {
  const {root, sequence = 0} = state;
  const _keyMap = {};

  const updateParents = (node = {}, leaves = []) => {
    const {parent}  = node;
    const _leaves = hasChildren(node) ? leaves.filter(l => l.key !== node.key) : [...leaves, node];

    node.deepestLevel = Math.max(node.deepestLevel, node.level);
    node.leaves =  uniqBy([...node.leaves, ..._leaves], 'key');
    

    if (parent) {
      parent.deepestLevel = Math.max(node.deepestLevel, parent.deepestLevel);
      parent.height = parent.deepestLevel - parent.level; /////////////FIXME
      updateParents(parent, _leaves);
    }
  }
  
  let counter = 0;
  const garden = ({node, pos = 0}) => {
    node.index = counter++;
    node.level = (node?.parent?.level ?? -1) + 1;
    node.deepestLevel = node.level;
    node.height = 0;
    node.leaves = [];
    node.hierarchy = node?.parent ? [...(node?.parent?.hierarchy), pos] : [];
    node.pos = pos;

    // eslint-disable-next-line no-unused-expressions
    node?.children?.forEach(c => c.parent = node);

    !hasChildren(node) && updateParents(node);
    _keyMap[node.key] = node;
    
    // eslint-disable-next-line no-unused-expressions
    node?.children?.forEach((child, childPos) => garden({
      node: child, 
      pos: childPos
    }));
  };

  garden({node: root});

  return {
    ...state,
    root,
    keyMap: _keyMap,
    sequence: sequence + 1,
    stamp: makeid(8)
  }
}

const createRoot = ({key = 'ROOT'} = {}) => {
  const root = new Node({key, isRoot: true});
  const state = {
    root,
    keyMap: {[key]: root},
    sequence: 0,
  }

  return gardenRoot(state);
};

const buildFromArray = ({source, keyProp, parentProp, orderProp}) => {
  const root = fromArray({source, keyProp, parentProp, orderProp});
  const state = {
    root,
    keyMap: {[root.key]: root},
    sequence: source.length,
  };

  return gardenRoot(state);
}


const drop = (node, state) => {
  const _drop = (node) => {
    const pos = positionInParent(node);
    // eslint-disable-next-line no-unused-expressions
    node?.parent?.children.splice(pos, 1);
  }
  
  if (isArray(node)) {
    node.forEach(n => _drop(n));
  } else {
    node && _drop(node);
  }
  
  return gardenRoot(state);
}

const changeParent = ({node, newParent}, state) => {
  //console.log('chage parent')
  const { root, keyMap } = state;

  const _changeParent = (node) => {
    if (node) {
      const pos = positionInParent(node);
      const extracted = node?.parent?.children?.splice(pos, 1)
      if (extracted.length) {
        const picked = extracted[0];
        const loadPicked =  keyMap?.[picked?.key];
        const parent = keyMap?.[newParent?.key] || root;
        
        const child = loadPicked;
        child.parent = parent;
        parent.children = [...parent.children, child ];
      }
    }
  }
  
  if (isArray(node)) {
    node.forEach(n => _changeParent(n));
  } else {
    node && _changeParent(node);
  }

  return gardenRoot(state);
}

const editData = ({node, data}, state) => {
  const loaded = state?.keyMap?.[node?.key];
  loaded && (loaded.data = data);

  return gardenRoot(state)
}

const moveDown = (node, state) => {
  const { parent, pos} = node || {};
  if (parent && pos < parent.children.length - 1) {
    parent.children = swap(parent.children, pos +1, pos);
  }

  return gardenRoot(state);
}

const updateHeight = (node) => {
  node.height = node.deepestLevel - node.level;
}

const updateDeepestLevel = (node, i = 0) => {
  if (node.parent) {
    if (node.deepestLevel > node.parent.deepestLevel) {
      node.parent.deepestLevel = node.level + i;
      updateHeight(node.parent);
      updateDeepestLevel(node.parent, i + 1)
    }
  }
}

const appendChild = ({ child, parent }, state) => {
  const { root, keyMap } = state;
  if (root) {
    const _parent = keyMap?.[parent?.key] || root;
    const _child = {...child, parent: _parent};
    _parent.children = [..._parent.children, _child ];
  }
  return gardenRoot(state);
}

const insertChild = ({ child, parent, pos }, state) => {
  const { root, keyMap } = state;
  if (root) {
    const _parent = keyMap?.[parent?.key] || root;
    const _children = isArray(child) ? child.map(c => ({...c, parent: _parent})) : [{...child, parent: _parent}];
    const parentsChildren = _parent?.children || [];
    const _pos = Math.max(0, Math.min(pos, parentsChildren.length));
    const slice1 = parentsChildren.slice(0, _pos);
    const slice2 = parentsChildren.slice(_pos, parentsChildren.length);
    _parent.children = [...slice1, ..._children, ...slice2];
  }
  return gardenRoot(state);
}

const setChildren = ({parent, children}, state) => {
  const { root, keyMap } = state;
  if (root) {
    const _parent = keyMap?.[parent?.key] || root;
    _parent.children = [...children];
  }
  return gardenRoot(state);
}

const swap = (arr, i, j) => {
  const swapped = [...arr];
  const temp = swapped[i];
  swapped[i] = swapped[j];
  swapped[j] = temp;
  return swapped;
}

const moveUp = (node, state) => {
  const { parent, pos} = node || {};
  if (parent && pos > 0) {
    parent.children = swap(parent.children, pos, pos - 1);
  }

  return gardenRoot(state);
}


const treeReducer = (state, action) => {
  switch (action.type) {
    case actions.SET_STATE:
      return action.payload;
    case actions.CREATE_ROOT:
      return createRoot(action.payload);
    case actions.BUILD_FROM_ARRAY:
      return buildFromArray(action.payload);  
    case actions.APPEND_CHILD:
      return appendChild(action.payload, state);
    case actions.INSERT_CHILD:
      return insertChild(action.payload, state);
    case actions.SET_CHILDREN:
      return setChildren(action.payload, state);
    case actions.DROP:
      return drop(action.payload, state);
    case actions.MOVE_UP:
      return moveUp(action.payload, state);
    case actions.MOVE_DOWN:
        return moveDown(action.payload, state);    
    case actions.CHANGE_PARENT:
      return changeParent(action.payload, state);        
    case actions.EDIT_DATA:
      return editData(action.payload, state);        
    default:
        return state;
  }
};

function useTreeReducer({onChange} = {}) {
  const [{ root, keyMap, sequence, stamp }, dispatch] = useReducer(treeReducer, {
    root: null,
    keyMap: {},
  });

  const [nodeCount, setNodeCount] = useState(0);

  const changeStamp = `${sequence}_${stamp}`;

  useEffect(() => {
    onChange && onChange();
    setNodeCount(Object.keys(keyMap).length);
  }, [changeStamp]);

  const [selected, setSelected] = useState(null);
  
  const dispatcher = {
    createRoot: (key) => dispatch({ type: actions.CREATE_ROOT, payload: {key}}),
    buildFromArray: ({source, keyProp, parentProp, orderProp}) => 
    dispatch({ type: actions.BUILD_FROM_ARRAY, payload: {source, keyProp, parentProp, orderProp}}),
    appendChild: ({child, parent}) => dispatch({ type: actions.APPEND_CHILD, payload: {child, parent}}),
    insertChild: ({child, parent, pos}) => dispatch({ type: actions.INSERT_CHILD, payload: {child, parent, pos}}),
    setChildren: ({parent, children}) => dispatch({ type: actions.SET_CHILDREN, payload: {parent, children}}),
    changeParent: ({node, newParent}) => dispatch({ type: actions.CHANGE_PARENT, payload: {node, newParent}}),
    drop: (node) => dispatch({ type: actions.DROP, payload: node}),
    moveUp: (node) => dispatch({ type: actions.MOVE_UP, payload: node}),
    moveDown: (node) => dispatch({ type: actions.MOVE_DOWN, payload: node}),
    editData: ({node, data}) => dispatch({ type: actions.EDIT_DATA, payload: {node, data}})
  };

  const getNodeByKey = (key) => keyMap?.[key];

  const toggleSelected = (node) => {
    (selected && node?.key === selected?.key)
      ? setSelected(null)
      : setSelected(node);
  };

  return { 
    root,
    dispatcher,
    getNodeByKey,
    keyMap, 
    nodeCount,
    setSelected,
    selected,
    toggleSelected,
    changeStamp,
  }
}



function useTraversal(treeReducer) {
  const { 
    root,
    createRoot, 
    appendChild,
    getNodeByKey,
    keyMap
  } = treeReducer;

  //const preOrder = (node) => traverse(node, )

  
}

export default useTreeReducer;
export {
  useTraversal,
  Node
};



/*
const _appendChild = ({ child, parent }, state) => {
  const { root, keyMap } = state;
  
  if (root) {
    const _parent = parent || root;
    const level = (_parent.level ?? 0) + 1;
    

    const _child = {
      ...child, 
      deepestLevel: level,
      level,
      parent: _parent
    }
    
    _parent.children = [..._parent.children, _child ];

    if (_parent.children.length === 1) {
      updatedeepestLevel(_child);
      console.log('-----------------------------')
    }

    return {
      root,
      keyMap: { 
        ...keyMap, 
        [_parent.key]: _parent, 
        [_child.key]: _child 
      }
    }
  } else {
    const newRoot = createRoot();
    newRoot.root.children = [...newRoot.root.children, { ...child, parent: newRoot, level: 1}];

    return {
      ...state,
      ...{
        root: newRoot.root,
        keyMap: { ...keyMap, [child.key]: child }
      }
    }
  }
};
*/