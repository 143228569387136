
import http from '../utils/HttpCommon';

const getAll = () => {
  return http.get(`/auth/signon`);
}

const getReverseSignOnToken = () => {
  return http.get(`/auth/reverse-signon`)
}

export default {
  getAll,
  getReverseSignOnToken
};