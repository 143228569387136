import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faExclamationCircle, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get, isArray } from "lodash";
import React from "react";

export const helperTextList = ({optional, required, helperText, isRequiredError, readOnly}) => {
  const result = [];

  const addHelp = (test, help) => {
    if (!test) return;
    isArray(help) ? result.push(...help) : result.push(help)
  }

  addHelp(optional && !readOnly, <><FontAwesomeIcon icon={faDotCircle}/> <span className="italic">Opcional</span></>);
  addHelp(required && !isRequiredError && !readOnly, <><FontAwesomeIcon icon={faExclamationCircle}/> <span className="italic">Obrigatório</span></>);
  addHelp(!!helperText, helperText);
  return result;
}

const KskInputHelper = ({optional, required, hasErrors, name, errorsObj, helperText, readOnly}) => {
  const result = [];
  const helperList = helperTextList({optional, required, helperText, isRequiredError: get(errorsObj, name)?.type === 'required', readOnly:readOnly});
  const infoHelpers = helperList.map((h, i) => <span key={i}>{h}</span>)
  const err = hasErrors && <span><FontAwesomeIcon icon={faTimesCircle} /> {get(errorsObj, name)?.message}</span>

  hasErrors && result.push(err);
  infoHelpers.length && result.push(...infoHelpers);

  return result.map((h, i) => <React.Fragment key={i}>{h}</React.Fragment>);
}

export default KskInputHelper;