import React, { useState, useEffect } from 'react'
import { watchPendingRequests$ } from 'utils/HttpCommon';
import { S } from './LoadingSpinner.styles';

function LoadingSpinner() {
  const [show, setShow] = useState(false);
  /* const toggle = () => setShow(prevState => !prevState); */

  useEffect(() => {
    const subscription = watchPendingRequests$.subscribe(
      pendingRequests => setShow(pendingRequests !== 0)
    );

    return () => subscription.unsubscribe();
    
  }, [show]);

  /* DEBUG */
  /* const toggleBtn = <button type="button" onClick={toggle} style={{position: 'fixed', top: 10, left: 10}}>toggle</button> */

  const showClass = show ? 'loading-spinner--visible' : 'loading-spinner--hidden';
  return (
    <>
      {/* {toggleBtn} */}
      <S.Aside className={`loading-spinner ${showClass}`} role="status" aria-hidden={!show}>
        <div className="loading-spinner__spinner">     
          <svg width="100%" height="100%" 
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" 
            preserveAspectRatio="xMidYMid">
          
            <circle className="loading-spinner__outer-circle" cx="50" cy="50" fill="none" strokeLinecap="round" r="40" 
            strokeWidth="10" stroke="#ffffff" strokeDasharray="62.83185307179586 62.83185307179586" >        
            </circle>

            <circle className="loading-spinner__inner-circle" cx="50" cy="50" 
              fill="none" strokeLinecap="round" r="29" 
              strokeWidth="10" stroke="rgba(100%,100%,100%,0.516)" 
              strokeDasharray="45.553093477052 45.553093477052" 
              strokeDashoffset="45.553093477052">
            </circle>
          </svg>    
        </div>
        <span className="loading-spinner__running">Processando...</span>
      </S.Aside>
    </>

    
  );
}

export default LoadingSpinner;
