import { Node } from 'ksk/hooks/useTreeReducer';
import { isEqual, sortBy, omit } from 'lodash';
import { makeid } from 'utils/stringUtils';
import { getLastItem, isLastItem } from 'utils/utils';

const depth = (node) => ({preOrder, inOrder, postOrder}, {stop} = {}) => {
  let preOrderCounter = 0;
  let inOrderCounter = 0;
  let postOrderCounter = 0;
  let stopped = false;

  const _depth = (n) => {
    stopped = stop && stop(n, {preOrderCounter, inOrderCounter, postOrderCounter}); 
    preOrder && preOrder(n, preOrderCounter++);

    hasChildren(n) && n.children.forEach((child, i) => { 
      !stopped && !isLastItem(n.children, i) && _depth(child); 
    });
  
    inOrder && inOrder(n, inOrderCounter++);
  
    hasChildren(n) && _depth(getLastItem(n.children));
    postOrder && postOrder(n, postOrderCounter++);
  }

  return node && _depth(node)
};

/* const _preOrder = (node) => (callback, stop, i) => {
  if (stop && stop(node, i)) {
    return;
  }

  callback(node, i);

  hasChildren(node) && node.children.forEach((child, count) => { 
    _preOrder(child)(callback, stop, (i + 1) + count); 
  });
};
 */
const preOrder = (node) => (callback, {stop} = {}) => {
  return node && depth(node)({preOrder: callback}, {stop})
  //return node && _preOrder(node)(callback, stop, 0)
};

const inOrder = (node) => (callback, {stop} = {}) => {
  return node && depth(node)({inOrder: callback}, {stop})
};


/*----------------------------------------------------------------*/
/* const _postOrder = (node) => (callback, stop, i) => {
  if (stop && stop(node, i)) {
    return;
  } 

  hasChildren(node) && node.children.forEach((child, count) => { 
    _postOrder(child)(callback, stop, (i + 1) + count++); 
  });

  callback(node, i);
}; */

const postOrder = (node) => (callback, {stop} = {}) => {
  return node && depth(node)({postOrder: callback}, {stop})
  //return node && _postOrder(node)(callback, stop, 0)
};


/*----------------------------------------------------------------*/
const _breadth = (node) => (callback, stop, i) => {
  const queue = [node];
  let count = i;
  while (queue.length > 0) {
    if (stop && stop(node, count)) {
      return;
    }
    callback(queue[0], count++);
    // eslint-disable-next-line no-unused-expressions
    queue.shift()?.children?.forEach(n => queue.push(n));
  }
};

const breadth = (node) => (callback, {stop} = {}) => {
  return node && _breadth(node)(callback, stop, 0);
};
/*----------------------------------------------------------------*/

const _ancestors = (node) => (callback, stop, i) => {
  if (stop && stop(node, i)) {
    return;
  }
  callback(node, i);
  node.parent && _ancestors(node.parent)(callback, i + 1);
};

const ancestors = (node) => (callback, {stop} = {}) => {
  return _ancestors(node)(callback, stop, 0)
};


const hasChildren = (node) => Array.isArray(node?.children) && !!node.children.length;

const isSameNode = (node1, node2) => node1?.key && node2?.key && node1.key === node2.key;

const commonHierarchy = (node1, node2) => (
  node1?.hierarchy?.length > node2?.hierarchy?.length
    ? [node1?.hierarchy?.slice(0, node2?.hierarchy?.length), [...node2.hierarchy]]
    : [node2?.hierarchy?.slice(0, node1?.hierarchy?.length), [...node1.hierarchy]]
);

const isSameSubtree = (node1, node2) => isEqual(...commonHierarchy(node1, node2));

const isDescendant = (node, supossedAncestor) => {
  if (isSameNode(node, supossedAncestor)) {
    return false;
  }

  return node?.hierarchy?.length > supossedAncestor?.hierarchy?.length
    && isSameSubtree(node, supossedAncestor);
}

const isAncestor = (node, supossedDescendant) => {
  if (isSameNode(node, supossedDescendant)) {
    return false;
  }

  return node?.hierarchy?.length < supossedDescendant?.hierarchy?.length
    && isSameSubtree(node, supossedDescendant);
};

const compareNode = (node) => ({
  isEquals: (node2) => isSameNode(node, node2),
  isDescendant: (node2) => isDescendant(node, node2),
  isEqualsOrDescendant: (node2) => isSameNode(node,node2) || isDescendant(node, node2),
  isAncestor: (node2) => isAncestor(node, node2),
  isEqualsOrAncestor: (node2) => isSameNode(node,node2) || isAncestor(node, node2),
});


const positionInParent = (node) => {
  if (node?.parent) {
    return node.parent?.children?.findIndex(sibling => sibling.key === node.key);
  } else {
    return -1;
  }
}

const isFirstChild = (node) => {
  if (node.isRoot) return false;

  const { hierarchy } = node;
  const len = hierarchy.length;
  const pos = len && hierarchy.slice(len -1, len)[0];

  return pos === 0;
}

const isLastChild = (node) => {
  if (node.isRoot) return false;

  const { hierarchy } = node;
  const len = hierarchy.length;
  const pos = len && hierarchy.slice(len -1, len)[0];
  return node?.parent?.children?.length === pos + 1 ;
}

const leftSiblings = (node) => {
  if (!node || node.isRoot) return [];
  return node?.parent?.children?.filter(c => c?.pos < node?.pos);
}

const rightSiblings = (node) => {
  if (!node || node.isRoot) return [];
  return node?.parent?.children?.filter(c => c?.pos > node?.pos);
}

const siblings = (node) => {
  if (!node || node.isRoot) return [];
  return node?.parent?.children?.filter(c => c?.pos !== node?.pos);
}

const traverse = (node, {stop} = {}) => ({
  depth: depth(node, {stop}),
  preOrder: preOrder(node, {stop}),
  inOrder: inOrder(node, {stop}),
  postOrder: postOrder(node, {stop}),
  breadth: breadth(node, {stop}),
  ancestors: ancestors(node, {stop})
});

const _linearizeDepth = (node, order) => {
  const list = [];
  traverse(node)[order](n => list.push(n));
  return list;
}

/* const _linearizePreOrder = (node) => {
  const list = [];
  traverse(node).preOrder(n => list.push(n));
  return list;
}

const _linearizeInOrder = (node) => {
  const list = [];
  traverse(node).inOrder(n => list.push(n));
  return list;
}

const _linearizePostOrder = (node) => {
  const list = [];
  traverse(node).postOrder(n => list.push(n));
  return list;
} */

const _linearizeBreadth = (node) => {
  const list = [];
  traverse(node).breadth(n => list.push(n));
  return list;
}

const _linearizeAncestors = (node) => {
  const list = [];
  traverse(node).ancestors(n => list.push(n));
  return list;
}

const linearize = (node) => ({
  /* preOrder: () => _linearizePreOrder(node),
  inOrder: () => _linearizeInOrder(node),
  postOrder: () => _linearizePostOrder(node), */
  //
  preOrder: () => _linearizeDepth(node, 'preOrder'),
  inOrder: () => _linearizeDepth(node, 'inOrder'),
  postOrder: () => _linearizeDepth(node, 'postOrder'),
  //
  breadth: () => _linearizeBreadth(node),
  ancestors: () => _linearizeAncestors(node)
});

const nodeSize = (node) => {
  let size = 0;
  traverse(node).preOrder(_ => size++);
  return size;
}

const addChild = (node, child) => {
  const _child = child.setParent(node);
  node.setChildren((prev) => [...prev, _child]);
};

const oneBasedHierarchy = (hierarchy) => hierarchy?.map(h => h + 1) || [];

/*#################################################################*/
const fromArray = ({source = [], keyProp, parentProp, orderProp}) => {
  if (!keyProp?.trim()?.length || !parentProp?.trim()?.length || !orderProp?.trim()?.length) {
    throw new Error(
      `Erro ao converter array em um TreeNode. "keyProp", "parentProp" e "orderProp" são todos argumentos obrigatórios.` 
    )
  }

  const array = sortBy(source, orderProp);
  const root = new Node ({
    key: `root-${makeid(8)}`,
    isRoot: true
  })

  const nodeBag = new Map();
  nodeBag.set(root.key, root);

  const extractData = (record) => omit(record, [keyProp, parentProp, orderProp])

  for(let i = 0, len = array.length; i < len; i++) {
    const curr = array[i];
    const parent = nodeBag.get(curr?.[parentProp]);
    const key = curr?.[keyProp];
    
    const data = extractData(curr);
/*     console.log('curr ->', curr)
    console.log('data ->', data) */

    const newNode = new Node({
      key, 
      data, 
      parent, 
      //attrs = {}, 
      //children = [],
    });

    if (parent) {
      parent.children.push(newNode);
      newNode.parent = parent;
      root.children = root.children.filter(c => c.key !== newNode.key)
    } else {
      root.children.push(newNode);
      newNode.parent = root;
    }
    nodeBag.set(key, newNode);
  }
  return root;
}

const toFlatArray = ({node, keyProp, parentProp, orderProp, excludeRoot}) => {
  if (!keyProp?.trim()?.length || !parentProp?.trim()?.length || !orderProp?.trim()?.length) {
    console.log('erro', 
    {node, keyProp, parentProp, orderProp}
    )

    throw new Error(
      `Erro ao converter TreeNode em um array. "keyProp", "parentProp" e "orderProp" são todos argumentos obrigatórios.` 
    )
  }
  const nodeList = excludeRoot 
    ? linearize(node).preOrder().filter((n) => !n.isRoot)
    : linearize(node).preOrder();

  return nodeList.map((n) => {
    const specialProps = {
      [keyProp]: n.key,
      [parentProp]: n?.parent?.isRoot ? null : (n?.parent?.key ?? null),
      [orderProp]: n?.index ?? null
    }

    const omitProps = 'attrs|children|data|deepestLevel|index|height|hierarchy|isRoot|key|leaves|level|parent|pos'.split('|')
    const otherProps = omit(n, [keyProp, parentProp, orderProp, ...omitProps]);

    return {...otherProps, ...specialProps, ...(n?.data || {})};
  });
}



export {
  addChild,
  compareNode,
  fromArray,
  toFlatArray,
  hasChildren,
  linearize,
  positionInParent,
  traverse,
  isFirstChild,
  isLastChild,
  leftSiblings,
  rightSiblings,
  siblings,
  oneBasedHierarchy,
  nodeSize
};


const teste = () => {
  const isLastItem = (arr, index) => (arr && index != null) && (index === arr.length - 1);
  const getLastItem = (arr) => arr && arr.length === 0 ? null : arr[arr.length - 1];
  const hasChildren = (n) => n.children && n.children.length

  /* const teste = {
    label: 'root',
    children: [
      {
        label: 'a',
        children: [
          {
            label: 'b',
            children: [
              {label: 'c'}
            ]
          },

          {label: 'd'}
        ]
      },
      {label: 'x'},
      {label: 'y'}
    ]
  }; */

  const teste = {
    label: '1',
    children: [
      {
        label: '2',
        children: [
          {label: '4'},
          {label: '5'}
        ]
      },
      {label: '3'}
    ]
  };

 
  
/*   const preOrder = (node) => (callback, {stop} = {}) => {
    let counter = 0;
    let stopped = false;

    const _preOrder = (n) => {
      stopped = stop && stop(n, counter); 
      callback(n, counter++);
      hasChildren(n) && n.children.forEach((child) => { 
        !stopped &&_preOrder(child); 
      });
    }

    return node && _preOrder(node)
  }; */

  //preOrder(teste)((n)=> console.log(n.label))
  //preOrder(teste)((n)=> console.log(n.label),{stop: (n, i) => {console.log(i); return i === 1}})


 /*  const inDepth = (node) => ({preOrder, inOrder, postOrder}, {stop} = {}) => {
    let preOrderCounter = 0;
    let inOrderCounter = 0;
    let postOrderCounter = 0;
    let stopped = false;

    const _inDepth = (n) => {
      stopped = stop && stop(n, {preOrderCounter, inOrderCounter, postOrderCounter}); 
      preOrder && preOrder(n, preOrderCounter++);

      hasChildren(n) && n.children.forEach((child, i) => { 
        !stopped && !isLastItem(n.children, i) && _inDepth(child); 
      });
    
      inOrder && inOrder(n, inOrderCounter++);
    
      hasChildren(n) && _inDepth(getLastItem(n.children));
      postOrder && postOrder(n, postOrderCounter++);
    }

    return node && _inDepth(node)
  }; */
}

