/*
 * BACKEND FEATURES IDs 
 */
export const ADMIN = 'ADMIN';
export const AUT_EMIT = 'AUT_EMIT';
export const CONDICAO = 'CONDICAO';
export const DEM_JUD = 'DEM_JUD';
export const FINALIDADE = 'FINALIDADE';
export const LIMITE = 'LIMITE';
export const MODELO_QUADRO = 'MODELO_QUADRO';
export const NORMATIVO = 'NORMATIVO';
export const PARAM_CALCULO = 'PARAM_CALCULO';
export const PERFIL = 'PERFIL';
export const PROGRAMA = 'PROGRAMA';
export const SIM_LIMITE = 'SIM_LIMITE';
export const TP_ANALISE = 'TP_ANALISE';
export const COC = 'COC';
export const PVL = 'PVL';
export const LISTAS = 'LISTAS'
export const CONSULTA_DOCUMENTO = "CONSULTA_DOCUMENTO";





















/* const cons = (id) => `CONS_${id}`;
const ins =  (id) => `INS_${id}`;
const alt =  (id) => `ALT_${id}`;
const exc =  (id) => `EXC_${id}`;

const backendFeature = (backendId) => (
  (id) => ({ 
    backendId,
    CONS: cons(id),
    INS: ins(id),
    ALT: alt(id),
    EXC: exc(id)
  })
)(backendId);


const AUT_EMIT = backendFeature('AUT_EMIT');
const CONDICAO = backendFeature('CONDICAO');
const DEM_JUD = backendFeature('DEM_JUD');
const FINALIDADE = backendFeature('FINALIDADE');
const LIMITE = backendFeature('LIMITE');
const MODELO_QUADRO = backendFeature('MODELO_QUADRO');
const NORMATIVO = backendFeature('NORMATIVO');
const PARAM_CALCULO = backendFeature('PARAM_CALCULO');
const PERFIL = backendFeature('PERFIL');
const PROGRAMA = backendFeature('PROGRAMA');
const SIM_LIMITE = backendFeature('SIM_LIMITE');
const TP_ANALISE = backendFeature('TP_ANALISE');
 */
