import { faCheckCircle, faExclamationTriangle, faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { px2em } from 'ksk/styledComponents/functions';
import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';

const borderColor = theme('mode', {
  padrao:  'rgba(255,255,255,.25)',
  altoContraste: 'black'
});

const ToastBody = styled.div.attrs(() => ({className: `ksk-toast`}))`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "icon msg"
                       "empty details"
                       "empty  msgs";
  align-items: flex-start;
  grid-column-gap: 1em;

  > .ksk-toast__icon {
    grid-area: icon;
    font-size: 2em;
    line-height: 1;
    align-self: center;
    justify-self: center;
  }

  > .ksk-toast__message {
    grid-area: msg;
    align-self: center;
  }

  > .ksk-toast__details {
    grid-area: details;
    align-self: center;
    font-size: ${px2em(12)};
    border-top: ${px2em(1)} solid ${borderColor};
    white-space: pre-wrap;
  }

  > .ksk-toast__msg-list {
    grid-area: msgs;
    list-style: square;
    padding: 0;
    font-size: ${px2em(12)};
    border-top: ${px2em(1)} solid ${borderColor};
  }
`
const icons = { 
  info: <FontAwesomeIcon icon={ faInfoCircle } />, 
  success: <FontAwesomeIcon icon={ faCheckCircle } />,
  warning: <FontAwesomeIcon icon={ faExclamationTriangle } />, 
  error: <FontAwesomeIcon icon={ faTimesCircle } />

};

const KskToast = ({ httpError, mainMessage, details, messages, info, success, warning, error}) => {
  const severityInfo = info && 'info';
  const severitySuccess = success && 'success';
  const severityWarning = warning && 'warning';
  const severityError = error && 'error';  

  const severity = severityInfo || severitySuccess || severityWarning || severityError || 'info';

  const toastIcon = icons[severity];
  const { httpMessage, httpMessages , httpDetails} = httpError ? digestError(httpError) : {};
  const toastMessage = mainMessage || httpMessage;
  const toastDetails = details || httpDetails;
  const toastMessageList = messages || httpMessages;

  return (
    <ToastBody>
      <div className="ksk-toast__icon">{ toastIcon }</div>
      
      { 
        toastMessage && 
        <div className="ksk-toast__message">{ toastMessage }</div>
      }

      { 
        toastDetails && 
        <div className="ksk-toast__details">{ toastDetails }</div>
      }

      {
        toastMessageList &&
        <ul className="ksk-toast__msg-list">
          {toastMessageList.map(m => <li key={m}>{m}</li>)}
        </ul>
      }
    </ToastBody>
  )
}

function digestError (httpError = {}) {
  const { isAxiosError, code, response, response: { data: { error, errors, status, message } = {} } = {}} = httpError;

  console.log('HTTP ERROR', {...httpError});

  const noResponse = isAxiosError && (response === undefined);
  const expired = noResponse && code === 'ECONNABORTED';
  const offlineMsg = noResponse && !expired && 'Problemas ao se conectar com Siconfi';
  const expiredMsg = expired && 'A operação demorou demais e foi abortada'; 

  return {
    status,
    httpMessage: error || offlineMsg || expiredMsg,
    httpMessages: errors?.map(({message}) => `${message}`) ,
    httpDetails: message  
  }
}
 
export default KskToast;
