
import { Subject } from 'rxjs';

/* const TOKEN =
  'eyJhbGciOiJIUzUxMiJ9.eyJqc29uU2ljb25maSI6eyJjcGYiOiIzMzMzMzMzMzMzMyIsIm5vbWUiOiJBQUFBQUFBQUEgLSAzMzMiLCJpZFZpbmN1bG8iOiI4MDk4Iiwibm9tZVBlcmZpbCI6IkFkbWluaXN0cmFkb3IgZG8gU2lzdGVtYSIsImlkUGVyZmlsIjoiMiIsIm9yZ2FvIjoiR292ZXJubyBGZWRlcmFsIiwiaWRPcmdhbyI6IjEifSwic3ViIjoiMzMzMzMzMzMzMzMiLCJleHAiOjQ1NjY4MTQ3ODh9.7RYw8vaGqQsTVBpuq2IJYhNlwr5h_pdE8Dbc8Q47g4QaKzm5F-pWdmDBhZiRge9axyRn6MKlGkwDdDYEeBHQqw';
 */

export const jwtConfig = {
  key: 'JWT_SIGN_ON',
  prefix: 'JWTOC '
};

const authSubject = new Subject(); 

const getAuthData = () => {
  try {
    return JSON.parse(localStorage.getItem(jwtConfig.key));
  } catch(e) {
    console.warn('Formato de Autorização inválido');
    return null;
  }
};

const setAuthData = ({jwt}) => {
  if (getAuthData() !== jwt) {
    const jwtData = {timestamp: new Date().getTime(), jwt}

    localStorage.setItem(
      jwtConfig.key, 
      JSON.stringify(jwtData)
    );

    authSubject.next(jwtData);
  }
};

const removeAuthData = () => {
  if (getAuthData() != null) {
    localStorage.removeItem(jwtConfig.key);
    authSubject.next(null);
  }
}

export default {
  getAuthData,
  setAuthData,
  removeAuthData,
  authDataChanges$: authSubject.asObservable()
};
