import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { kskTheme } from 'ksk/styledComponents/themes';
import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';

const placeholderBgColor = theme('mode', {
  padrao: 'rgba(0,0,0,.1)',
  altoContraste: 'rgba(255,255,255,.1)'
});

const transitionBgColor = theme('mode', {
  padrao: 'rgba(255,255,255,0.35)',
  altoContraste: 'rgba(0,0,0,0.45)',
});

const borderColor = theme('mode', {
  padrao: 'rgba(0,0,0,.03)',
  altoContraste: 'rgba(255,255,255,.1)'
});

const PlacehoderItem = styled.div.attrs(props => ({className: `ksk-placeholder ${props?.className || ''}`}))`
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  min-height: 2em;
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-size: 1em;
  background: ${placeholderBgColor};
  border: 1px solid ${borderColor};

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${kskTheme.disabledText};
  

  &.ksk-placeholder--align-left {
    justify-content: left;
    padding-left: 1em;
  }
  

  @keyframes loading-text-animation {
    0% {
      color: ${kskTheme.disabledText};
    }
    100% {
      color: transparent;
    }    
  }
  

  @keyframes load {
    0% {
        /*left: -150px;*/
        transform: translate(-60%, 0) rotateZ(360deg);
        
    }
    100%   {
        /*'left: 100%;*/
        transform: translate(60%, 0) rotateZ(360deg); // Performance hack
    }
  /*   100%   {
        
        transform: translate(60%, 0) rotateZ(360deg); // Performance hack
    } */
  }

  &::before {
    // Performance hack
    z-index: 0;
    transform: rotateZ(360deg);
    will-change: transform;
    content: '';
    display: block;
    position: absolute;
    //left: -150px;
    //top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, transparent 40%, ${transitionBgColor} 50%, transparent 60%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    //animation: load 1.8s infinite;
     
  }

  &.ksk-placeholder--circle {
    border-radius: 50%;
  }

  &.ksk-placeholder--rounded {
    border-radius: 1em;
  }

  .ksk-placeholder__text {
  //  animation:  loading-text-animation 1s steps(8) infinite;
   // animation-direction: alternate;
    // position: relative;
    // top: 2px;
    display: flex;
    align-items: center;
  }
`;

const KskPlaceHolder = ({style, height, width, rounded, circle, children, fontSize, text, showDefaultText, alignLeft, className}) => {
  const _height = {height: height, minHeight: height} ?? {};
  const _width = {width: width} ?? {};
  const _fontSize = {fontSize: fontSize} ?? {};
  const _style={...style, ..._height, ..._width, ..._fontSize};
//<FontAwesomeIcon icon={faHourglass} />
  const innerText = (    
    (text !=null || showDefaultText) &&
    <span className="ksk-placeholder__text">
      <CircularProgress color="inherit" size="1em" />
      <span style={{marginLeft: '.5em'}}>{
        showDefaultText 
          ? 'Carregando...'
          : text
      }</span>
    </span>
    
  );

  return (
    <>
      <PlacehoderItem style={_style} className={[className, classNames({
        'ksk-placeholder--circle': circle,
        'ksk-placeholder--rounded': rounded,
        'ksk-placeholder--align-left': alignLeft,
        })].join(' ')} >
        {
          children
            ? children
            : innerText
        }          
      </PlacehoderItem>
        
    </>
  );
}

export default KskPlaceHolder;
