import { isRegExp } from "lodash";
const { StringBuilder } = require("./stringUtils");

const defaultRegexMap = {
  '9': /[0-9]/,
  't': /[0-9-]/,
  'a': /[A-Za-z]/,
  '?': /[0-9 ]/,
  '*': /[A-Za-z0-9]/,
};

const buildMask = (strMask, regexMap = defaultRegexMap) => strMask.split('').map(c => regexMap[c] || c);

/* const Mask = ({mask, regexArr, regexMap = defaultRegexMap}) => {
  return {
    asString: str,
    asRegexArray: buildMask(str),
    isAppliable: (value) => true,
    apply: (value) => str,
    isApplied: (value) => false,
    remove: (value) => value,
}}; */
/* arr.reduce((output, current, i) => {
} */

const buildReplacer = (arr) => arr.reduce((output, current, i) => {
  const REGEX = 'R';
  const FIXED = 'F'
  const isLast = i === arr.length - 1;
  const currentType = isRegExp(current) ? REGEX : FIXED
  const isFixed = currentType === FIXED;
  const isRegex = currentType === REGEX;
  const isPreviousRegex = output.previousType === REGEX;
  
  const isGroupStart = !isPreviousRegex && isRegex;
  const isGroupEnd = (isPreviousRegex && !isRegex) || (isRegex && isLast);
  const groupCount = isGroupEnd ? output.groupCount + 1 : output.groupCount;
  
  const newSource = new StringBuilder(output.source);
  const newReplacement = new StringBuilder(output.replacement);

  /* const newUnmaskSource = new StringBuilder(output.unmaskSource);
  const newUnmaskReplacement = new StringBuilder(output.unmaskReplacement); */

  const fixed = isFixed ? [...output.fixed, i] : [...output.fixed];

  newSource.appendIf(isGroupStart, '(')
           .appendIf(isRegex, current.source)
           .appendIf(isGroupEnd, ')');

  newReplacement.appendIf(isGroupEnd, `$${groupCount}`)
                .appendIf(isFixed, current);

/*   newUnmaskSource.appendIf(isGroupStart, '(')
                 .appendIf(isRegex, current.source)
                 .appendIf(isGroupEnd, ')')
                 .appendIf(isFixed, current);

  newUnmaskReplacement.appendIf(isGroupEnd, `$${groupCount}`); */

  return {
    source: newSource.toString(), 
    replacement : newReplacement.toString(), 
    /* unmaskSource: newUnmaskSource.toString(),
    unmaskReplacement: newUnmaskReplacement.toString(), */
    previousType: currentType, groupCount,
    fixed
  };
}
, { source: '', replacement: '', previousType: '', groupCount: 0, fixed: [], unmaskSource: '', unmaskReplacement: ''});


export const applyMask = (value, strMask, regexMap = defaultRegexMap) => {
  const regexArr = buildMask(strMask, regexMap);
  const replacer = buildReplacer(regexArr);

  return value?.replace(new RegExp(replacer.source), replacer.replacement);
};

export const removeMask = (value, strMask, regexMap = defaultRegexMap) => {
  const regexArr = buildMask(strMask, regexMap);
  const replacer = buildReplacer(regexArr);
  //console.log(replacer)
  
  return value?.split('')?.filter((_, i ) => !replacer.fixed.includes(i) )
  //return value?.replace(new RegExp(replacer.unmaskSource), replacer.unmaskReplacement);
}


export const formatos = {
  nup: { mask: '99999.999999/9999-99', unmask: (val) => val?.replace(/[^0-9]/g,'')},
  cpf: { mask: '999.999.999-99', unmask: (val) => val?.replace(/[^0-9]/g,'')},
  cnpj: { mask: '99.999.999/9999-99', unmask: (val) => val?.replace(/[^0-9]/g,'')},
  numerico4: { mask: '9999', unmask: (val) => val?.replace(/[^0-9]/g,'')},
  numerico2: { mask: '99', unmask: (val) => val?.replace(/[^0-9]/g,'')},
  identificadorsiconfi: { mask: '9999.99.999999-99', unmask: (val) => val?.replace(/[^0-9]/g,'')},
};

export const NAO_ALFANUMERICOS_OU_ESPACOS_SUBSEQUENTES = /[^\p{L}\p{Nd}\s]|(?<=\s)\s+/g;

//const maskCPF = (str) => str.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
// const cpfMask = [/[0-9]/, /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/];