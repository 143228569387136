import Color from 'color';
import { px2em } from 'ksk/styledComponents/functions';
import { device } from 'ksk/styledComponents/mixins';
import { defaultThemeColor, kskTheme } from 'ksk/styledComponents/themes';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const gradient2 = Color(defaultThemeColor).desaturate(.46).lightness(85).hsl();

const headerTextColor = theme('mode', {
  //padrao: `linear-gradient(white, ${gradient2} ${px2em(200)});`,
  padrao: Color(defaultThemeColor).desaturate(.46).lightness(85).hsl(),
  altoContraste: '#060606'
});

const Main = styled.main`
  background: ${headerTextColor};    
  border: 1px solid transparent;
  transition: background 1s ease;
  

  color: ${ kskTheme.text };

  ${device.phone(css`
    padding: 0 .5em;
    min-height: calc(100vh - ${px2em(454)} );
  `)}

  ${device.tablet(css`
    padding: 0 .5em;
    min-height: calc(100vh - ${px2em(410)});
  `)}


  ${device.desktop(css`
    padding: 0 ${px2em(30)} 0 ${px2em(60)};
    min-height: calc(100vh - ${px2em(420)});
  `)}
`;

export const S = {
  Main
};