import React from 'react';
import DOMPurify from 'dompurify';

const KskHtmlRenderer = ({ content }) => {
  const sanitizedHtml = DOMPurify.sanitize(content);
  const sanitizedHtmlWithCorrectedLinks = setTargetBlankAndDefaultProtocolInLinkTags(sanitizedHtml);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtmlWithCorrectedLinks }} />;
};

/**
 * Função que corrige todos as tags \<a\> do documento para que não haja conflito com o router.
 * Essa função:
 *  - Encontra todos as tags \<a\> 
 *  - Seta o protocolo padrão "http://" se o link não tiver protocolo. Isso evita ser interpretado pelo router
 *  - Seta o atributo "target" para "_blank"
 *  - Seta o atributo "rel" para "noopener noreferrer external"
  * @param {*} html html a ser corrigido
 * @returns html corrigido
 */
const setTargetBlankAndDefaultProtocolInLinkTags = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const links = doc.querySelectorAll('a');

  links.forEach(link => {
    const href = link.getAttribute('href');

    if (href && !hasProtocol(href)) {
      // Adiciona "http://" como padrão se não houver protocolo definido
      const hrefWithDefaultProtocol = "http://" + href;
      link.setAttribute('href', hrefWithDefaultProtocol);
      
    }

    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer external');
  });

  return doc.documentElement.innerHTML;
};

 // Função para verificar se uma URL possui um protocolo definido
const hasProtocol = (url) => {
  return /^[a-zA-Z0-9+.-]+:\/\//.test(url);
};

export default React.memo(KskHtmlRenderer);