import Color from 'color';
import { css } from 'styled-components';
import { capitalize } from 'lodash';

/**
 * Breakpoints em pixels
 */

export const deviceBreakpoints = {
  phone: [1,  768],
  tablet: [768,  992],
  desktop: [992, 10000],
}

export const deviceList = Object.keys(deviceBreakpoints);

const respondeTo = deviceList.reduce(
  (accumulator, label) => {
    const [min, max] = deviceBreakpoints[label];

    accumulator[label] = (styles) => css`
      @media (min-width: ${min}px) {
        ${styles};
      }
    `;

    accumulator[`only${capitalize(label)}`] = (styles) => css`
    @media only screen and (min-width: ${ min }px) and (max-width: ${max - 1}px) {    
      ${styles};
    }
    `;

    accumulator[`except${capitalize(label)}`] = (styles) => css`
    @media screen and (min-width: ${ max }px), screen and (max-width: ${min - 1}px) {
      ${styles};
    }
    `;
    
    return accumulator;
  },
  {}
);

/* 
outra forma de se fazer, a chamada ficaria assim: device.tablet`background: red`,
mais direta, mas as ferramentas não suportam o syntax highlight interno

  accumulator[label] = (...args) => css`
    @media (min-width: ${min}px) {
      ${css(...args)};
    }
  `; 
*/

export const device = {
  phone: respondeTo.phone,
  onlyPhone: respondeTo.onlyPhone,
  exceptPhone: respondeTo.exceptPhone,

  tablet: respondeTo.tablet,
  onlyTablet: respondeTo.onlyTablet,
  exceptTablet: respondeTo.exceptTablet,

  desktop: respondeTo.desktop,
  onlyDesktop: respondeTo.onlyDesktop,
  exceptDesktop: respondeTo.exceptDesktop
};

export const hoverActiveFocus = (styles) => {
  return  css`
    &:hover,
    &:active,
    &:focus {
      ${styles};
    }
    `;
}

export const inlineFlexList = css`
  display: flex;
  flex-flow: wrap;
  list-style-type: none;
  padding: .5em .75em;
  margin: .75em 0;
  align-items: baseline;
`;

export const isLight = color => Color(color).isLight() ? css : () => '';
export const isDark = color => Color(color).isDark() ? css : () => '';

export const pseudo = ({display = 'block', position = 'absolute', content = ' '} = {}) => (styles) => css`
  content: '${content}';
  display: ${display};
  position: ${position};
  ${styles};
`;

export const pseudoFloatElem = css`
  content: ' ';
  display: block;
  position: absolute;
`;

export const cssTriangle = (dir = 'right', size = '.25em', color = '#333') => css`
  width: 0; 
  height: 0; 

  ${dir === 'up' && css`
    border-left: ${size} solid transparent;
    border-right: ${size} solid transparent;
    border-bottom: ${size} solid ${color};
  `}

  ${dir === 'down' && css`
    border-left: ${size} solid transparent;
    border-right: ${size} solid transparent;
    border-top: ${size} solid ${color};
  `}

  ${dir === 'left' && css`
    border-top: ${size} solid transparent;
    border-bottom: ${size} solid transparent;
    border-right: ${size} solid ${color};
  `}

  ${dir === 'right' && css`
    border-top: ${size} solid transparent;
    border-bottom: ${size} solid transparent;
    border-left: ${size} solid ${color};
  `}
`;