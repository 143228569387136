import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import { ptBR as ptBR_date } from 'date-fns/locale';
import storage from 'local-storage-fallback';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './GlobalStyle';
import muiTheme, { MuiGlobalCss } from './muiTheme';

const KskContext = React.createContext();

export const KSK_ACTIONS = {
  CHANGE_MODE: 'changeMode'
}

function reducer(state, action) {
  switch (action.type) {
    case KSK_ACTIONS.CHANGE_MODE:  
      return {...state, mode: action.payload};
    default: 
      return state;
  } 
}

function useTheme(defaultTheme = { mode: 'padrao', textZoom: 'normal' }) {
  const [theme, _setTheme] = useState(getInitialTheme);
  
  function getInitialTheme() {
    const savedTheme = storage.getItem('theme');
    return savedTheme
      ? JSON.parse(savedTheme)
      : defaultTheme;
  }

  return {
    ...theme,
    setTheme: ({ setTheme, ...theme }) => _setTheme(theme)
  };
}

export function useKsk() {
  return useContext(KskContext);
}

export function KskProvider({ children }) {
  const _theme = useTheme();
  const [theme, dispatch] = useReducer(reducer, _theme); //, init

  useEffect(
    () => {
      storage.setItem('theme', theme ? JSON.stringify(theme) : '');
    },
    [theme.mode, theme.textZoom]
  );

  return (
    <KskContext.Provider value={[theme, dispatch]}>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR_date} >
            <GlobalStyle/>
            <MuiGlobalCss/>
            { children }   
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </KskContext.Provider>
  );
}
