import Color from 'color';
import { px2em } from 'ksk/styledComponents/functions';
import { defaultThemeColor, kskTheme } from 'ksk/styledComponents/themes';
import styled from 'styled-components';
import theme from 'styled-theming';
import headerBG from 'ksk/assets/template/trianglify-header-bg.svg';

const spinnerBgColors = [
  Color(defaultThemeColor).saturate(.15).hsl(),
  Color(defaultThemeColor).saturate(.15).darken(.15).hsl()
]

const spinnerBackgroundImage = theme('mode', {
  padrao: `background-image: linear-gradient(-45deg, ${spinnerBgColors[0]}, ${spinnerBgColors[1]});`,
  altoContraste: ``,
}); 

const Aside = styled.aside`
  background-image: url(${headerBG});
  background-color: white;    
  position: fixed;
  
  transition: top .2s ease-in-out;
  top: -5em;
  left: calc(50% - 7em);
  height: 4em;
  padding: .5em 1em;
  text-align: center;  
  color: $textColor;
  z-index: 1301;
  border-radius: 0 0 ${kskTheme.borderRadius} ${kskTheme.borderRadius};
  box-shadow: 0 ${px2em(3)} ${px2em(4)} ${px2em(2)} rgba(0, 0, 0, 0.4);

  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: 1fr;
  align-items: center;
  grid-gap: .75em;


  .loading-spinner__spinner {
    box-sizing: content-box;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background-color: ${kskTheme.themeColor};
    ${spinnerBackgroundImage};
    padding: 0.35em;
    
  }

  .loading-spinner__running {
    font-size: 1.1em;
  }


  &.loading-spinner--visible {
    &.loading-spinner {
      top: 0;
    }

    .loading-spinner__spinner {
      animation: box-shadow-animation 0.8s steps(12) infinite;
      //animation-direction: alternate;
    }

    .loading-spinner__outer-circle {
      transform-origin: 50px;  
      animation: outer-circle-animation 0.8s steps(12) infinite;
    }
    
    .loading-spinner__inner-circle {  
      transform-origin: 50px;
      animation: inner-circle-animation 0.6s steps(12) infinite;
    }
    
    .loading-spinner__running {
      animation: running-animation 1s steps(8) infinite;
      animation-direction: alternate;
    }
  }

  @keyframes box-shadow-animation {
    0% {
      box-shadow: 0 0 0 0 ${kskTheme.themeColor};
    }

    20% {
      box-shadow: 0 0 0 2px ${kskTheme.themeColor};
    }
    
    100% {
      box-shadow: 0 0 0 8px transparent;
    }
  }

  @keyframes outer-circle-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes inner-circle-animation {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }


  @keyframes running-animation {
    0% {
      color: ${kskTheme.textColor};
    }
    100% {
      color: ${kskTheme.themeColor};
    }    
  }
`;

export const S = {
  Aside
};