import Color from 'color';
import librasLogoVector from 'ksk/assets/template/libras-logo-vector.svg';
import trianglifyBG from 'ksk/assets/template/trianglify-header-bg.svg';
import { createGlobalStyle, css } from 'styled-components';
import theme from 'styled-theming';
import { px2em } from './functions';
import { device, hoverActiveFocus } from './mixins';
import { baseFontSize, baseLineHeight, defaultThemeColor, kskTheme } from './themes';

import faHandPointerRegular from 'ksk/assets/template/faHandPointerRegular.svg';
import { faHandPointer } from '@fortawesome/free-regular-svg-icons';

const librasBgColor = theme('mode', {
  padrao: Color(defaultThemeColor).darken(.35).saturate(.35).hsl(),
  altoContraste: 'black'
});

const GlobalStyle = createGlobalStyle`
html,
body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: ${baseFontSize};
  line-height: ${ baseLineHeight };
  height: 100%;
  min-height: 80vh;
  min-width: 360px;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  ${device.onlyPhone(css`
      font-size: 12px;
    `)}
}



a,
button {
  cursor: pointer;
  
}

a, button, main, footer, input, select  {
  ${hoverActiveFocus(css`
    outline: none;
  `)}
}

a {
  color: ${kskTheme.linkColor};
  text-decoration: none;

  ${hoverActiveFocus(css`
    color: ${kskTheme.hoverLinkColor};
    text-decoration: none;
  `)}
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  line-height: 1.1;
  color :inherit;
}

.list-style-none {
  list-style: none;
}



.text-selection-disabled {  
  user-select: none;  
}

.cursor-pointer.cursor-pointer {
  cursor: pointer;
}

.cursor-move.cursor-move {
  cursor: move;
}

.cursor-help.cursor-help {
  cursor: help;
}

.cursor-not-allowed.cursor-not-allowed {
  cursor: not-allowed;
}

.small-text {
  font-size: .8em;
}

/* h3, h4, h5, h6 {
  font-size: px2em(14);  
}
 */
var {
  font-style: normal;
}

.monospace {
  font-family: Courier New, Courier, monospace;
  font-size: 0.928571429rem;
}

.display-block.display-block{
  display: block;
}

.display-none.display-none {
  display: none;
}

.inline-block.inline-block {
  display: inline-block;
}

.normal.normal {
  font-weight: normal;
}

.bold.bold {
  font-weight: bold;
}

.semibold.semibold {
  font-weight: 600;
}

.italic.italic {
  font-style: italic;
}

.underline.underline {
  text-decoration: underline;
}

.align-center.align-center {
  text-align: center;
}

.align-left.align-left {
  text-align: left;
}

.align-right.align-right {
  text-align: right;
}

.justify.justify {
  text-align: justify;
}

/*===================================================================================*/
.theme-text-color {
  color: ${kskTheme.themeColor};
}

.text-color-over-theme-color {
  color: ${kskTheme.extOverThemeColor};
}

.default-text-color{
  color: ${kskTheme.text};
}

.default-background-color {
  background-color: ${kskTheme.defaultBackground};
}

.dark-bg {
  background-color: ${kskTheme.darkText};
}

.dark-text {
  color: ${kskTheme.darkText};
}

.light-text { 
  color: ${kskTheme.lightText};
}

.disabled-text { 
  color: ${kskTheme.disabledText};
}

.disclaimer-text {
  color: ${kskTheme.disclaimerText};
}

.primary-text {
  color: ${kskTheme.primaryColor}
}

.primary-bg {
  background-color: ${kskTheme.primaryColor};
}

.secondary-text {
  color: ${kskTheme.secondaryColor}
}

.secondary-bg {
  background-color: ${kskTheme.secondaryColor};
}

.success-text {
  color: ${kskTheme.successColor}
}

.success-bg {
  background-color: ${kskTheme.successColor}
}

.warn-text {
  color: ${kskTheme.warnColor}
}

.warn-bg {
  background-color: ${kskTheme.warnColor};
}

.danger-text {
  color: ${kskTheme.dangerColor}
}

.danger-bg {
  background-color: ${kskTheme.dangerColor};
}

.info-text {
  color: ${kskTheme.infoColor}
}

.info-bg {
  background-color: ${kskTheme.infoColor};
}

.text-over-primary {
  color: ${kskTheme.textOverPrimaryColor};
}

.text-over-secondary {
  color: ${kskTheme.textOverSecondaryColor};
};

.text-over-success {
  color: ${kskTheme.textOverSuccessColor};
}

.text-over-warn {
  color: ${kskTheme.textOverWarnColor};
}

.text-over-danger {
  color: ${kskTheme.textOverDangerColor};
}

.text-over-info {
  color: ${kskTheme.textOverInfoColor};
}

/*=====================================================================================*/

${
  /** Classes utilitárias de padding e margin
  p = padding, m = margir
  t = top; r = right; b = bottom; l = left
  v = vertical; h = horizontal
  Número que segue é o valor da propriedade na unidade 'em'

  ex: p-025 = padding de 0.25em
      ml-05 = margin-left de 0.5em
      mh-075 = margin horizontal (left e right) de 0.75em
      pv-125 = padding vertical (top e bottom) de 1.25em
 */
  [0, .25, .5, .75, 1, 1.25, 1.5, 1.75, 2].map(v => {
    const factor = `${v}`.replace('.', '');
    const marginClass = `m-${factor}`;
    const marginVClass = `mv-${factor}`;
    const marginHClass = `mh-${factor}`;
    const paddingClass = `p-${factor}`;
    const paddingVClass = `pv-${factor}`;
    const paddingHClass = `ph-${factor}`;
    const emValue = `${v}em`;
    return css`
      .${marginClass}.${marginClass} {
        margin: ${emValue};
      }

      .${marginVClass}.${marginVClass} {
        margin-top: ${emValue};
        margin-bottom: ${emValue};
      }

      .${marginHClass}.${marginHClass} {
        margin-left: ${emValue};
        margin-right: ${emValue};
      }

      .${paddingClass}.${paddingClass} {
        padding: ${emValue};
      }

      .${paddingVClass}.${paddingVClass} {
        padding-top: ${emValue};
        padding-bottom: ${emValue};
      }

      .${paddingHClass}.${paddingHClass} {
        padding-left: ${emValue};
        padding-right: ${emValue};
      }

      ${
        ['top', 'right', 'bottom', 'left'].map(pos =>  {
          const marginPosClass = `m${pos.substring(0,1)}-${factor}`;
          const paddingPosClass = `p${pos.substring(0,1)}-${factor}`;
          return css`
            .${marginPosClass}.${marginPosClass} {
              margin-${pos}: ${emValue};
            }
            .${paddingPosClass}.${paddingPosClass} {
              padding-${pos}: ${emValue};
            }
        `})
      }
    `})
}

.screen-reader,
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.disabled-text {
  color: ${kskTheme.disabledText};
}


.disclaimer-text.disclaimer-text {
  color: ${kskTheme.disclaimerText};
}

.cpf {
  font-family: monospace;
  letter-spacing: ${px2em(-2)};
}

.full-width {
  width: 100%;
}

.display-flex.display-flex {
  display: flex;
}

.flex-direction-column.flex-direction-column {
  flex-direction: column;
}

.flex-end.flex-end {
  align-items: flex-end;
}

.flex-center.flex-center {
  align-items: center;
}

.justify-content-center.justify-content-center {
  justify-content: center;
}

.ksk-button-container + .ksk-button-container {
  margin-left: .75em;
}

.gap-05em,
.grid-gap-05em {
  grid-gap: 0.5em;
}

.gap-1em,
.grid-gap-1em {
  grid-gap: 1em;
}



.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

#barra-brasil {
  background-color: ${kskTheme.themeColor} !important;
  background-image: url(${trianglifyBG}) !important;
  background-position: center bottom !important;
  transition: background-color 1s ease;
  /* box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3) !important;*/
}

#barra-brasil .conteudo-barra-brasil {
  max-width: unset !important;
  width: calc(100% - 24px) !important;
  height: 30px !important;  
}

#barra-brasil a {
  color: ${kskTheme.textOverThemeColor} !important;
}

body div[vw] {
  top: 205px !important;
  margin-top: 0 !important;
  //transition: top .3s ease, right .3s ease;
  border: 2px solid rgba(255,255,255,.6) !important;
  border-radius: 8px;
  z-index: 9998 !important;
  
  
  ${device.onlyPhone(css`
    top: 68px !important;
   
  `)}

  // > [vw-access-button] {
  //  margin-left: -102px;
  //  width: 142px;
  //} 

  [vw-access-button] .access-button {
    opacity: 0;
  }

  > [vw-access-button]::after {
    content: ' ';
    display: block;
    background-image: url(${librasLogoVector}) !important;
    background-size: 26px 26px;
    background-position: 7px 7px;
    background-color: hsl(164,55.4%,23.4%);
    background-repeat: no-repeat;
    z-index: 31;
    position: absolute;
    height: 41px;
    width: 40px;
  }

  background-color: ${librasBgColor};
 //  background-image: url(${librasLogoVector}) !important;
 // background-size: 26px 26px;
 // background-position: 7px 7px;
 // background-repeat: no-repeat;

  
  [vw-plugin-wrapper].active  {
    top: 1.8em;
  }

  &[data-libras-layout=desktop-overlay] {
      [vw-plugin-wrapper].active  {
      top: 12em;
    }
  }

  &[data-libras-layout=phone] {
      [vw-plugin-wrapper].active  {
      top: 9em;
    }
  }

  &[data-libras-layout=phone-overlay] {
      [vw-plugin-wrapper].active  {
      top: 13em;
    }
  }
}

.mat-dialog-container {
  padding: 0!important;
  -moz-border-radius:7px!important;
  -webkit-border-radius:7px!important;
  border-radius:17px!important;
  border: 1px solid black!important;
}

#info-modal:hover{
  -moz-box-shadow:3px 1px 1px 1px;
  -webkit-box-shadow:3px 1px 1px 1px;
  box-shadow: 3px 1px 1px 1px;
}

.bgBlue {
  background-color: blue !important;
}



/** Classes for the displayed toast **/
.Toastify__toast {
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
  background-color: #3498db;
  color: ${kskTheme.textOverInfoColor};
}
.Toastify__toast--success {
  background-color: ${kskTheme.successColor};
  color: ${kskTheme.textOverSuccessColor};
}
.Toastify__toast--warning {
  background-color: ${kskTheme.warnColor};
  color: ${kskTheme.textOverWarnColor};
}
.Toastify__toast--error {
  background-color: ${kskTheme.dangerColor};
  color: ${kskTheme.textOverDangerColor};
}
.Toastify__toast-body {
}

.ellipsis {
  max-width: 100ch;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.kskSimpleList {
  padding-left: 2em;
  margin-bottom: 0;
}

@keyframes ksk-animation-error-shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
}

.ksk-animation-error-shake {
  animation: ksk-animation-error-shake 0.5s infinite;
}

.bg-red.bg-red {
  background: #f005;
}

.white-space-pre.white-space-pre {
  white-space: pre;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.tagify__dropdown.tagify__dropdown {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding:.25em 0;

  &[position=text] {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    font-size: 1em;
  }
}

.tagify__dropdown__wrapper.tagify__dropdown__wrapper {
  border: none;
}

.tagify__dropdown__item.tagify__dropdown__item {
  font-size: 1em;
  padding: .75em 1.5em;

  color: ${kskTheme.text};

  &--active {
    background-color: ${kskTheme.dropdownHoverColor};
    color: ${kskTheme.text}
  }
}

.pointer-events-none { 
  pointer-events: none; 
} 


.ksk-input-rico {
  .note-editor.note-frame .note-editing-area .note-editable[contenteditable=false], 
  .note-editor.note-airframe .note-editing-area .note-editable[contenteditable=false] {
    background-color: #f6f6f6;
    cursor: not-allowed;
  }

  &--readonly {
    .note-btn {
      cursor: not-allowed;
    }
  }
}

.ksk-form__helper {
  padding: 0.5em 0px 0.5em .75em;
  flex-direction: row;
  gap: 0.3333em;
  align-items: center;
  
  color: rgba(0, 0, 0, 0.54);
  color: #555;
  margin: 0;
  display: flex;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  white-space: pre-wrap;

  letter-spacing: 0.03333em;
}

.ksk-form__helper-info {
  font-size:1.25em;
}


`;

export default GlobalStyle;
