import Color from 'color';
import theme from 'styled-theming';
import { bestContrast } from './functions';

export const defaultThemeColor = 'hsl(164, 41%, 36%)';
export const defaultPrimaryColor = defaultThemeColor;
export const defaultSecondaryColor = '#aaa';
export const baseFontSize = '14px';
export const baseLineHeight = 1.5;

export const kskPalette = {
  lightBlue: 'hsl(203, 40%, 52%)', // #5490b6
  red:       'hsl(  7, 71%, 55%)', // #dd4b39 Danger
  green:     '#00a65a',  //Success
  aqua:      'hsl(192, 100%, 40%)', //'#00c0ef'  Info
  yellow:    'hsl(37, 90%, 43%)',  //Warning hsl(37, 90%, 51%)
  blue:      '#0073b7',
  navy:      '#001F3F',
  teal:      '#39CCCC',
  olive:     '#3D9970',
  lime:      '#01FF70',
  orange:    '#FF851B',
  fuchsia:   '#F012BE',
  purple:    '#605ca8',
  maroon:    '#D81B60',
  black:     '#111',
  gray:      '#d2d6de',
  materialRed: '#f44336'
}



export const kskTheme = {
  themeColor: theme('mode', {
    padrao: defaultThemeColor,
    altoContraste: 'hsl(220, 13%, 1%)'
  }),

  boldThemeColor: theme('mode', {
    padrao: Color(defaultThemeColor).darken(.2),
    altoContraste: 'hsl(220, 13%, 5%)'
  }),

  lightThemeColor: theme('mode', {
    padrao: Color(defaultThemeColor).lighten(1.75),
    altoContraste: 'hsl(220, 13%, 5%)'
  }),

  backgroundColor: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#040506'
  }),

  /* fontFamily: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }), */

  textOverThemeColor: theme('mode', {
    padrao: bestContrast(defaultThemeColor, 'rgba(0,0,0,.85)', 'rgba(255,255,255,.95)'),
    altoContraste: 'white'
  }),

  text: theme('mode', {
    padrao: 'rgba(0,0,0,.8)',
    altoContraste: 'rgba(255,255,255,.98)'
  }),

  defaultBackground: theme('mode', {
    padrao: 'rgba(255,255,255,1)',
    altoContraste: 'rgba(0,0,0,1)'
  }),

  darkText: theme('mode', {
    padrao: 'rgba(0,0,0,.95)',
    altoContraste: 'rgba(255,255,255)'
  }),

  lightText: theme('mode', {
    padrao: 'rgba(0,0,0,.65)',
    altoContraste: 'rgba(255,255,255,.93)'
  }),

  disabledText: theme('mode', {
    padrao: 'rgba(0,0,0,.35)',
    altoContraste: 'rgba(255,255,255,.90)'
  }),

  disclaimerText: theme('mode', {
    padrao: 'rgba(0,0,0,.6666667)',
    altoContraste: 'rgba(255,255,255,.95)'
  }),

  outputFieldColor: theme('mode', {
    padrao: 'rgba(0,0,0,.8)', 
    altoContraste: 'rgba(255,255,255,.90)'
  }),

  lineColor: theme('mode', {
    padrao: 'rgba(0,0,0,.1)',
    altoContraste: 'rgba(255,255,255,.95)'
  }),

  inputBorderColor: theme('mode', {
    padrao: 'rgb(148, 148, 148)',
    altoContraste: 'rgba(255,255,255,.95)'
  }),

  boldLineColor: theme('mode', {
    padrao: 'rgba(0,0,0,.2)',
    altoContraste: 'rgba(255,255,255,.98)'
  }),

  disabledLineColor: theme('mode', {
    padrao: 'rgba(0, 0, 0, 0.38)',
    altoContraste: 'rgba(255,255,255,.90)'
  }),

  defaultBorderColor: theme('mode', {
    padrao: 'rgba(0,0,0,.2)',
    altoContraste: 'rgba(255,255,255,.95)'
  }),

  borderRadius: theme('mode', {
    padrao: '.25em',
    altoContraste: '.25em'
  }),

  linkColor: theme('mode', {
    // padrao: 'hsl(211deg 100% 50%)',
    // altoContraste: 'cyan'
    padrao: Color(defaultThemeColor).desaturate(.20).darken(.15).hsl(),
    altoContraste: 'lime'
  }),

  hoverLinkColor: theme('mode', {
    padrao: 'hsl(211deg 100% 30%)',
    altoContraste: 'magenta'
  }),

  primaryColor: theme('mode', {
    padrao: () => kskTheme.themeColor ,
    altoContraste: 'cyan'
  }),

  textOverPrimaryColor: theme('mode', {
    padrao: () => kskTheme.textOverThemeColor,
    altoContraste: 'black'
  }),

  secondaryColor: theme('mode', {
    padrao: '#ccc' ,
    altoContraste: '#fff'
  }),

  textOverSecondaryColor: theme('mode', {
    padrao: () => kskTheme.text,
    altoContraste: 'black'
  }),

  successColor: theme('mode', {
    padrao: kskPalette.green,
    altoContraste: 'lime'
  }),

  textOverSuccessColor: theme('mode', {
    padrao: () => bestContrast(kskTheme.successColor, 'rgba(0,0,0,.85)', 'rgba(255,255,255,.95)'),
    altoContraste: 'black'
  }),

  warnColor: theme('mode', {
    padrao: kskPalette.orange,
    altoContraste: 'yellow'
  }),

  textOverWarnColor: theme('mode', {
    padrao: () => bestContrast(kskTheme.warnColor, 'rgba(0,0,0,.85)', 'rgba(255,255,255,.95)'),
    altoContraste: 'black'
  }),

  dangerColor: theme('mode', {
    padrao: kskPalette.red,
    altoContraste: 'pink'
  }),

  materialRed: theme('mode', {
    padrao: kskPalette.materialRed,
    altoContraste: 'pink'
  }),

  textOverDangerColor: theme('mode', {
    padrao: () => bestContrast(kskTheme.dangerColor, 'rgba(0,0,0,.85)', 'rgba(255,255,255,.95)'),
    altoContraste: 'black'
  }),

  infoColor: theme('mode', {
    padrao: kskPalette.aqua,
    altoContraste: 'cyan'
  }),

  textOverInfoColor: theme('mode', {
    padrao: () => bestContrast(kskTheme.successColor, 'rgba(0,0,0,.85)', 'rgba(255,255,255,.95)'),
    altoContraste: 'black'
  }),

  dropdownHoverColor: theme('mode', {
    padrao: 'rgba(0, 0, 0, 0.12)',
    altoContraste: 'white'
  }),

  muiErrorColor: theme('mode', {
    padrao: '#f44336',
    altoContraste: '#f44336'
  }),
  

  

  /*
  disabledOpacity: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }), */

  // COLORS
  /* primaryColor: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  textOnPrimary: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  highlightBackground: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  hoverBackground: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  focusOutline: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  error: theme('mode', {
    padrao: '#fafafa',
    altoContraste : '#222'
  }),*/

  // SURFACES
/*   ground: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  content: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  border: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }), */


  // FORMS
  /* background: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  hoverBorder: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  focusBorder: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  filledBackground: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }), */

  // BUTTONS
  /* secondaryBackground: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  successBackground: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  successText: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  infoBackground: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  infoText: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  warnBackground: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  warnText: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  helpBackground: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  helpText: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  dangerBackground: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  dangerText: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }), */


  // MESSAGES
  /* infoMsg: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  successMsg: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  warningMsg: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }),

  errorMsg: theme('mode', {
    padrao: '#fafafa',
    altoContraste: '#222'
  }), */

};

/*
const palette = {
  lightBlue: 'hsl(203, 40%, 52%)', // #5490b6
  red:       'hsl(  7, 71%, 55%)', // #dd4b39 Danger
  green:     '#00a65a',  //Success
  aqua:      '#00c0ef',  //Info
  yellow:    '#f39c12',  //Warning
  blue:      '#0073b7',
  navy:      '#001F3F',
  teal:      '#39CCCC',
  olive:     '#3D9970',
  lime:      '#01FF70',
  orange:    '#FF851B',
  fuchsia:   '#F012BE',
  purple:    '#605ca8',
  maroon:    '#D81B60',
  black:     '#111',
  gray:      '#d2d6de',
}

const lightTheme = {
  textColor: 'rgba(0,0,0,0.8)',
  lightTextColor: 'rgba(255,255,255,.9)',
  contrastTextColor: '#eee',    
  linkColor: '#007bff',
  bodyBg: '#ecf0f5',
  siconfi: '#618ca6',
  siconfiLight: '#5390b7',
  operacoesCredito: 'hsl(280, 31%, 47%)',
  lineColor: '#f4f4f4',
  themeColor: 'hsl(164, 41%, 41%)',
  //themeColor: 'black',
  okColor: 'green',
  notOkColor: 'red'
}
*/

export const kskThemeGet = (prop) =>  kskTheme[prop];