import { isString } from 'lodash';
import React from 'react';
import KskTooltip from './KskTooltip';

const defaultTooltipProps = {
  interactive: true,
  arrow: true,
  placement:"bottom"
 }

const KskTooltipWrapper = ({children, tooltipProps}) => {
  const _tooltipProps = isString(tooltipProps)
    ? {...defaultTooltipProps, title: tooltipProps}
    : {...defaultTooltipProps, ...tooltipProps};
  
  return (
    <KskTooltip {..._tooltipProps}>{children}</KskTooltip>
  );
};

export default KskTooltipWrapper;

