import trianglifyBg from 'ksk/assets/template/trianglify-header-bg.svg';
import { device } from 'ksk/styledComponents/mixins';
import { kskTheme } from 'ksk/styledComponents/themes';
import styled, { css } from 'styled-components';


const Footer = styled.footer`
  border: none;
  margin: 0;

  figure {
    margin: 0;
  }
  
  

  .footer__banners {
    padding: 4em 2em;  
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1.5em 2em; 
   

    ${device.phone(css`
      
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      justify-items: center;
      grid-gap: 4em; 
     
    `)}

    ${device.tablet(css`
      grid-template-columns: auto auto;
      grid-auto-rows: auto;
     
    `)}

    ${device.desktop(css`
      grid-template-columns: auto auto auto auto;
     
    `)}


    background-color: ${kskTheme.themeColor};
    background-image: url(${trianglifyBg});
    background-position: center bottom;
    border: none;  
    color: white;
    min-height: px2em(100);


  }

  .footer__versao-aplicacao {
    padding: 1em;
    border-radius: 0.5em;
    text-align: center;
    width: fit-content;
  }

  ${device.tablet(css`
      .footer__acesso-informacao {
        justify-self: end;
        margin-right: 1em;
      }
     
  `)}

  .footer__stack-horizontal,
  .footer__stack-vertical {
    display: flex;
  }

  .footer__stack-vertical {
    flex-direction: column;
  }
  

 
`;

export const S = {
  Footer
};