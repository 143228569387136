
import { useCallback,  useMemo } from 'react';
import { useAtom } from "jotai";
import { createStamper } from 'localization/stamper';

import appLocales from './appLocales';
import { currentLocaleAtom, stamperMapAtom } from './localeAtoms';

function useStamper() {
  const [currentLocale, setCurrentLocale] = useAtom(currentLocaleAtom);
  const [stamperMap] = useAtom(stamperMapAtom)

  const setLocale = useCallback((locale) => setCurrentLocale(locale), [setCurrentLocale]);
  const getLocale = useCallback(() => currentLocale, [currentLocale]);

  const getStamper = useCallback((locale) => {
    const targetStamper = stamperMap.get(locale);
    if (!targetStamper) {
      const newStamper = createStamper();
      stamperMap.set(locale, newStamper);
      return newStamper;
    }
    return targetStamper;
  }, [stamperMap]);

  const localize = useCallback((locale, context, config) => {
    const stamper = getStamper(locale);
    stamper.configure(context, config);
  }, [getStamper]);

  const stamper = useMemo(() => {
    return getStamper(currentLocale);
  }, [currentLocale, getStamper]);

  const fallbackStamper = useMemo(() => {
    return getStamper(appLocales.PT_BR);
  }, [getStamper]);

/*   const stamp = useCallback((...keys) => 
    stamper.stamp(...keys) ?? fallbackStamper.stamp(...keys)
  , [fallbackStamper, stamper]); */

  const getText = (keys, method) => {
    return stamper[method](...keys) || fallbackStamper[method](...keys) || '__RÓTULO_NÃO_ENCONTRADO__';
  }

  return {
    setLocale,
    getLocale,
    localize,
    currentStamper: stamper,
    stamp: (...keys) => getText(keys, 'stamp') ,
    Stamp: (...keys) => getText(keys, 'Stamp'),
    STAMP: (...keys) => getText(keys, 'STAMP'),
    stamps: (...keys) => getText(keys, 'stamps'),
    Stamps: (...keys) => getText(keys, 'Stamps'),
    STAMPS: (...keys) => getText(keys, 'STAMPS'),
  };
}

export default useStamper;

