import { useEffect, useState } from 'react';

function useWindowScroll() {
  const [scrollY, setScrollY] = useState(() => 0);

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  useEffect(() => {
    // Handler to call on window resize
    function handleScroll() {
      setScrollY(() => window.scrollY);
    }
    
    // Add event listener
    window.addEventListener("scroll", handleScroll);
    
    // Call handler right away so state gets updated with initial window size
    handleScroll();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Empty array ensures that effect is only run on mount

  return [scrollY, scrollTop];
}

export default useWindowScroll;