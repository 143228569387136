import { bestContrast } from 'ksk/styledComponents/functions';
import { device } from 'ksk/styledComponents/mixins';
import styled, { css } from 'styled-components';

export const KskGrid = styled.div.attrs(props => ({className: `ksk-grid ${props?.className || ''}`}))`
  display: grid;
  grid-gap: 1em;
  padding: .25em;
  align-items: ${({alignItems}) => alignItems || 'start'  };
  
  ${gridTemplateColumns()}
  ${device.phone(css`${defs('phone')}`)} 
  ${device.tablet(css`${defs('tablet')}`)} 
  ${device.desktop(css`${defs('desktop')}`)} 

  ${({debug}) => debug ? css` > * {
    background: ${debug} !important;
    color: ${bestContrast(debug, 'black',  'white')} !important; }` : `` 
  }

  ${({ subgrid }) => subgrid && css`
    padding: 0;
  `}

  .new-row {
    grid-column-start: 1;
  }
`;
 
function defs(deviceName) {
  return ({ theme, columns, totalWidth }) => {
    let templateLiteral = `
      max-width: ${ (totalWidth || {[deviceName]: '100%'})[deviceName] || '100%' };
    `;

    for (let i = 0; i < columns; i++) {
      templateLiteral += `
        .${deviceName}-${i + 1} {
          grid-column: span ${i + 1};
        }

        .${deviceName}-start-${i + 1} {
          grid-column-start: ${i + 1};
        }

        .${deviceName}-end-${i + 1} {
          grid-column-end: ${i + 2};
        }
      `;
    }
        return templateLiteral;
    }

}

function gridTemplateColumns() {
  return ({columns}) => columns ? css`grid-template-columns: repeat(${columns}, 1fr);` : css``
}

/* function toAlpha({number = 0, start = 0} =  {}) {
  const i = number - start;
  return (i >= 26 ? this.toAlpha((i / 26 >> 0) - 1) : '') +  'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26 >> 0];
} */

export default KskGrid;