import {getFeature} from './features.config';

const mainMenuConfig = {
  submenus: [
    {
      id: 'configuracoes',
      label: 'Configurações',
      icon: '',
      title: 'Gerencie informações sensíveis do sistema',
      items: [
        { feature: getFeature('PERFIL')  },
        { feature: getFeature('FINALIDADE')},
        { feature: getFeature('AUTORIDADE_EMITENTE')},
        { feature: getFeature('LISTAS')},        
        { feature: getFeature('CONDICAO')},
        { feature: getFeature('LIMITE')},
        { feature: getFeature('PROGRAMA')},
        { feature: getFeature('TIPO_ANALISE')},        
        { feature: getFeature('PARAMETRO_CALCULO')},
        { feature: getFeature('MODELOQUADROANALISE')},
      ]
    },
    {
      id: 'analise',
      label: 'Análise',
      icon: '',
      title: 'Análise',
      items: [
        { feature: getFeature('PVL')},
        { feature: getFeature('LIMITE_SIMULACAO')},
        { feature: getFeature('CONSULTA_DOCUMENTO')},
        { feature: getFeature('NORMATIVO')},
        { feature: getFeature('DEMANDA_JUDICIAL')},
      ]
    },
    {
      id: 'cadDividaPublica',
      label: 'Cadastro da Dívida Pública',
      icon: '',
      title: 'Cadastro da Dívida Pública',
      items: [
        { feature: getFeature('COC')},
      ]
    },
    {
      id: 'publico',
      label: 'Público',
      icon: '',
      title: 'Público',
      items: [
        { feature: getFeature('CONSULTA_PUBLICA')},
      ]
    }
  ]
};

export default mainMenuConfig;

export const getMenu = (idFeature) => {
  return mainMenuConfig.submenus.find(sub=>sub.items.find(i=>i.feature.id==idFeature))?.label;
};