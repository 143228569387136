
import { formatJSONDate } from 'utils/date-utils';
import { applyMask, removeMask } from './maskUtils';
import { formatos } from './maskUtils'
import { get, isArray, isObject, padEnd} from 'lodash';
import React from 'react';
import { all, create } from 'mathjs';
import KskTooltip from 'ksk/styledComponents/components/KskPopover/KskTooltip';

/**
 * ESTA FUNÇÃO NÃO É UM FORMATTER, MAS UMA FUNÇÃO DE ORDEM SUPERIOR QUE CRIA FORMATTERS.
 * 
 * ESTA FUNÇÃO RECEBE UMA FUNÇÃO DE FORMATAÇÃO E SEUS ARGUMENTOS E RETORNA UM NOVO FORMATTER.
 * 
 * Um formatter é uma função que recebe um valor e retorna uma string formatada de acordo como uma regra específica,
 * que foi definida na função de formatação.
 * 
 * EM UM FORMATTER, O VALOR A SER FORMATADO É SEMPRE O PRIMEIRO ARGUMENTO.
 * 
 * 
 * @param {*} fn Função que contém a lógica de formatação.
 * @param  {...any} args Argumentos da função que contém a lógica de formatação.
 * @returns Um formatter que irá pegar o primeiro argumento e passar para a função formatadora fn.
 */
const createFormatter = (fn, ...args) => (val) => fn(val, ...args);


/**
 * Formatter para ser utilizado quando se quer aplicar um formatter em cada um dos itens
 * de um array.
 * @param {*} formatter Formatador a ser aplicado em cada item do array.
 * @returns Função formatadora de itens de um array.
 */
export const arrayMapFormatter = (formatter, { separator = ', ' } = {}) => {
  const mapFn = (arr = [], fn) => arr.map(createFormatter(fn)).join(separator);
  return createFormatter(mapFn, formatter);
}

/**
 * Função que cria um encadeamento de formatters.
 * 
 * A função pipeFormatters recebe uma lista de formatters e retorna uma função que encadeia esses formatters.
 * Sendo que a saída de um formatter é a entrada do próximo.
 *
 * @param {...Function} formatters - Os formatters a serem encadeados.
 * @returns {Function} - A função encadeada resultante.
 */
export const pipeFormatters = (...formatters) => formatter => formatters.reduce((output, curr) => {
  return curr(output)
} , formatter);


/**
 * Formata uma data. 
 * @param {*} format formato da data.
 * @returns função formatadora de data.
 */
export const dateFormatter = (format) => createFormatter(formatJSONDate, format);


/**
 * Formata um domínio.
 * @param {*} dominio Domínio geral.
 * @returns função formatadora de domínio geral.
 */
export const dominioFormatter = (dominio) => createFormatter(dominio
  ? (val, dominio) => dominio?.find(d => d.codigo === val)?.nome
  : (val) => val?.nome
,dominio);

/**
 * Formatter para aplicar uma máscara em um valor.
 * @param {*} strMask Máscara a ser aplicada.
 * @returns Função formatadora que aplica máscara.;
 */
export const maskFormatter = (strMask) => createFormatter(applyMask, strMask);

/**
 * Formatter para remover uma máscara de um valor.
 * @param {*} strMask Máscara a ser removida.
 * @returns Função formatadora que remove máscara.
 * 
 */
export const unmaskFormatter = (strMask) => createFormatter(removeMask, strMask);

// Configuração do mathJs
const mathJsconfig = {
  epsilon: 1e-12,
  matrix: 'Matrix',
  number: 'BigNumber',
  precision: 24,
  predictable: false,
  randomSeed: null
}

// Instância do mathJs, a ser utilizada para formatação de números decimais.
const mathJsInstance = create(all, mathJsconfig);

/**
 * Formatter para números decimais.
 * @param {*} fixedPrecison Precisão do número.
 * @returns Função formatadora de números decimais.
 */
export const decimalFormatter = ({ fixedPrecison = 0 } = {}) => {
  const precison = Number(fixedPrecison);

  return createFormatter((number) => {
    if (number != null && !isNaN(number)) {
      const numberRounded = precison != null ? mathJsInstance.round(number, precison) : number;
      const [intPart, decimalPart] = String(numberRounded).split('.');
      const numberFormated = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        + ((precison > 0 || decimalPart) ? "," : '')
        + ((!precison && decimalPart) ? decimalPart : '')
        + ((precison) ? padEnd(decimalPart, precison, '0') : '');
      return numberFormated;
    } else {
      return ''
    }
  });
}


/**
 * Formata um número para o formato brasileiro.
 * Vírgula como separador decimal e ponto como separador de milhar.
 * 
 * @returns {Function} Função formatadora.
 */
export const brNumberFormat = () => createFormatter(
  (number) => (number != null && !isNaN(number)) 
    ? String(number).replace('.', ',')
    : ''
);

/**
 * Formata um número percentual.
 * @param {*} param0 
 * @returns 
 */
export const percentualFormatter = ({fixedPrecison = 0} = {}) => 
  createFormatter((number) => {
    return decimalFormatter({fixedPrecison})(number*100);
});

/**
 * Formata valores booleanos. Atribuindo um rótulo para true e outro para false.
 * @param {*} labelTrue 
 * @param {*} labelFalse 
 * @returns Função formatadora de valores booleanos.
 */
export const booleanFormatter = (labelTrue, labelFalse) => createFormatter((bool) => bool ? labelTrue : labelFalse);



/**
 * Formata uma string para exibir apenas uma substring com um tamanho máximo.
 *
 * @param {number} tam - O tamanho máximo da substring.
 * @returns {Function} - A função de formatação que recebe a string a ser formatada.
 */
export const substringFormatter = (tam) => createFormatter((val) => val.length > tam ? `${val.substring(0,tam)}...` : val);

export const bitTextFormatter = (maxChars) => createFormatter(
  (val) => (
    val.length > maxChars 
    ? <KskTooltip popover arrow title="Texto" body={val.length > 3000 ? val.substring(0, 3000) + '...' : val} size="lg">
        <span>{val.substring(0,maxChars)}...</span>
      </KskTooltip>   
    : val
  )
);


/**
 * Formata um valor booleano para 'Sim' ou 'Não'.
 * @returns Função formatadora de valores booleanos.
 */
export const boolFmt = createFormatter((val) => val === 'Sim' ? 'true' : (val === 'Não' ? 'false' : 'erro'));

/**
 * Formatter que retorna a propriedade de um objeto em um caminho específico.
 * @param {*} path Path dentro do objeto que contém o valor a ser recuperado.
 * @param {*} defaultValue Valor padrão a ser retornado caso o caminho não exista.
 * @returns Função formatadora que retorna a propriedade de um objeto em um caminho específico.
 */
export const getFormatter = (path, defaultValue) => createFormatter((val) => get(val, path, defaultValue));


/**
 * Formatter que transforma um objeto em uma string JSON.
 * @param {*} pretty Se true imprime o JSON com quebra de linha e identação.
 * @returns Função formatadora que transforma um objeto em uma string JSON.
 */
export const jsonFormatter = (pretty = false) => createFormatter((val) => JSON.stringify(val, null, pretty && 2));

export const arrayFormatter = ({separator = ', ', path}) => 
  createFormatter((arr = []) => arr.sort(function (a,b){return a.nome.localeCompare(b.nome)}).map(i => isObject(i) ? get(i, path) : i).join(separator));


/**
 * Formatter que transforma um array em uma string separada por um separador
 * @param {*} separator separador
 * @returns Função formatadora que transforma um array em uma string separada por um separador
 */
export const joinFormatter = (separator = ', ') => createFormatter(val => isArray(val) ? val.join(separator) : val);


/**
 * Formatter que aplica um prefixo a um valor.
 * @param {*} prefix Prefixo a ser aplicado.
 * @returns Função formatadora que aplica um prefixo a um valor.
 */
export const prefixFormatter = (prefix) => createFormatter((val) => `${val}${prefix}`);

/**
 * Formatter que aplica um sufixo a um valor.
 * @param {*} suffix Sufixo a ser aplicado.
 * @returns Função formatadora que aplica um sufixo a um valor.
 */
export const suffixFormatter = (suffix) => createFormatter((val) => `${val}${suffix}`);

// #############################################################################################
// FORMATTERS REUTILIZÁVEIS MAIS COMUNS
export const brDateFormatter = dateFormatter('dd/MM/yyyy');
export const brDiaMesFormatter = dateFormatter('dd/MMM');
export const brDateTimeFormatter = dateFormatter('dd/MM/yyyy - HH:mm:ss');
export const nupFormatter = maskFormatter(formatos.nup.mask);
export const cpfFormatter = maskFormatter(formatos.cpf.mask);
export const identificadorSiconfiFormatter = maskFormatter(formatos.identificadorsiconfi.mask);
export const cnpjFormatter = maskFormatter(formatos.cnpj.mask);
export const nupUnmaskFormatter = unmaskFormatter(formatos.nup.mask);
export const cpfUnmaskFormatter = unmaskFormatter(formatos.cpf.mask);
export const identificadorSiconfiUnmaskFormatter = unmaskFormatter(formatos.identificadorsiconfi.mask);
export const cnpjUnmaskjFormatter = unmaskFormatter(formatos.cnpj.mask);
export const simNaoFormatter = booleanFormatter('Sim', 'Não');

export const pessoaFormatter = (val) => {
  const rawVal = val?.replace(/[^0-9]/g,'');
  return rawVal.length === 11 ? cpfFormatter(rawVal) : cnpjFormatter(rawVal);
}
// #############################################################################################
