import axios from 'axios';
import { Subject } from 'rxjs';
import TokenService from '../services/TokenService';
import { toast } from 'react-toastify';
import React from 'react';
import KskToast  from 'ksk/styledComponents/components/KskToast/KskToast';
import { blobToStringSync } from './stringUtils';

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PATH}`,
  
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

let pendingRequests = 0;
const pendingRequests$ = new Subject(0); 

const incrementPendingRequests = () => {
  pendingRequests++;
  pendingRequests$.next(pendingRequests);
  //console.log('Pending Requests = ' + pendingRequests);
}

const decrementPendingRequests = () => {
  pendingRequests--;
  pendingRequests$.next(pendingRequests);
  //console.log('Pending Requests = ' + pendingRequests);
}

//const pipe = (...handlers) => arg => handlers.reduce((output, actual) => actual ? actual(output) : output, arg);
const pipeRequestHandlers = (...handlers) => arg => handlers.reduce((output, actual) => actual?.request ? actual.request(output) : output, arg);
const pipeResponseHandlers = (...handlers) => arg => handlers.reduce((output, actual) => actual?.response ? actual.response(output) : output, arg);

const autorizationHandler = {
  request: (config) => {
    if (!config.headers.Authorization) {
      const token = TokenService?.getAuthData()?.jwt ?? '';
      if (token) {
        config.headers.Authorization = token;
      }
    }
    return config;
  },

  response: (response) => {
    const newAuthorization = response.headers.authorization;
    //console.log('token response', newAuthorization);
    if (newAuthorization) {
      TokenService.setAuthData({jwt: newAuthorization});
    } 
    return response;
  }
}

const responseErrorHandler = (error) => {
  if (error?.response?.config?.responseType === 'blob') {
    error.response.data = JSON.parse(blobToStringSync(error?.response?.data));
  } 

  toast.error(<KskToast error httpError={error} />);
  return error;
};

http.interceptors.request.use(
  (config) => { 
    incrementPendingRequests();
    return pipeRequestHandlers(autorizationHandler)(config);
  },
  (error) => {
    decrementPendingRequests();
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    decrementPendingRequests();
    return pipeResponseHandlers(autorizationHandler)(response);
  },
  (error) => {
    decrementPendingRequests();    
    if(error && error.response && error.response.data 
      && error.response.data.status === 401) {
      TokenService.removeAuthData();
    }
    
    return Promise.reject(responseErrorHandler(error));
  }
);

export const watchPendingRequests$ = pendingRequests$.asObservable();
export default http;

/* const HANDLER_TEMPLATE = {
  request: (config) => {
    return config;
  },
  response: (response) => {
    return response;
  }
}; */
