import KskGrid from 'ksk/styledComponents/components/KskGrid/KskGrid';
import KskPlaceHolder from 'ksk/styledComponents/components/KskPlaceHolder/KskPlaceHolder';
import React from 'react';

const PagePlaceholder = ({children, title, breadcrumb}) => {
  return (
    <KskGrid style={{padding: '1em 0 2em 0'}} columns={1}>
      <KskPlaceHolder height="2.5em" width="250px"/>
      <KskPlaceHolder width={'400px'}/>
      <KskPlaceHolder showDefaultText fontSize="3em" height={'450px'}/>
    </KskGrid>
  );
}

export default PagePlaceholder;
