
import { useCallback,  useMemo } from 'react';
import { useAtom } from "jotai";
import appLocales from './appLocales';
import { currentLocaleAtom, messagesMapAtom } from './localeAtoms';
import { createMessageStore } from './messageStore';

function useMessages() {
  const [currentLocale] = useAtom(currentLocaleAtom);
  const [messagesMap] = useAtom(messagesMapAtom)

  const getMessageStore = useCallback((locale) => {
    const targetStore = messagesMap.get(locale);
    if (!targetStore) {
      const newStore = createMessageStore();
      messagesMap.set(locale, newStore);
      return newStore;
    }
    return targetStore;
  }, [messagesMap]);

  const localize = useCallback((locale, context, config) => {
    const messageStore = getMessageStore(locale);
    messageStore.configure(context, config);
  }, [getMessageStore]);

  const messageStore = useMemo(() => {  
    return getMessageStore(currentLocale);
  }, [currentLocale, getMessageStore]);

  const fallbackMessageStore = useMemo(() => {
    return getMessageStore(appLocales.PT_BR);
  }, [getMessageStore]);

  const getMessageFn = (key, args) => {
    return (
      messageStore.message(key, args) 
      || fallbackMessageStore.message(key, args) 
      || '__MENSAGEM_NÃO_ENCONTRADA__s'
    );
  }

  return {
    localize,
    currentMessageStore: messageStore,
    message: (key, args) => getMessageFn(key, args) ,
    
  };
}

export default useMessages;

