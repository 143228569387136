import useLocalizeStamper from "./useLocalizeStamper";

function useGlobalStamper() {
  /**
   * Shared stamper deve ser preenchido com os stamps que sejam compartilhados 
   * em mais de um contexto (feature)
   */
  useLocalizeStamper({
    context: 'shared', 
    config: {
      abrangencia: 'abrangência[s]',
      amortizacao: 'amortizaç[ão|ões]',
      analise: 'análise[s]',
      autoridadeEmitente: 'autoridade[s] emitente[s]',
      carimboTempo: 'carimbo[s] do tempo',
      condicao: 'condiç[ão|ões]',
      condicaoAnalise: 'condiç[ão|ões] de análise',
      configuracao: 'configuraç[ão|ões]',
      consorcio_entidade:'consórcio[s] público[s] ou entidade[s] empresarial[ais]',
      contrapartida: 'contrapartida[s]',
      custo_divida:'custo[s] da dívida',
      demandaJudicial: 'demanda[s] judicia[l|is]',
      dispositivo: 'dispositivo[s]',
      documento: 'documento[s]',
      enteFederativo: 'ente[s] federativo[s]',
      esfera: 'esfera[s]',
      etiquetaAssociada: 'etiqueta[s] associada[s]',
      finalidade: 'finalidade[s]',
      fonte: 'fonte[s]',
      formulaAnalise: 'fórmula[s] de análise',
      liberacao: 'liberaç[ão|ões]',
      limite: 'limite[s]',
      modeloQuadroAnalise: 'modelo do quadro[s] de análise[s]',
      modulo: 'módulo[s]',
      nomeDocumento: 'Nome do documento',
      normativo: 'normativo[s]',
      normativoDispositivo: 'normativo[s] e dispositivo[s]',
      parcela: 'parcela[s]',
      periodicidade: 'periodicidade[s]',
      programa: 'programa[s]',
      quadroAnalise: 'quadro[s] de análise[s]',
      servico_divida:'serviço[s] da[a] dívida[s]',
      tipoAnalise: 'tipo[s] de análise',
      tipoCertificado: 'tipo de certificado',
      tipoChecagem: 'tipo de checagem',
      tipoDocumento: 'tipo[s] do documento',
      tipoVerificacao: 'tipo[s] de verificação',
      tipoVinculacao: 'tipo[s] de vinculação',
      nup: 'NUP',
      numeroUnicoProtocolo: 'número único de protocolo',
      identificacaoNormativo: 'identificaç[ão|ões] do[s] normativo[s]',
      dataNormativo: 'data[s] do[s] normativo[s]',
      dataAssinatura: 'data[s] da[s] assinatura[s]',
      nomeDocumento: 'nome[s] do[s] documento[s]',
      tipoDosDocumento: 'tipo[s] do[s] documento[s]',
      dataDocumento: 'data[s] do[s] documento[s]',
      dataSituacao: 'data da situação',
      validadeDocumento: 'validade do[s] documento[s]',
      nivelAcesso: 'níve[l|is] de acesso',
      informacaoPessoal: 'informaç[ão|ões] pessoa[l|is]',
      acessarNormativo: 'acessar normativo[s]',
      acessarDemandaJudicial: 'acessar demanda[s] judicia[l|is]'
    }
  });

  /**
   * Common stamper deve preenchido com palavras e verbos comuns com a finalidade de aumentar
   * a padronização.
   */
  useLocalizeStamper({
    context: 'common', 
    config: {
      acao: 'aç[ão|ões]',
      adicionar: 'adicionar',
      ano: 'ano[s]',
      
      atencao: 'atenção',
      ativar: 'ativar',
      arquivo: 'arquivo[s]',
      botaoVoltar: 'retorna à página anterior',      
      cpf: 'cpf',
      cnpj: 'cnpj',
      confirmar: 'confirmar',
      contendoAlguma: 'contendo alguma desta[s]',
      contendoAlgum: 'contendo algum deste[s]',
      contendoCadaUma: 'contendo cada uma dessa[s]',
      contendoCadaUm: 'contendo cada um desse[s]',
      contendoTodos: 'contendo todos estes',
      contendoTodas: 'contendo todas estas',
      conteudo:'conteúdo[s]',
      contida: 'contida[s] a[s]',
      contido: 'contido[s] o[s]',
      dadoBasico: 'dado[s] básico[s]',
      da: 'da[s]',
      data: 'data[s]',
      descartar: 'descartar',
      descartar_tip: 'descartadas as alterações desde o último salvamento',
      identificacao: 'identificaç[ão|ões]',
      ibge: 'ibge',
      descricao: 'descriç[ão|ões]',
      detalhe: 'detalhe[s]',
      editar: 'editar',
      email: 'e-mail',
      emSuaIntegra: 'em sua íntegra',      
      excluir: 'excluir',
      exportar: 'exportar',
      fechar: 'fechar',
      historico: 'histórico[s]',
      baixar: 'baixar',
      filtro: 'filtro[s]',
      fimPeriodo: 'fi[m|ns] do[s] período[s]',
      fimVigencia: 'fi[m|ns] da[s] vigência[s]',
      habilitarEdicao: 'habilitar edição',
      identificadorSiconfi: 'identificador Siconfi',
      inativar: 'inativar',
      incluir: 'incluir',
      indisponivel: 'indisponíve[l|is]',
      inicioPeriodo: 'início[s] do[s] período[s]',
      inicioVigencia: 'início[s] da[s] vigência[s]',
      limpar: 'limpar',
      listaDeVersoes: 'lista[s] de versões',
      mes: 'm[ês|eses]',
      nenhum: 'nenhum',
      nenhuma: 'nenhuma',
      nome: 'nome[s]',
      nomeReduzido: 'nome[s] reduzido[s]',
      numero: 'número[s]',      
      periodo: 'período[s]',
      pesquisar: 'pesquisar',
      previsualizacao: 'pré-visualizaç[ão|ões]',
      resposta: 'resposta',
      retornar: 'retornar',
      retornarPara: 'retornar para',
      salvar: 'salvar',
      salvar_tip: 'Salva este registro',
      salvar_fechar: 'salvar e fechar',
      salvar_fechar_tip: 'Salva este registro e retorna para a página de detalhe',
      sigla: 'sigla[s]',
      simboloReal: 'R$',
      situacao: 'situaç[ão|ões]',
      texto: 'texto[s]',
      tipo: 'tipo[s]',
      url:'url[s]',
      valor: 'valor[es]',
      versaoAnterior: 'vers[ão|ões] anterior[es]',
      versaoSeguinte: 'vers[ão|ões] seguinte[s]',
      vigenteNaData: 'vigente[s] na[s] data[s]',
      visualizar: 'visualizar',
      voltar: 'voltar',
      
    }
  });

/*

useLocalizeStamper({
  context: 'dominio', 
  config: {

    logico: '',
    
    tipoDocumento: '',
    tipoAnalise: '',
    tipoVerificacaoCondicao: '',
    tipoVerificacaoLimite: '',
    tipoVinculoNormativo: '',
    abrangenciaEsfera: '',
    tipoPrograma: '',
    tipoModuloDemandaJudicial: '',
    tipoPeriodicidadeParametroCalculo: '',
    tipoDadoParametroCalculo: '',
    tipoEntradaParametroCalculo: '',
    tipoMes: '',
    tipoOperacaoTipoAnalise: '',
    especiePvlTipoAnalise: '',
    emissorDebentureTipoAnalise: '',
    instituicaoDebentureTipoAnalise: '',
    emissorTitulosPúblicosTipoAnalise: '',
    instituicaoTitulosPublicosTipoAnalise: '',
    tomadoresTipoAnalise: '',
    credoresTipoAnalise: '',
    garantidoresTipoAnalise: '',
    agFinanceirosTipoAnalise: '',
    tipoDirecaoQuadro: '',
    tipoRegistroQuadro: '',
    tipoMascara: '',
    tipoDivida: '',
    tipoDevedor: '',
    tipoEnte: '',
    tipoPessoa: '',
    origemEntidade: '',
   
  }
});
*/
  return null;
}

export default useGlobalStamper