import { faCaretDown, faExchangeAlt, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from 'auth/AuthContext';

import logoOverlay from 'ksk/assets/template/svg-logo-overlay.svg';
import logo from 'ksk/assets/template/svg-logo.svg';
import useWindowScroll from 'ksk/hooks/useWindowScroll';
import useWindowSize from 'ksk/hooks/useWindowSize';
import KskTooltip from 'ksk/styledComponents/components/KskPopover/KskTooltip';
import React, { useEffect, useState, useLayoutEffect } from 'react';


import MainMenu from '../MainMenu/MainMenu';
import { S } from './MainHeader.styles';
import siconfiPages from 'config/siconfi.config';
import BuscaRapidaIdSiconfi from 'pages/id_siconfi/BuscaRapidaIdSiconfi';


function useMainMenu() {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const toggleMenu = () => setIsMenuExpanded((prev) => !prev);
  return [isMenuExpanded, setIsMenuExpanded, toggleMenu];
}

function MainHeader() {  
  // const [theme, dispatch] = useKsk();
  const { isPhone, isTablet, isDesktop } =  useWindowSize();
  const [isMenuExpanded, setIsMenuExpanded, toggleMenu] = useMainMenu();
  const [scrollY, scrollTop] = useWindowScroll();

  const { credentials, reverseSignOn, isAuthenticated } = useAuth();

  const showOverlay = ((isPhone && (scrollY > 100)) || (isTablet && (scrollY > 100)) || (isDesktop && (scrollY > 120))); 
  const appLogo = isDesktop ? logo : logoOverlay;

  {/* vlibras */}
  const vLibrasConfig = {
    overlayOn_isPhone: {
      apply: (node) => {
        node.style.setProperty('transition', 'top .3s ease, right .3s ease');
        node.style.setProperty('right', '0', 'important');
        node.style.setProperty('top', '36px', 'important');
        node.setAttribute('data-libras-layout', 'phone-overlay');
        
      }
    },
    overlayOn_isNotPhone: {
      apply: (node) => {
        node.style.setProperty('transition', 'top .3s ease, right .3s ease');
        node.style.setProperty('top', '42px', 'important');
        node.setAttribute('data-libras-layout', 'desktop-overlay');
      }
    },
    overlayOff_isPhone: {
      apply: (node) => {
        node.style.setProperty('transition', 'unset');
        node.style.setProperty('top', `${68 - scrollY}px`, 'important');
        node.style.setProperty('right', '0');
        node.setAttribute('data-libras-layout', 'phone');
      }
    },
    overlayOff_isNotPhone: {
      apply: (node) => {
        node.style.setProperty('transition', 'unset');
        node.style.setProperty('top', `${205 - scrollY}px`, 'important');
        node.setAttribute('data-libras-layout', 'desktop');
      }
    },
  }

  useLayoutEffect(() => {
    const overlay = showOverlay ? 'overlayOn' : 'overlayOff';
    const phone = isPhone ? '_isPhone' : '_isNotPhone';
    const vLibras = document.querySelector('div[vw]');    
    
    if (vLibras) {
      vLibrasConfig[overlay + phone].apply(vLibras);
    } 
  }, [scrollY, showOverlay, vLibrasConfig]);

  return (
    <>
      <S.Header>
        {/* logotipo do sistema */}
        {isAuthenticated&&
          <KskTooltip title="Selecionar módulo do sistema"
            body="Encerra a sessão no módulo Operações de Crédito e vai para a página de seleção de módulos na Área Administrativa." popover arrow>
            <button className="header__logo" type="button" onClick={reverseSignOn} style={{ border: 'none', background: 'transparent' }}>
              <figure className="header__logo-figure" >
                <img alt="Logotipo do Siconfi" className="header__logo-img" src={appLogo} />
              </figure>
            </button>
          </KskTooltip>}
        {!isAuthenticated&&
          <figure className="header__logo header__logo-figure" style={{ border: 'none', background: 'transparent' }}>
            <img alt="Logotipo do Siconfi" className="header__logo-img" src={appLogo} />
          </figure>
        }

        {/* menu do usuario */}
        <Credenciais className="header__credenciais" credentials={credentials}></Credenciais>

        <nav className="header__top-menu" role="menubar">
          {/* busca rápida */}
          {isAuthenticated&&<BuscaRapidaIdSiconfi/>}
        </nav>

        {/* cabecalho flutuante */}
        <aside className={`header__overlay-panel ${showOverlay ? '' : 'header__overlay-panel--hidden'}`}>    
          <KskTooltip title="Selecionar módulo do sistema" 
                      body="Encerra a sessão no módulo Operações de Crédito e vai para a página de seleção de módulos na Área Administrativa." popover arrow>
            <button className="header__logo" type="button" onClick={reverseSignOn} style={{border: 'none', background: 'transparent'}}>
              <figure className="header__logo-container-overlay">
                <img  alt="Logotipo do Siconfi" src={logoOverlay} className="header__logo-overlay-img"/>
              </figure>
            </button>
          </KskTooltip>
      
          <section className="header__credentials-container-overlay" role="group">
            {isAuthenticated && <div>
              <div>
                <var>{ credentials.nome }</var>
              </div>
              <div>
                <var> { credentials.orgao }</var>
              </div>
              <div>
                <var> { credentials.nomePerfil } </var>
              </div>
            </div>}
          </section>
      
          <button className="goto-top" type="button" onClick={scrollTop}>{isPhone ? '⬆' : '⬆ Topo'}</button>
        </aside>
      </S.Header>

      {/* menu de funcionalidades flutuante */}
      {isAuthenticated&&<MainMenu isMenuExpanded={isMenuExpanded} setIsMenuExpanded={setIsMenuExpanded} toggleMenu={toggleMenu} />}
    </>
  );
}


function Credenciais({className, credentials}) {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const { remoteLogout, reverseSignOn, isAuthenticated} = useAuth()

  useEffect(() => {
    const onAnyWindowClickSetDropDownFalse = () => dropdownOpen && setDropDownOpen(false);

    window.addEventListener('click', onAnyWindowClickSetDropDownFalse, false);
    return () => window.removeEventListener('click', onAnyWindowClickSetDropDownFalse, false)
  }, [dropdownOpen])


  function handleToggleDropdown() {
    setDropDownOpen(actualDropdownOpen => !actualDropdownOpen);
  }

  function handleAcessarAreaRestrita() {
    window.location.href = siconfiPages.LOGIN_AREA_RESTRITA;
  }

  return(
    <> 
    {isAuthenticated &&
      <S.Credenciais className={className} role="group">
          <div>
            <div>
              <var>{ credentials.nome }</var>
            </div>
            <div>
              <var> { credentials.orgao }</var>
            </div>
            <div>
              <var> { credentials.nomePerfil } </var>
            </div>
          </div>
    
          <button className="credenciais__toggle" type="button" onClick={handleToggleDropdown}>
            <FontAwesomeIcon icon={faCaretDown} /> 
          </button>

          {
            dropdownOpen && (
              <div className="credenciais__dropdown">
                <S.SessionActionBtn onClick={reverseSignOn}  >
                  <div>Acesso aos módulos</div>
                  <FontAwesomeIcon icon={faExchangeAlt} />
                </S.SessionActionBtn>
                

                <S.SessionActionBtn type="button" onClick={remoteLogout} >
                  <div>Sair com segurança</div>
                  <FontAwesomeIcon icon={faPowerOff} /> 
                </S.SessionActionBtn>
            
            {/*     <S.SessionAction to={'/'}  >
                  <div>Selecionar vínculo</div>
                  <FontAwesomeIcon icon={faExchangeAlt} />
                </S.SessionAction> */}


              {/*  <S.SessionAction to={'/Logout'}  >
                  <div>Sair</div>
                  <FontAwesomeIcon icon={faPowerOff} /> 
                </S.SessionAction> */}
            </div>
          )
        }
      </S.Credenciais>}
    {/* ************ */}
    {!isAuthenticated &&
      <S.Credenciais className={className} role="group" style={{display:'block'}}>
        <button className="credenciais__toggle bold button" type="button" title="Acessar área restrita do Siconfi" onClick={handleAcessarAreaRestrita} style={{width:'12em', height:'40px'}}>
            <span>Acessar área restrita</span>
        </button>
      </S.Credenciais>
    }
    </>
  );
}

export default MainHeader;
