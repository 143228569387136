import useLocalizeMessages from "./useLocalizeMessages";

function useGlobalMessages() {

  /**
   * Mensagens comuns.
   */
  useLocalizeMessages({
    context: 'common', 
    config: {
      helperPeriodo: ([artigo, campo]) => `Período em que deve estar contid${artigo} ${artigo} ${campo}.`,
      cliqueParaDetalhar: () => `Clique para detalhar`
    }
  });

  return null;
}

export default useGlobalMessages;