import { useAuth } from "./AuthContext";

const Protected = ({requires, fallback = null, children} = {}) => {
  const { isAuthorized } = useAuth();

  return isAuthorized(requires)
   ? children
   : fallback;
}

export default Protected;

