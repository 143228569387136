import { isFunction } from "lodash";

/**
 * Loga um valor e o retorna para ser usado no fluxo, normalmente
 * @param {*} val 
 * @returns val
 */
export const debug = (val, message) => {
  if (message) {
    console.log(message, val);
  } else {
    console.log(val);
  }
  return val;
}

export const debugMode = (fn) => {
  const isDebugMode = localStorage.getItem('__app_debug_mode__') === 'true';
  if (!isDebugMode || !isFunction(fn)) {
    return;
  }
  fn();
}

/**
 * Informa toda vez que uma função especifica for chamada e com quais argumentos ela foi chamada,
 * 
 * Ex: para nossa função: function hello(name) { console.log('hello', name); }
  * adicionamos o override
 * hello = track(hello);
 * Pronto, vc será avisado toda vez que "hello" for executada, e verá o trace (de onde ela foi chamada) e com quais args.
 * 
 * @param {*} fn  função a ser rastreada
 * @returns  proxy que rastreia a função desejada
 */
export const track = fn => {
  return new Proxy(fn, {
    apply: function(target, thisArg, argumentsList) {
      console.trace('tracked: ', target, thisArg, argumentsList);
  
      return target.apply(thisArg, argumentsList);
    }        
  });
}

/**
 * Rastreia todas as vezes que uma classe foi instanciada, onde e com que argumentos
 * 
 * Exemplo Date = checkCreation(Date)
 * Isso rastreará todas as vezes que uma Objeto Date for criado
 * 
 * @param {*} clazz Classe que será rastreada
 * @returns Proxy da classe que será rastreada.
 */
export const checkCreation = clazz => {
  return new Proxy(clazz, {
     construct(target, args) {
      console.trace(`New ${target.name}`, args);
  
      return new target(...args);
    }   
  });
}