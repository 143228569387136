import { atom } from "jotai";
import { focusAtom } from 'jotai/optics';
import appLocales from "./appLocales";

const localeAtom = atom({
  currentLocale: appLocales.PT_BR, 
  stamperMap: new Map(), 
  messagesMap: new Map()
});

export const currentLocaleAtom = focusAtom(localeAtom, (optic) => optic.prop('currentLocale'));
export const stamperMapAtom = focusAtom(localeAtom, (optic) => optic.prop('stamperMap'));
export const messagesMapAtom = focusAtom(localeAtom, (optic) => optic.prop('messagesMap'));