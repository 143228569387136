import { each, isArray, isDate, isEmpty, isFunction, isMap, isObject, isPlainObject, isSet, isString, keyBy, transform, unset } from 'lodash';
import { debug } from './debug-utils';
import { flatten } from './flat-utils';

const dominioGeralDTOKeys = ['nomeDominio', 'id', 'nome', 'codigo'];

/**
 * Verifica se um objeto possui todas as chaves especificadas em um array e se todos os elementos do array estão presentes como chaves no objeto.
 * @param {object} obj - O objeto a ser verificado.
 * @param {string[]} keys - O array de chaves a serem verificadas.
 * @param {boolean} [only=false] - Indica se o objeto deve conter apenas as chaves especificadas no array. Se for verdadeiro, o objeto não deve conter chaves adicionais além das especificadas.
 * @returns {boolean} - Retorna true se todas as chaves estiverem presentes no objeto e se todos os elementos do array estiverem presentes como chaves no objeto. Caso contrário, retorna false.
 */
export function hasTheseKeys(obj, keys, only) {
  if (!isObject(obj) || !isArray(keys) || !keys.length) {
    return false;
  }

  const objKeys = Object.keys(obj);
  const keysSet = new Set(keys);
  
  if (only && (objKeys.length !== keysSet.size)) {
    return false;
  }

  // Verifica se todas as chaves do objeto estão contidas no array
  const keysContained = objKeys.every(key => keys.includes(key));

  // Verifica se todos os elementos do array estão presentes como chaves no objeto
  const elementsContained = keys.every(element => objKeys.includes(element));

  return elementsContained && (!only || keysContained );
}  

export const isDominioGeralDTO = (obj) => hasTheseKeys(obj, dominioGeralDTOKeys);

export const filterKeys = (obj, allowedKeys) => Object.keys(obj)
  .filter(key => allowedKeys.includes(key))
  .reduce((output, key) => {
    output[key] = obj[key];
    return output;
  }, {});

export const simplifyDominioGeralDTO = (obj) => isDominioGeralDTO(obj) 
  ? filterKeys(obj, ['codigo'])
  : obj;


export const isEmptyObject = obj => {
  for (var i in obj) return false;
  return true;
}

export const doNothing = () => {};

export const stopEvent = (e) => {
  e.stopPropagation();
  e.preventDefault()
}

export const tryCatch = (fn, whenCatch) => {
  try {
    return fn();
  } catch (e) {
    return typeof whenCatch === 'function' ? whenCatch() : whenCatch;
  }
} 

/* export const arrayToMap = ({array, keyPropName, valuePropName}) => array.reduce(
  (output, actual) => output.set(actual?.[keyPropName],  valuePropName ? actual[valuePropName] : actual) , 
  new Map()
); */

export const arrayToMap = ({array = [], mapFn}) => array.reduce(
  (output, actual) => {
    const mapped = mapFn(actual);
    return output.set(mapped.key,  mapped.value);
  } , 
  new Map()
);

export const arrayToObject = ({array = [], mapFn}) => array.reduce(
  (output, actual, i) => {
    const mapped = mapFn(actual, i);
    //console.log('mapped', mapped)
    output[mapped.key] = mapped.value;
    //console.log('output', output)
    return output;
  } , 
  {}
);

export const hasSameValues = (arr1 = [], arr2 = []) => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0, length = arr1?.length; i < length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

export const hasDuplicates = (array) => (new Set(array)).size !== array.length


export const componentErrorMsg = ({componentName, name, id, message}) => 
`Erro no componente ${componentName}\nname = '${name}'\nid = '${id}'\n${message}`; 

export const isArrayOrSet = (obj) => Array.isArray(obj) || obj instanceof Set;
export const isEmptyArrayOrSet = (obj) => (Array.isArray(obj) &&  !obj.length) || (obj instanceof Set && !obj.size);


/* export const isEmptyValue = (val) => (
  (val == null)   
  || (typeof val === 'string' && val.trim() === '') 
  || isEmptyArrayOrSet(val) 
  || isEmptyObject(val)
); */

export function isEmptyValue(value) {
  if (isDate(value)) {
    return false;
  }

  return (
    value == null ||
    (isArray(value) && value.length === 0) ||
    (isPlainObject(value) && Object.keys(value).length === 0) ||
    (isString(value) && value.trim() === '') ||
    (isSet(value) && value.size === 0) ||
    (isMap(value) && value.size === 0)
  );
}

/* window['isEmptyValue'] = isEmptyValue;
window[ 'isEmptyObject'] = isEmptyObject;
window['_isEmpty'] = isEmpty;
window['isDominioGeralDTO'] = isDominioGeralDTO; */

export const removeEmpty = (obj, deep) => {
  if (deep) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => !isEmptyValue(v))
        .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v, true) : v])
    );
  } else {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !isEmptyValue(v)));
  }
}

/* window['removeEmpty'] = removeEmpty; */


//###############################################################################################
// LIMPAR OBJETOS

export function deepOmit(obj, keysToOmit) {
  var keysToOmitIndex =  keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit] ); // create an index object of the keys that should be omitted

  function omitFromObject(obj) { // the inner function which will be called recursivley
    return transform(obj, function(result, value, key) { // transform to a new object
      if (key in keysToOmitIndex) { // if the key is in the index skip it
        return;
      }

      result[key] = isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
    })
  }
  return omitFromObject(obj); // return the inner function result
}

export function deepOmitBy(obj, predicateFn = (value, key) => true) {
  function omitFromObject(obj) {
    return transform(obj, function(result, value, key) {
      if (isObject(value)) {
        result[key] = omitFromObject(value); // Chamada recursiva se o valor for um objeto
        if (isEmpty(result[key])) {
          delete result[key]; // Remove a chave se o objeto resultante estiver vazio
        }
      } else if (!predicateFn(value, key)) {
        result[key] = value; // Adiciona a chave ao objeto resultante se não for para omitir
      }
    });
  }

  const result = omitFromObject(obj);
  return result;
}

export function mutateOmitByRecursive(value, filterFn) {
  each(value, (value, key) => {
    if(filterFn(value, key)) {
      unset(value, key); 
    } else if(isObject(value)) {
      mutateOmitByRecursive(value, filterFn);  
    }
  });
  return value;
}

export function removeUnfilledFilters(formData = {}) {
  const result = {};

  const cleanObject = (obj) => (    
    isDominioGeralDTO(obj) 
     ? removeEmpty(simplifyDominioGeralDTO(obj))
     : removeEmpty(obj)
  );
  // console.log('formdata '+ JSON.stringify(formData))
  for (const key in formData) {
    const value = formData[key];

    if (isArray(value) && value.length) {
      const mapped =  
        value.map((v, i) => (isObject(v) && !isEmptyObject(v) ? cleanObject(v) : v))
             .filter(v => !isEmpty(v));
      if (mapped.length) {
        result[key] = mapped;
      }
      continue;
    }

    if (isObject(value) && !isEmptyObject(value)) {
      const cleaned = cleanObject(value);
      if (!isEmptyObject(cleaned)) {
        result[key] = cleanObject(value);
      }
      continue;
    }

    if (isString(value)) {
      if (!isEmpty(value.trim())) {
        result[key] = value.trim();
      }
      continue;
    }
    
    if ( !isEmpty(value) || isDate(value)) {
      result[key] = value;
      continue;
    } 
  }

  // console.log('result: '+JSON.stringify(result))

  return result;
}


export const buildUrlSearchParams = (formData = {}) => {
  return new URLSearchParams(
    flatten(removeUnfilledFilters(formData), {arrayBrackets: true})
  ).toString();
}

export function cleanSearchObject(entity) {
  return deepOmitBy(entity, (value, key) => isDominioGeralDTO(debug(value, `[${key}]=`)))
}

//###############################################################################################

export const preserveDefaultValues = (obj = {}, defaultValues = {}) => {
  //const absentKeys = difference(Object.keys(defaultValues), Object.keys(obj));
  const absentKeys = Object.keys(defaultValues).filter(k => !Object.keys(obj).includes(k));

  const defaultValueOfAbsentKeys = absentKeys.reduce((output, key) => ({...output, [key]: defaultValues?.[key]}), {});
  return {...obj, ...defaultValueOfAbsentKeys};
}

export const handleDominiosOnSearch = (obj) => Object.keys(obj)
  .reduce((output, key) => ({...output, [key]: simplifyDominioGeralDTO(obj[key])})
  , {});

export const newArray = (length, fill) => new Array(length).fill(fill);

export const isFocused = (ref) => document.activeElement === ref?.current;

/**
 * Default JWT_REGEX = /^([a-zA-Z0-9\-_]{1,}\.){2}[a-zA-Z0-9\-_]{1,}$/;
 * @param prefix Prefixo do JWT
 */
 function jwtRegex(prefix = '') {
  return new RegExp('^' + prefix  + '([a-zA-Z0-9\-_]{1,}\.){2}[a-zA-Z0-9\-_]{1,}$');
}

/**
 * Decodifica o token JWT
 * @param jwt Token JWT
 * @param prefix Prefixo do Token
 */
export function decode(jwt = '', prefix  = '') {
  if (!jwtRegex(prefix).test(jwt)) {
    return null;
  }
  // return JSON.parse(atob(jwt.split('.')[1]));
  return JSON.parse(b64DecodeUnicode(jwt.split('.')[1]));
}

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map( c => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}


//export const isArray = (obj) => !!(obj && typeof obj === 'object' && obj.constructor === Array);
//export const isRegExp = (obj) => obj && (typeof obj === 'object') && (obj?.constructor?.name === 'RegExp');

export const isLastItem = (arr, index) => (isArray(arr) && index != null) && (index === arr.length - 1);
export const getLastItem = (arr) => isArray(arr) && arr.length === 0 ? null : arr[arr.length - 1];

const _genericPipe = (fnA, fnB) => (arg) => fnB(fnA(arg));

export const pipe = (...ops) => ops.reduce(_genericPipe);

export const mergeReactRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return inst => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const configureCallback = ({ value, when = [], callback, args = []} = {}) => {
  if (when.includes(value)) {
    isFunction(callback) && callback.apply(args);
  }
}

/* const bc = ({ target}) =>  {
  const when = (arrayTest = []) => {
    return {
      then: (callback) => {
        if (arrayTest.includes(target)) {
          isFunction(callback) && callback();
        }
      },
      otherwise: (callback) => { 
        isFunction() && callback();
      }
    }
  }

  return {when}
}


bindCallback({ target: e.key })
.when(['Backspace', 'Delete']).then(doSomething)
.when(['Space', 'Enter']).then(doOtherThing); */

export const createVanillaDOMElement = (tagName, props = {}) => {
  return Object.assign(document.createElement(tagName), props);
}

export const killEvent = (e) => {
  e.stopPropagation();
  e.preventDefault();
}

// Função utilitária genérica para converter um array de objetos em CSV com cabeçalhos personalizados
export const convertArrayToCSV = (array, columns) => {
  if (!array || !array.length) {
      return '';
  }

  // Identifica os campos que existem em ambos array e columns
  const availableFields = columns
      .filter(col => array.some(item => col.field in item))
      .map(col => ({ field: col.field, headerName: col.headerName }));

  // Cria o cabeçalho do CSV a partir dos headers correspondentes
  const header = availableFields.map(col => col.headerName).join(';');

  // Cria as linhas do CSV
  const rows = array.map(obj => {
      return availableFields
          .map(col => obj[col.field] !== undefined ? obj[col.field] : '')
          .join(';');
  });

  // Junta o cabeçalho e as linhas em uma string CSV
  return [header, ...rows].join('\n');
};

export function convertArrayToPropertyName(array, propertiesName) {
  return array.map(row => {
      let obj = {};
      row.forEach((value, index) => {
          obj[propertiesName[index]] = value;
      });
      return obj;
  });  
}

export function hasEmptyFields(array) {
  return array.some(item => {
      return Object.values(item).some(value => {
          // Verifica se o valor é vazio, nulo ou indefinido
          return value === "" || value === null || value === undefined;
      });
  });
}

export const sortDatatable = (array, sort) => {
  if (!array) {
    return [];
  }

  const { sortFields } = sort;

  // Se não houver campos de ordenação, retornar o array sem alterações
  if (!sortFields || sortFields.length === 0) {
      console.log('Se não houver campos de ordenação, retornar o array sem alterações');
      return array;
  }

  // Extrair o campo de ordenação e a direção
  const { field, dir } = sortFields[0];

  // Função de comparação para ordenação
  const comparar = (a, b) => {
      if (a[field] < b[field]) {
          return dir === 'asc' ? -1 : 1;
      }
      if (a[field] > b[field]) {
          return dir === 'asc' ? 1 : -1;
      }
      return 0;
  };

  // Ordenar o array com base na função de comparação
  return array.sort(comparar);
};

export const formatarNumeroBrasileiro = (valor) => {
  return Number(valor).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};