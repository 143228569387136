import Color from 'color';
import headerBG from 'ksk/assets/template/trianglify-header-bg.svg';
import { bestContrast, px2em } from 'ksk/styledComponents/functions';
import { device, hoverActiveFocus, inlineFlexList, isDark, isLight } from 'ksk/styledComponents/mixins';
import { defaultThemeColor, kskTheme } from 'ksk/styledComponents/themes';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const dropdownBgColor = theme('mode', {
  padrao: Color(defaultThemeColor).desaturate(.12).lighten(1.30).hsl(),
  altoContraste: 'black'
});

const dropdownBoderColor = theme('mode', {
  padrao: defaultThemeColor,
  altoContraste: 'lime'
});

const credenciaisPhoneBgColor = theme('mode', {
  padrao: 'rgba(0,0,0,.1)',
  altoContraste: 'rgba(255,255,255,.1)'
});

const Header = styled.header`

  background-color: ${kskTheme.themeColor};
  color: ${kskTheme.textOverThemeColor}; 

  background-position: center bottom;
  background-image: url(${headerBG});
  display: grid;
  grid-gap: ${px2em(2)};

  transition: background-color 1s ease, color 1s ease;

  ${ device.phone(css`
     grid-gap: ${px2em(1)};
     grid-template-rows: minmax(auto, auto) 
                         minmax(auto, auto) 
                         minmax(auto, ${px2em(52)});
     grid-template-areas: "logo"
                          "credenciais" 
                          "top-menu";
  `)}

  ${ device.tablet(css`
    grid-template-columns: ${px2em(350)} 1fr;
    grid-template-rows: minmax(${px2em(60)}, auto) 
                        minmax(auto, ${px2em(45)});
    grid-template-areas: "logo      credenciais" 
                         "top-menu  top-menu";
  `)}

  ${ device.desktop(css`
    grid-template-columns:  ${px2em(350)} 1fr;
    grid-template-rows: minmax(${px2em(60)}, auto) 
                        minmax(auto, ${px2em(45)});
    grid-template-areas: "logo      credenciais"
                         "top-menu  top-menu";
    `
  )}

  > .header__logo {
    grid-area: logo;  
  //  border: 1px dashed red;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: left;
    margin: 0;
    

    ${device.phone(css`
      padding: 0 1em .5em 0;
    `)}

    ${device.tablet(css` 
      padding: .5em 1em .5em ${px2em(8)}; 
    `)}

    ${device.desktop(css` 
      // padding: 2em 0 ${px2em(8)} ${px2em(8)};
      padding: ${px2em(8)} ${px2em(8)} ${px2em(8)} ${px2em(8)};
    `)}
  };  

  .header__logo-figure {
    margin: 0;
  }

  .header__logo-img {
    ${device.exceptDesktop(css` 
      width: ${px2em(400)};
    `)}
   }       

  > .header__atalhos {
    grid-area: atalhos;
    // border: 1px dashed red;
  }

  > .header__acessibilidade {
    grid-area: acessibilidade;
    text-align: right;
    
    // border: 1px dashed red;
  }

  > .header__credenciais {
    grid-area: credenciais;
    align-items:center;
    text-align: right;
    //border: 1px dashed red;

    ${device.exceptPhone(css` 
      /* text-align: right; */
      // padding-right: ${px2em(18)};
      padding: ${px2em(8)} ${px2em(8)} ${px2em(8)} ${px2em(8)};
    `)}

    ${device.onlyPhone(css` 
      padding: .25em 1em .25em 5em;
      background: ${credenciaisPhoneBgColor};
      line-height: 1.35;
    `)}

  }

  > .header__top-menu {
    grid-area: top-menu;
    border-top: ${px2em(1)} solid rgba(255,255,255,.2);
    padding: 0 ${px2em(8)} 0 ${px2em(8)};
   // border: 1px dashed red;
  }

  .header__lista-atalhos,
  .header__lista-acessibilidade {
    ${ inlineFlexList };
    margin: 0;
    padding: 0; 
  }

  .header__lista-acessibilidade {
    justify-content: flex-end;
    padding-right: ${px2em(8)}

  }

  .header__atalho,
  .header__item-acessibilidade {
    font-size: ${px2em(12)};
    padding: .5em .5em;
  }

  .header__atalho {
    margin-left: ${px2em(24, 14)};

    &:first-child {
      margin-left: ${px2em(12, 14)};
    }
  }

  ${device.onlyPhone(css` 
    .header__atalho {
      margin-left: 0;

      &:first-child {
        margin-left: ${px2em(4, 14)};
      }
    }
  `)}

  .header__link {
    color: ${kskTheme.textOverThemeColor};
    border: none;
    background: transparent;
    padding: 0;

    ${hoverActiveFocus(css`
    
      text-decoration: underline;
    `)}
  }

  .header__key {
    background: rgba(0,0,0,.2);
    display: inline-block;
    padding: .2em .5em;
    border-radius: ${px2em(4)};
    position: relative;
    color: #fff;
    font-size: ${px2em(10, 12)};
    line-height: 1;
    margin-left: .25em;
  }

  .header__item-acessibilidade {
    margin-left: ${px2em(24)};

    &:first-child {
      margin-left: 0;
    }
  }


  ${device.onlyPhone(css` 
    .header__item-acessibilidade {
      margin-left: 0;

      &:first-child {
        margin-left: 0;
      }
    }
  `)}

  
  .header__overlay-panel {
    display: grid;
    grid-gap: 1em;
    position: fixed;  
    line-height: 1.1;
    top: 0;
    left: 0;
    padding: .5em 4.75em .5em ${px2em(8, 14)};
    background-color: ${kskTheme.themeColor};
    background-image: url(${headerBG});
    background-position: center bottom;
    width: 100%;
    z-index: 21;

    transition: top .5s ease, opacity .6s ease;
    
    &.header__overlay-panel--hidden {
      top: ${px2em(-75)};
      opacity: 0;
    }
    
    align-items: center;
    border-bottom: ${px2em(1)} solid rgba(255,255,255,0.5);

    grid-template-columns: ${px2em(410)} 1fr ${px2em(55)};

    ${device.onlyPhone(css` 
      grid-template-columns:  1fr ${px2em(30)};
    `)}
  }

  .header__logo-container-overlay {
    margin: 0;
    
  }

  .header__logo-overlay-img {
    width: ${px2em(400)};
    ${device.onlyPhone(css`
      width: ${px2em(360)};
    `)}
  }

  .header__credentials-container-overlay {  
    ${device.onlyPhone(css` 
    display: none;
  `)}

  ${device.onlyTablet(css` 
  
  `)}

  ${device.onlyDesktop(css` 
  
  `)}

    font-size: ${px2em(12)};
    ${device.exceptPhone(css` 
      text-align: right;
    `)}

  }

  .goto-top {
      display: inline-block;
      width: 100%;
      text-align: center;
      color: #fff;
      background: rgba(255,255,255,0.1);
      padding: .5em .25em;
      border: ${px2em(1)} solid rgba(255,255,255,0.2);
      border-radius: ${kskTheme.borderRadius};
      padding: .5em .25em;
      transition: background .2s ease-in-out;

      ${hoverActiveFocus(css`
        background: rgba(0,0,0,.08);
      `)}
    }
`;

const Credenciais = styled.section`
  display: grid;
  grid-template-columns: 1fr 2em;
  grid-gap: 1em;
  position: relative;
  font-size: ${px2em(12)};

  .button
  {
    font-size: ${px2em(20)};
    margin-top: ${px2em(14.5)};

    ${device.onlyTablet(css`
      margin-top: ${px2em(6)};
    `)};

    ${device.onlyPhone(css`
      margin-top: ${px2em(4)};
      margin-bottom: ${px2em(4)};
    `)};
  }

  > .credenciais__toggle {
    display: inline-block;
    width: 100%;
    
    text-align: center;
    color: #fff;
    background: rgba(255,255,255,0.1);
    padding: .5em .25em;
    border: ${px2em(1)} solid rgba(255,255,255,0.2);
    border-radius: ${kskTheme.borderRadius};
    padding: .5em .25em;
    transition: background .2s ease-in-out;

    ${device.phone(css` 
      height: 100%;
      margin-right: 29%;
    `)}

    ${device.tablet(css` 
      height: calc(100% - 0.75em);
      margin-right: 0;
    `)}

    ${device.desktop(css` 
      height: calc(100% - 1.25em);
      margin-right: 0;
    `)}

    :hover {
      border: ${px2em(1)} solid rgba(255,255,255,0.8);
    }
  }

  > .credenciais__dropdown {
  position: absolute;
  font-size: 1rem;
  display: flex;
  flex-direction: columns;
  grid-gap: .25em;
  flex-direction: column;
  align-items: end;
  
  border: ${px2em(3, 14)} solid ${dropdownBoderColor};
  border-radius: ${kskTheme.borderRadius};
  background: ${ dropdownBgColor };
  color: ${kskTheme.textColor};
  width: fit-content;
  z-index:21;

  outline: ${px2em(1, 14)} solid #fff7;
  box-shadow: ${px2em(6, 14)} ${px2em(6, 14)} ${px2em(10, 14)} #0006;
  
  
  right: 3.5em;
  top: ${px2em(60, 14)};
}

.credenciais__link {
  padding: .5em 1em;
  width: calc(100% - 2em);
  display: grid;
  grid-template-columns: 1fr 1.5em;
  justify-items: end;
  white-space: nowrap;
  
  ${hoverActiveFocus(css`
    background: ${kskTheme.themeColor};
    color: white !important;
  `)}
}

`;

const baseSessionActionStyles = css`
  color: ${kskTheme.text};
  display: flex;
  grid-gap: 0.5em;
  align-items: center;
  padding: 1em;
  width: 100%;
  justify-content: end;

  ${hoverActiveFocus(css`
    background: ${kskTheme.themeColor};
    color: ${kskTheme.textOverThemeColor};
  `)}
`;


export const SessionAction = styled(Link)`
  ${baseSessionActionStyles}
`;


export const SessionActionBtn = styled.button`
  background: transparent;
  border: none;
  ${baseSessionActionStyles}
`;

export const S = {
  Header,
  Credenciais,
  SessionAction,
  SessionActionBtn
};
