import { useAuth } from 'auth/AuthContext';
import rj45f from 'ksk/assets/template/rj45-f.png';
import rj45m from 'ksk/assets/template/rj45-m.png';
import rj45u from 'ksk/assets/template/rj45-u.png';
import logo from 'ksk/assets/template/svg-logo.svg';
import headerBG from 'ksk/assets/template/trianglify-header-bg.svg';
import { kskTheme } from 'ksk/styledComponents/themes';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import siconfiPages from 'config/siconfi.config';
import { Link } from 'react-router-dom';
import KskTooltip from 'ksk/styledComponents/components/KskPopover/KskTooltip';


const SessionExpired = styled.main.attrs({className: 'session-expired'})`
  background-color: ${kskTheme.themeColor};
  color: ${kskTheme.textOverThemeColor}; 
  box-shadow: 0px 5px 0 ${kskTheme.themeColor};
  background-position: center bottom;
  background-image: url(${headerBG});
  background-blend-mode: soft-light;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 35px);
  min-height: 40em;

  align-items: center;
  padding-top: 10vh;
  grid-gap: 2em;

  .session_expired__logo {
    width: 350px;
  }

  .session_expired__box {
    background: white;  
    color: #333;
    width: 350px;
    padding-bottom: 2em;
    border-radius: .25em;
  }

  .session_expired__header {
    display: flex;
    flex-direction: column;
    align-items: center;
   // justify-content: center;
    overflow: hidden;
    position: relative;
  }

  .session_expired__h1 {
    font-size: 36px;
    font-weight: 100;
    margin-bottom: .25em;
  }

  .session_expired__h2 {
    font-size: 14px;
    padding: 0 1em;
    font-weight: 600;
  }

  .session_expired__body {
    padding: 1em;
  }

  .session_expired__footer {
    font-size: 14px;
    display: flex;
    gap: 1em;
    flex-direction: column;
    padding: 0 1em;
  }

  .session_expired__siconfi,
  .session_expired__login {
    background-color: ${kskTheme.themeColor};
    color:  ${kskTheme.textOverThemeColor};
    border-radius: .33em;
  }

  .session_expired__animation {
    overflow: hidden;
    position: relative;
    height: 60px;
    width: 100%;
  }

  .session_expired__rj45m,
  .session_expired__rj45u,
  .session_expired__rj45f {
    position: absolute;
    opacity: .95;
    
  }

  .session_expired__rj45m {  
    //left: 15px;  
    animation: frames-rj45m 1s linear forwards;
  }

  .session_expired__rj45u {    
    left: calc(50% - 5px);
    animation: frames-rj45u 2s linear forwards;
  }

  .session_expired__rj45f {   
    animation: frames-rj45f 1s linear forwards;
  }

  .session_expired__siconfi {
    display: grid;
    grid-gap: .5em;
    align-items: center;
    grid-template-columns:  min-content 1fr;
  }

  .session_expired__btn-icon {
    height: 2.5em;
    width: 2.5em;
    background: #0003;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .33em 0 0 .33em;
  }


  @keyframes frames-rj45m {
    0% {  left: 25px; }
    75% {   left: 25px; }
    100% { left: 0; }
  }

  @keyframes frames-rj45u {
    0% {  opacity: 0; }
    80% {   opacity: 0; }
    100% { opacity: 1; }
  }


  @keyframes frames-rj45f {
    0% {  right: 25px; }
    75% {   right: 25px; }
    100% { right: -10px; }
  }
  
`;

function LoginErro() {
  const {isAuthenticated, removeAuth, isProcessingReverseSignOn} = useAuth();

  const title = isProcessingReverseSignOn
    ? `Redirecionando...`
    : `Sessão encerrada`;

  const bodyText = isProcessingReverseSignOn
  ? `Você está sendo redirecionado. Por motivos de segurança, durante esse processo, a conexão com o módulo atual, Operações de Crédito, será encerrada.`
  : `Se você estava conectado com Certificado Digital, por questões de segurança, feche o navegador e abra-o novamente antes de efetuar um novo login com Certificado Digital.`

  useEffect(() => {
    if (isAuthenticated) {
      removeAuth();
    }
  },[]);


  return(
    <SessionExpired>
       <img className="session_expired__logo" alt="Logotipo do Siconfi" src={ logo }/>

       <section className="session_expired__box">
         <header className="session_expired__header">
           <h1 className="session_expired__h1">{title}</h1>

           <div className="session_expired__animation session_expired__animation--unplug">
							<img className="session_expired__rj45m" src={rj45m} aria-hidden="true" />
							<img className="session_expired__rj45u" alt="Desconectado" src={rj45u} aria-hidden="true" />		
							<img className="session_expired__rj45f" alt="Desconectado" src={rj45f} aria-hidden="true" />	
					  </div>
           <h2 className="session_expired__h2">A conexão com este módulo do Siconfi foi finalizada</h2>
         </header>

         <div className="session_expired__body">
           <div className="bold align-center">Observação</div>
           <p><span>{bodyText}</span></p>
         </div>

         {
          !isProcessingReverseSignOn &&
          <footer className="session_expired__footer">

            <KskTooltip title="Ir para a página inicial do Siconfi" arrow>
              <a className="session_expired__siconfi" href={siconfiPages.HOME}>
                <span className='session_expired__btn-icon'>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </span>
                <span>Página inicial do Siconfi</span>
              </a>
            </KskTooltip>

            <KskTooltip title="Ir para a página de consultas públicas" arrow>
              <Link className='session_expired__siconfi' id={`consultas-publicas-link`} to={"/consultas-publicas"}>
                <span className='session_expired__btn-icon'>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </span>
                
                <span>Consultas públicas</span>
              </Link>
            </KskTooltip>
          </footer>
         }
       </section>
    </SessionExpired>
  )
}

export default LoginErro;
