
import { useKskForm } from "ksk/styledComponents/components/KskForm/KskForm";
import { isFunction } from "lodash";
import { killEvent } from "utils/utils";

/**
 * Hook para compor um evento de onKeyDown que dispare a submissão de um formulário
 * se o usuário digitar Shift + Enter.
 * @param {Object} options - Opções para o hook.
 * @param {boolean} options.preventEnter - Indica se o evento padrão de pressionar Enter deve ser prevenido. 
 *  O valor padrão é true pois a maioria dos inputs vão tentar submeter o formulário ao pressionar Enter.
 * @param {Function} options.onKeyDown - Função de callback para o evento onKeyDown.
 * @returns {Function} - Função de callback para o evento onKeyDown que trata o "Shift + Enter".
 */
function useSubmitKskFormOnShiftEnter({ preventEnter = true, onKeyDown } = {}) {
  // Recupera o formulário KskForm
  const kskForm = useKskForm();
  
  // Handler que trata o evento de pressionar qualquer tecla
  const keyDownHandler = (e) => {
    // Se o usuário pressionou Enter, chame o handler específico.
    if (e.key === "Enter" && kskForm) {
      enterHandler(e);
    }
  }

  // Handler que trata a tecla Enter
  const enterHandler = (e) => {
    // O comportamento padrão de um input html é tentar submeter o formulário ao pressionar Enter.
    // PreventEnter tem como valor default true pois a maioria dos inputs vão tentar submeter o formulário.
    // Os casos em que preventEnter = true são os casos em que o evento vai tentar submeter o formulário, 
    // e, por isso, o evento padrão deve ser prevenido.
    // As exceções são definidas nos inputs que passarem preventEnter como false, geralmente esses inputs
    // que passam prevenEnter = false fazem o tratamento do evento de Enter de forma específica.
    if (preventEnter) {
      killEvent(e);
    }

    // Se o usuário pressionou Shift + Enter, chame o handler específico.
    if (e.shiftKey) {
      shiftEnterHandler(e);
    }
  }

  // Handler que trata a combinação de teclas Shift + Enter
  const shiftEnterHandler = (e) => {
    // Evita que o comportamento padrão do input ao pressionar enter seja executado.
    // Por exemplo: uma textarea tentaria inserir uma nova linha ao pressionar Enter e depois
    // tentaria submeter.
    // Isso previne que as duas ações sejam executadas. Só a ação de submissão será executada
    // no passo seguinte do algoritmo.
    if (!preventEnter) {
      killEvent(e);
    }

    // Se o kskForm for configurado para submeter o formulário ao pressionar Shift + Enter,
    // dispare o evento de submissão.
    if (kskForm.submitOnShiftEnter) {
      kskForm.dispatchSubmit();
    }
  }

  // Handler que compõe o onKeyDown que trata o "Shift + Enter" e agrega o onKeyDown específico
  // passado como parâmetro. 
  const composedKeyDownHandler = (e) => {
    keyDownHandler(e);

    // Se o usuário configurou um handler específico para o evento onKeyDown via props, execute esse handler também.
    if (isFunction(onKeyDown)) {
      onKeyDown(e);
    }
  };

  // O Handler composto.
  return composedKeyDownHandler;
}

export default useSubmitKskFormOnShiftEnter;