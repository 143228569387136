const siconfiURL = `${process.env.REACT_APP_SICONFI_URL}`;

const siconfiPages = Object.freeze({
  HOME: `${siconfiURL}/siconfi/index.jsf`,
  LOGIN_INTEGRACAO: `${siconfiURL}/siconfi/login/integracao`,
  LOGOUT: `${siconfiURL}/siconfi/pages/public/login/logout.jsf`,
  LOGIN_AREA_RESTRITA: `${siconfiURL}/siconfi/pages/public/login/login.jsf`
});

export default siconfiPages;


