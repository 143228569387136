import useAuthToken from 'auth/useAuthToken';
import siconfiPages from 'config/siconfi.config';
import { intersection, isArray } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import AuthService from 'services/AuthService';
import { features } from 'config/features.config';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function useUrlPublica() {
  const location = useLocation();

  return features.filter(
    feature => (feature.route.url === location.pathname.split("/")[1]) && feature.public == true
  ).length > 0;
}

export function useFeature() {
  const location = useLocation();
  // console.log('location.pathname ' + location.pathname);
  // console.log('location.pathname.split("/")[1] ' + location.pathname.split("/")[1]);
  const path = location.pathname.split("/")[1] ? location.pathname.split("/")[1] : '/';
  // console.log('path ' + path);

  return features.filter(
    feature => (feature.route.url === path)
  )[0];
}

function AuthProvider({children}) {
  const navigate = useNavigate();
  const isUrlPublica = useUrlPublica();
  const feature = useFeature();

  const { getCredentials, removeAuth } = useAuthToken();
  const credentials = getCredentials();

  const [isProcessingReverseSignOn, setIsProcessingReverseSignOn] = useState(false);
  const isAuthenticated = credentials && credentials.cpf != null;

  const loginPublico = async () => {
    try {
        await AuthService.getAll();
    } catch(error) {
      console.warn('Erro no login', error);
    } 
  }

  useEffect(() => {
    if (!isAuthenticated) {
      if (feature?.id === 'HOME') {
        navigate('/consultas-publicas', { replace: true })
      } else {
        if (isUrlPublica) {
          loginPublico();
        } else {
          navigate('/Negado', { replace: true })
        }
      }
    }
  }, [JSON.stringify(credentials), isUrlPublica]);

  const contextValue = useMemo(() => {
    const siglas = (credentials?.siglas?.split?.(',') ?? []);  //.filter(s => !s.includes('ALT')) ; // retirar

    const isAuthorized = (requires) => {

      const requirements = (
        isArray(requires)
        ? requires
        : requires == null
          ? []
          : [requires]
      );

      return (
        credentials?.acessoTotal ||
        !requirements?.length || 
        !!intersection(siglas, requirements)?.length
      );
    }

    const reverseSignOn = async ({redirectToListarPerfil} = {}) => {
      const response = await AuthService.getReverseSignOnToken();
      const auth = response?.data?.reverseSignOnToken;

      const redirectTo = !!redirectToListarPerfil ? "&redirectTo=listar_perfil" : "";

      const remoteAppUrl = `${siconfiPages.LOGIN_INTEGRACAO}?Authorization=${auth}${redirectTo}`;
      setIsProcessingReverseSignOn(true);
      removeAuth();
      window.location.replace(remoteAppUrl);
    };

    const remoteLogout = () => {
      // window.location.replace(siconfiPages.LOGOUT);
      navigate('/Negado', { replace: true })
      removeAuth();
    }
    
    return {
      credentials,
      removeAuth,
      reverseSignOn,
      remoteLogout,
      siglas,
      isAuthenticated,
      isAuthorized,
      isProcessingReverseSignOn,
    }
  }, [JSON.stringify(credentials)]);

  return(
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}



export default AuthProvider;