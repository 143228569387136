import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import Color from 'color';
import { defaultThemeColor } from 'ksk/styledComponents/themes';
import { isString } from 'lodash';
import React, { useMemo, useState } from 'react';
import KskMarkdown from '../KskMarkdown/KskMarkdown';
import KskHtmlRenderer from '../KskHtmlRenderer/KskHtmlRenderer';
import { useAtom } from 'jotai';
import { readOnlyModeAtom } from 'contexts/FeatureContext';

const borderRadius = 'calc((4 / 14) * 1rem)'
const backgroundColor = `${Color(defaultThemeColor).desaturate(.65).lightness(95).hsl()}`;
const color = `${Color(defaultThemeColor).lightness(15).hsl()}`;
const arrowColor = `${Color(defaultThemeColor).desaturate(.50).lightness(80).hsl()}`;

// make comments
const scaledTooltip = (scale = 1) => withStyles((theme) => ({
  tooltip: {
    borderColor: '#ddd',
    borderWidth: 'calc((1 / 14) * 1rem)',
    borderRadius: `${borderRadius} ${borderRadius} 0 0`,
    padding: '0',
    background: 'white',
    border: '1px solid rgba(0,0,0,.2)',
    borderRadius: borderRadius,
    minWidth: 'fit-content',
    fontSize: 'calc((14 / 14) * 1rem)',
    fontFamily: '"Source Sans Pro", sans-serif',
    

    scale: `${scale}`,
    '& .ksk-popover-section': {
      minWidth: '45ch',

      '&.ksk-popover--md': {
        minWidth: '60ch',
      },

      '&.ksk-popover--lg': {
        minWidth: '80ch',
      },

      '&.ksk-popover--xg': {
        minWidth: '120ch',
      },

      '&.ksk-popover--full': {
        minWidth: 'calc(100vw - 4em)',
      }
    },

    '& .ksk-popover-header': {
      padding: `.75em 1em`,
      margin: '0',
      fontSize: '14px',
      backgroundColor,
      borderBottom: '1px solid #ebebeb',
      borderRadius: `${borderRadius} ${borderRadius} 0 0`,
      color: 'rgb(51,51,51)',
      boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
      color, 
      
    },

    '& .ksk-popover-body': {
      backgroundColor: 'white',
      padding: '.75em 1em',
      boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
      color: 'rgb(51,51,51)',
      '&--no-footer' :{
        borderRadius: `0 0 ${borderRadius} ${borderRadius}`,  
      }
    },

    '& .ksk-popover-footer': {
      padding: '.35em 1em',
      borderTop: '1px solid #ebebeb',
      backgroundColor,
      borderRadius: `0 0 ${borderRadius} ${borderRadius}`,
      fontSize: 'calc((12 / 14) * 1rem)',
      boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
      color: 'rgb(51,51,51)',
    },

    '& .MuiTooltip-arrow': {
      color: arrowColor,
    }
  },
}))(Tooltip);

/* const HtmlTooltip = scaledTooltip(1);
const SmallHtmlTooltip = scaledTooltip(.75); */

const SimpleTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 'calc((13/14) * 1rem)'
  },
}))(Tooltip);


const KskTooltip = ({
  popover, 
  children, 
  title, 
  body, 
  footer, 
  size, 
  className, 
  style,
  markdownTitle, 
  markdownBody, 
  markdownFooter, 
  htmlBody,
  scale=1,
  bodyStyle,
  delay,
  ...other
} = {}) => {
  const sizeClass = ({
    'md': 'ksk-popover--md',
    'lg': 'ksk-popover--lg',
    'xg': 'ksk-popover--xg',
    'full': 'ksk-popover--full',
  })[size] ?? '';

  const sectionClass = ['ksk-popover-section', className, sizeClass].join(' ').trim();
  const [readOnlyMode] = useAtom(readOnlyModeAtom);

  const delayProps = (
    delay
    ? { enterDelay: delay, enterNextDelay: delay }
    : {}
  );

  const printBody = () => {
    if (markdownBody) {
      return <KskMarkdown markdown={body} />
    }
    if (htmlBody) {
      return <KskHtmlRenderer content={body} />
    }
    return body;
  }

  const HtmlTooltip = useMemo(() => scaledTooltip(scale), [scale]);
  
  return (<>
    {
      popover
      ? <HtmlTooltip
          title={
            <section className={sectionClass} style={style}>
              {
                title && 
                <header>
                  <h1 className="ksk-popover-header">{!!markdownTitle ? <KskMarkdown markdown={title} /> : title}</h1>
                </header>
              }

              <div 
                style={bodyStyle}
                className={
                  classNames({
                    "ksk-popover-body": true, 
                    "ksk-popover-body--no-footer": !footer 
                  })}>{printBody()}
                  </div>

              {
                footer && 
                <footer className="ksk-popover-footer">{!!markdownFooter ? <KskMarkdown markdown={footer} /> : footer}</footer>
              }
            </section>
          }

          {...delayProps}
          {...other}>
          {children}
        </HtmlTooltip>
      : <SimpleTooltip title={!!markdownTitle ? <KskMarkdown markdown={title} /> : title} {...delayProps} {...other}>{children}</SimpleTooltip>
  }
  </>);
};

const defaultTooltipProps = {
  interactive: false,
  arrow: true,
  placement:"bottom"
 }

export const ApplyTooltip = ({element, tooltipProps}) => {
  if (tooltipProps == null) {
    return element;
  }

  const _tooltipProps = (
    isString(tooltipProps)
    ? {...defaultTooltipProps, title: tooltipProps}
    : {...defaultTooltipProps, ...tooltipProps}
  );

  return (
     <KskTooltip {..._tooltipProps}>{element}</KskTooltip>
  );

}

export default KskTooltip;