

import { faKeyboard } from '@fortawesome/free-regular-svg-icons';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '@material-ui/core/TextField';
import { useBuildInputId } from 'ksk/styledComponents/components/KskForm/KskForm';
import React from 'react';
import { Controller, get } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { componentErrorMsg } from 'utils/utils';
import KskTooltipWrapper from '../KskPopover/KskTooltipWrapper';
import InputWithMask from './InputWithMask';
import KskInputHelper from '../KskInputHelper/KskInputHelper';
import { useAtom } from 'jotai';
import { readOnlyModeAtom } from 'contexts/FeatureContext';
import useSubmitKskFormOnShiftEnter from 'ksk/hooks/useSubmitKskFormOnShiftEnter';

const defaultCustomNumberFormatProps = {
  integerScale: 14,
}

const defaultNumberFormatProps = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  thousandsGroupStyle: 'thousand',
  decimalScale: 2,
  //fixedDecimalScale: '2',
  allowNegative: true,
  allowEmptyFormatting: false,
  isNumericString: true,
  displayType: 'input',
  type: 'text',
  //prefix: '',
  //suffix: '',
  //value: null,
  //defaultValue: null,
  //format:
  //removeFormatting: (formattedValue) => numericString
  //mask:
  //renderText
  //getInputRef
}

const NEGATIVE_VALUE_CLASS_NAME = 'numeric-input-negative-value';

export const KskInput = React.forwardRef(({
  autocomplete = 'off',
  label,
  labelIcon = faKeyboard,
  name,
  id, 
  submitOnEnter = false,
  //onKeyPress,
  form,
  tooltip,
  errors,
  className,
  required,
  optional,
  multiline,
  control,
  mask,
  unmask,
  numberFormat,
  helperText,
  alwaysShowHelperText,
  inputProps,
  onKeyDown,
  // propriedades de controle
  onChange, onPaste, onMouseDown, onFocus, onBlur, value, disabled, readOnly, maxLength,
  //
  ...otherProps
}, ref) => {
  
  const errorsObj = form?.errors || errors;  
  const hasErrors = errorsObj ? !!get(errorsObj, name) : false;

  //const handleSubmitOnEnter = handleSubmitOnEnterBuilder(submitOnEnter || multiline, onKeyPress);
  const dirtyClass = form?.formState?.dirtyFields?.[name] ? ' is-dirty' : '';
  const touchedClass = form?.formState?.touched?.[name] ? ' is-touched' : '';
  const _id = useBuildInputId({id, name});
  
  const _control = control || form?.control;
  const [readOnlyMode] = useAtom(readOnlyModeAtom);
  const _readOnly = readOnly || readOnlyMode;

  const onKeyDownHandler =  useSubmitKskFormOnShiftEnter({ preventEnter: (!submitOnEnter&&!multiline), onKeyDown });

  if (mask && numberFormat) {
    throw new Error(
      componentErrorMsg({
        componentName: 'KskInput', 
        name, id: _id, 
        message: `As propriedades "mask" e "numberFormat" são autoexcludentes. ` +
        `Use apenas uma delas, nunca as duas ao mesmo tempo.`
      })
    );
  }

  const labelWithIcon = (
    <>
      { disabled || _readOnly ? <FontAwesomeIcon icon={faBan}/> : <FontAwesomeIcon icon={labelIcon}/> } 
      <span> {label}</span>
    </>
  );

  const inputRefProp = {
    inputRef: (r) => {
      // eslint-disable-next-line no-unused-expressions
      form?.register(r);
      if (ref) {
        ref.current = r; // you can still assign to ref
      }
    }
  };

  const textFieldProps = {
    id:_id,
    label: labelWithIcon,
    className:`${className}${dirtyClass}${touchedClass}`,
    onKeyDown: onKeyDownHandler,
    autoComplete: autocomplete,
    multiline,
    error: hasErrors,
    helperText: <KskInputHelper {...{optional, required, hasErrors, name, errorsObj, helperText, readOnly:disabled || _readOnly}} />,
    inputProps: {...inputProps, ...{maxLength}},
    ...otherProps
  };

  const defaultInput = () => (
    <TextField name={name} 
      {...(mask ? {} : inputRefProp)}
      {...textFieldProps} 
      {...{onChange, onFocus, value, disabled }}
      InputProps={{...{onPaste, onMouseDown, onFocus, onBlur, readOnly: _readOnly}}}
      disabled={disabled}
    /> 
  );



  // Avaliar no futuro 
  const maskedInput2 = () => (
    <Controller
      control={_control}
      name={name}
      defaultValue={''}
      render={({ 
        onChange: _onChange, 
        onBlur: _onBlur, 
        ...rest
        }) => (
        <InputWithMask 
          mask={mask} 
          unmask={unmask}
          defaultValue={form?.watch(name) ?? ''}
          inputProps={ {...textFieldProps}}
          onBlur={e => { (onBlur && onBlur(e)); _onBlur(e)}}
          onChange={e => { (onChange && onChange(e)); _onChange(e)}}
          {...{onPaste, onMouseDown, onFocus, value, disabled,}}
          {...rest}
          readOnly= {_readOnly}/>
      )}
    />
  );

  const numberInput = () => {
    const params = {...defaultCustomNumberFormatProps, ...numberFormat};
    const intScale = params?.integerScale;
    const isIntScaleValidFn = (values) => values?.value?.split('.')[0].length <= intScale;
    const allowNegativeFn = params?.allowNegative
      ? () => true
      : (values) => values?.value?.indexOf('-') < 0

    // Override do comportamento original do componente
    // pois se já estivesse negativo na hora do carregamento, o input transformava o valor
    // em um número positivo, o que não é um comportamento adequado.  
    const isAllowedFn = numberFormat?.isAllowed
      ? (values) => numberFormat.isAllowed(values) && isIntScaleValidFn(values) && allowNegativeFn(values)
      : (values) => isIntScaleValidFn(values) && allowNegativeFn(values);

    const {integerScale, ..._numberFormat} = numberFormat; 
    const numberFormatProps = {..._numberFormat, isAllowed: isAllowedFn, allowNegative: true}
   
    return (
      <Controller
        control={_control}
        name={name}
        defaultValue={''}
        render={({ 
          onChange: _onChange, 
          onBlur: _onBlur, 
          ...rest
          }) => (
            <NumberFormat 
              onValueChange={(values) => { 
                onChange && onChange(values?.value);
                _onChange(values?.value);
              }}
              customInput={TextField}
              {...textFieldProps} 
              {...{name, onFocus, disabled }}
              InputProps={{...{onPaste, onMouseDown, onFocus, onBlur, readOnly: _readOnly}}}
              inputProps={{...{maxLength}}}
              {...{...defaultNumberFormatProps, ...numberFormatProps}}
              {...rest}
            />
          )}
      />
  )};

  const renderedInput = mask 
    ? maskedInput2() 
    : numberFormat 
      ? numberInput()
      : defaultInput();

  return (
    tooltip 
      ? <KskTooltipWrapper tooltipProps={tooltip}>{renderedInput}</KskTooltipWrapper>
      : renderedInput
  );   
});


export const KskInputSimples = React.forwardRef(({
  autocomplete = 'off',
  className,
  name,
  phone,
  tablet,
  desktop,
  errors,
  ...otherProps
}, ref) => {
  
  const deviceClass = (deviceName, cols) => Number(cols) ? `${deviceName}-${cols}`: '';
  const deviceClasses = [deviceClass('phone', phone), deviceClass('tablet', tablet), deviceClass('desktop', desktop)].join(' ');   

  const classes = `${className} ${deviceClasses}`
 
  return (
    <TextField name={name} ref={ref} className={classes} autoComplete={autocomplete} 
    
    error={!!errors?.[name]}
    helperText={errors?.[name]?.message || JSON.stringify(errors || {limpo: 'limpo'})}
    
    {...otherProps} /> 
  ) 
});

export default KskInput;

