import React from 'react';
import styled from 'styled-components';
import accessDeniedIcon from 'ksk/assets/template/access-denied.svg';

const PageNotFoundStyle = styled.div.attrs()`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4em;

  .page-notfound__container {
    margin-top: 6em;
    background: #fff8;
    display: flex;
    grid-gap: 3em;
    justify-content: center;
    height: fit-content;
    padding: 3em;
    max-width: 52em;
    box-shadow: 0.25em 0.25em 0.25em #0005;
  }


  .page-notfound__icon {
    width: 10rem;
  }

  .page-notfound__h1 {
    font-size: 3rem;
    margin: auto 0;
    font-weight: 100;
  }

`;

const PageNotFound = () => 
  <PageNotFoundStyle>
    <div className="page-notfound__container">
      <img className="page-notfound__icon" src={accessDeniedIcon} alt="Ícone de página não encontrada" />
      <div>
        <h1 className='page-notfound__h1'>Página não encontrada</h1>
      </div>
    </div>
  </PageNotFoundStyle>
  
export default PageNotFound;