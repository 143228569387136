import { useState } from 'react';
import { convertBase } from 'utils/stringUtils';

export function* generateSequencialId() {
  let counter = 0;
  let overflowCounter = 0;

  while (true) {
    if (counter >= Number.MAX_SAFE_INTEGER - 1) {
      // Se o contador for maior que o máximo permitido
      // tem que reiniciar o contador e incrementar a quantidade
      // de vezes que deu overflow.
      overflowCounter++;
      counter = 0;
    }

    //incrementa o contador
    counter++;

    // Só mostra o overflow se alguma vez deu overflow
    const overflowValue = overflowCounter ? `${overflowCounter}-` : '';

    // Transforma o número decimal em uma String com números
    // letras minúsculas e maiúsculas para deixar a string
    // mais compactada, com menos caracteres.
    const counterValue = convertBase(String(counter), 10, 62);

    // Id sequencial único.
    const id = `${overflowValue}${counterValue}`;

    yield id; // Pausa a execução e retorna o valor de id
  }
}

/**
 * Gerador global de ids.
 */
export const globalKskIdGenerator = generateSequencialId();

/**
 * Hook que gera um id para elementos do DOM.
 * @param {*} prefix prefixo a ser colocado antes do id
 * @returns Id único para ser utilizado no DOM.
 */
function useKskId(...prefix) {
  const [id] = useState(() => globalKskIdGenerator.next().value);

  if (prefix.length === 0) {
    return id;
  }

  if (prefix.length === 1) {
    return `${prefix}-${id}`;
  }

  return prefix.map((currentPrefix) => `${currentPrefix}-${id}`);
}

export default useKskId;
