import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import KskButton from 'ksk/styledComponents/components/KskButton/KskButton';
import { kebabCase } from 'lodash';
import React from 'react';

const ConfirmationDialog = ({ open, title, message, onConfirm, onDismiss }) => {
  return (
    <Dialog open={open} onClose={onDismiss}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{paddingTop: '2em', minWidth: '350px'}}>{message}</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <KskButton primary icon={ faTimes } onClick={onDismiss}
          id={`btn-${kebabCase(title)}-resposta-nao`}
          label="Não"
        />

        <KskButton secondary icon={ faCheck } onClick={onConfirm}
          id={`btn-${kebabCase(title)}-resposta-sim`}
          label="Sim"
        />
      </DialogActions>
    </Dialog>
  );
};

const ConfirmationDialogContext = React.createContext({});

const ConfirmationDialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState({});

  const openDialog = ({ title, message, actionCallback }) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialog = () => {
  const { openDialog } = React.useContext(ConfirmationDialogContext);

  const getConfirmation = ({ ...options }) =>
    new Promise((resolve) => {
      openDialog({ actionCallback: resolve, ...options });
    });

  return { getConfirmation };
};

export default ConfirmationDialog;
export { ConfirmationDialogProvider, useConfirmationDialog };
