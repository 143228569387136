

function createSingularPluralVariations(word) {
  // Regex para String no formato [plural], criando grupo de captura para o plural
  // regex original sem escape para os colchetes /\[([^|\]]+)\]/g;
  const onlyPluralRegex =   /(?<!\/)\[([^|\]]+)(?<!\/)\]/g;
  
  if (word.match(onlyPluralRegex)) {
    return [word.replace(onlyPluralRegex, ""), word.replace(onlyPluralRegex, "$1")];
  }
  
  // Regex para a String no formato [singular|plural], criando grupo de captura para o singular e e plural
  // regex orifinal sem escape /\[([^|]*)\|([^\]]*)\]/g;
  const singularPipePluralRegex =   /(?<!\/)\[([^|]*)\|([^\]]*)(?<!\/)\]/g


  if (word.match(singularPipePluralRegex)) {
    return [word.replace(singularPipePluralRegex, "$1"), word.replace(singularPipePluralRegex, "$2")];
  }

  // Detectar cochetes escapados, precedidos por barra "/", e retirar a barra
  const escapedRegex = /\/[[\]]/g;
  const escaped = word.replace(escapedRegex, (match) => match.replace('/', ''));

  return [escaped, escaped]
}

 export function singularAndPlural(text) {
  const str = text ?? ''

  const pluralData = str.split(' ').reduce((variationsList, word) => {
    const variations = createSingularPluralVariations(word); 
    variationsList[0].push(variations[0]); // Singular
    variationsList[1].push(variations[1]); // Plural
    return variationsList;
  }, [[], []]);

  return pluralData.map((variations) => variations.join(" "));
}


