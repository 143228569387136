import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import  Protected from 'auth/Protected';
import React from 'react';
import { Link } from 'react-router-dom';
import { S } from './KskButton.styles';
import { ApplyTooltip } from '../KskPopover/KskTooltip';

function KskButton({
  type = 'button',
  routerLink,
  label,
  className = '',
  hasValidationErrors,
  icon,
  iconClassName = '',
  iconRight,
  floatIcon,
  primary,
  secondary,
  warn,
  danger,
  info,
  small,
  mini,
  large,
  disabled,
  busy,
  iconButton,
  requires,
  tooltip,
  hidden,
  containerProps,

  ...otherProps
}) {
  const containerClasses = classNames({'has-validation-errors': hasValidationErrors});
  const buttonClasses =  [
    'ksk-button', 
    className,
    classNames({
      'ksk-button--router-link': routerLink,
      'ksk-button--with-icon' : icon && !iconRight,
      'ksk-button--with-icon-right' : icon && iconRight,
      'ksk-button--primary': primary,
      'ksk-button--secondary': secondary,
      'ksk-button--warn': warn,
      'ksk-button--danger': danger,
      'ksk-button--info': info,
      'ksk-button--enabled': !disabled,
      'ksk-button--disabled': disabled,
      'ksk-button--small': small,
      'ksk-button--mini': mini,
      'ksk-button--large': large,
      'ksk-button--icon-button': iconButton,

    })
  ].join(' ');

  const floatIconContent = ( 
    floatIcon
    ? <span className={`ksk-button__float-icon ksk-button__float-icon--${floatIcon.position}`}><FontAwesomeIcon  icon={floatIcon.icon} /></span> 
    : null
  );

  const buttonContent = (
    <>
      { icon && <>
        <span className="ksk-button__icon"> 
          {
            busy
            ? <FontAwesomeIcon className="ksk-button__icon--busy" icon={faCog} />
            : (
              <>
                <FontAwesomeIcon icon={icon} className={iconClassName} />
                {floatIconContent}
              </>
            )
          }
          </span>
      </>
      }
      {
        !iconButton &&
        <span className="ksk-button__label">{ label }</span>
      }
    </>
  );
 
  const _disabled = disabled || busy;



  const outputButton = (
    <S.Div className={containerClasses} {...containerProps}> 
      <ButtonWithTooltip 
        tooltipProps={tooltip}
        disabled={_disabled} 
        routerLink={routerLink}
        buttonClasses={buttonClasses}
        buttonContent={buttonContent}
        hidden={hidden}
        type={type}
        {...otherProps}
      />
    </S.Div>
  );

  return (
    requires?.length
    ? <Protected requires={requires}>{outputButton}</Protected>
    : outputButton
  )
}

/**
 * Necessário porque botões desabilitados não disparam eventos, e por isso tem que ser envoltos
 * em algum wrapper para que os tooltips sejam exibidos sem problemas.
 * @param {*} param0 
 * @returns 
 */
function ButtonWithTooltip({tooltipProps, disabled, routerLink, buttonClasses, buttonContent, hidden, type, ...otherProps}) {

  // Se tiver router link então deve ser um componente Link do ReactRouter estilizado como um botão
  const element = (
    routerLink
    ? <Link {...otherProps} className={ buttonClasses }>{buttonContent}</Link>
    : <button disabled={disabled} hidden={hidden} className={ buttonClasses } type={type} {...otherProps}>
        {buttonContent}
      </button>
  )

  return (
    // ApplyTooltip irá envolver o elemento em uma tooltip se tooltipProps não for nulo.
    <ApplyTooltip 
      tooltipProps={tooltipProps} 
      element={
        disabled
        ? <span className="ksk-button__wrapper-for-disabled">{element}</span>
        : element
      }/>
  );
}

export default KskButton
