import MainContent from 'components/frames/MainContent/MainContent';
import MainFooter from 'components/frames/MainFooter/MainFooter';
import MainHeader from 'components/frames/MainHeader/MainHeader';
import MainRouter from 'components/frames/MainRouter/MainRouter';
import LoadingSpinner from 'components/frames/LoadingSpinner/LoadingSpinner';
import React from 'react';
import { Route, Routes } from 'react-router';

import LoginErro from 'pages/login_erro/LoginErro';

const TemplateDefault = () => (
  <>
    <section className="main-frame">
      <LoadingSpinner />
      <MainHeader />

      <MainContent>
        <MainRouter />
      </MainContent>

      <MainFooter />
    </section>
  </>
);

function MainFrame() {
  return (
    <Routes>
      <Route path='/Negado' element={<LoginErro />} />
      <Route path='*' element={<TemplateDefault />} />
    </Routes>

  )
}

export default MainFrame;
