//organizar os imports por tipo css ou js e externo e internos ?
import React from 'react';
import 'bootstrap/scss/bootstrap.scss';
import MainFrame from './components/frames/MainFrame/MainFrame';
import { KskProvider } from 'ksk/styledComponents/KskContext';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

import { ConfirmationDialogProvider } from 'components/Dialog/ConfirmationDialog';
import AuthProvider from 'auth/AuthContext';
import useGlobalStamper from 'localization/useGlobalStamper';
import useGlobalMessages from 'localization/useGlobalMessages';


//pq ConfirmationDialogProvider agrupa mainframe? semanticamente estranho?
function App() {
  useGlobalStamper();
  useGlobalMessages();

  return (
    <>
    <KskProvider>   
      <AuthProvider>
          <ConfirmationDialogProvider>
            <ToastContainer />
            <MainFrame />
          </ConfirmationDialogProvider>
        </AuthProvider>
    </KskProvider>
    </>
  );
}

export default App;
