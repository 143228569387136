import Color from 'color';

export const px2em = (px, base = 14) => `${px / base}em`;

// color: ${props => Color(props.ksk.themeColor).rotate(-30).hex()  };

export const bestContrast = (baseColor, ...colors) => {
  const baseColorObj = Color(baseColor);

  if (!colors || !colors.length) {
    return baseColorObj.isLight() ? 'black' : 'white';
  }

  return colors.reduce((choosen, actualColor) => {
    const actualColorObj = Color(actualColor);
    const choosenColorObj = Color(choosen);

    if (baseColorObj.contrast(actualColorObj) > baseColorObj.contrast(choosenColorObj)) {
      return actualColor;
    } else {
      return choosen;
    }
  }, colors[0]);
};

