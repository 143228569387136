export const formulaInMathJsFormat = (formulaInEvalExFormat) => {
  return (
    formulaInEvalExFormat.replace(/<>/g, '!=')
           .replace(/\|\|/g, 'or')
           .replace(/\&\&/g, 'and')
  );
}

export const strikeWith = (...keyTypes) => ({
  then: (then) => keyTypes.reduce((output, keyType) => ({...output, [keyType]: then}), {})
});

export const buildActionMap = (...strikes) => strikes.reduce((output, strike) => ({...output, ...strike}), {});

