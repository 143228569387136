import { features } from 'config/features.config';
import React, { Suspense, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import PagePlaceholder from '../PagePlaceholder/PagePlaceholder';
import PageNotFound from 'pages/page_notfound/PageNotFound';
import { useAuth } from 'auth/AuthContext';
import ProtectedRoute from 'auth/ProtectedRoute';


function MainRouter() {
  const {isAuthorized} = useAuth();
  const routes = useMemo(() => buildRoutes(), [isAuthorized]) ;

  function buildRoutes() {
    return features.sort((fa, fb) => fb.route.path.length - fa.route.path.length || fa.route.path.localeCompare(fb))
      .map((feature) => {
        const DynamicElement = feature.route.element;
        return (
          <ProtectedRoute key={feature.route.path}
            path={feature.route.path} 
            element={<DynamicElement />}
            requires={feature.requires}/>
        )
      });
  }

  return (
    <>
      <Suspense fallback={<PagePlaceholder/>}>
        <Routes> 
          {routes}     
          <Route path='*'  element={<PageNotFound/>}  />
        </Routes>
      </Suspense>
    </>
  );
}

export default MainRouter;
