import { useCallback, useEffect, useRef, useState } from "react";
import TokenService, { jwtConfig } from "services/TokenService";
import { timeInMs } from "utils/date-utils";
import { debugMode } from "utils/debug-utils";
import { decode, doNothing } from "utils/utils";

const sessionConfig = {
  timeoutInMs: timeInMs('00:30:00')
};

const useAuthTimeout = ({timeInMs, initialTimestamp = 0} = {}) => {
  const [isReached, setIsReached] = useState(false);
  const timeoutId = useRef(null);

  const configTimeout = useCallback(({timestamp, isCancel = false} = {}) => {
    timeoutId.current != null && clearTimeout(timeoutId.current);
    isReached && setIsReached(false);

    if (isCancel) {
      timeoutId.current = null;
      return;
    }
    
    const now = new Date().getTime();
    const delta = now - timestamp;
    const time = timestamp
      ? timeInMs - delta 
      : timeInMs;
    
    timeoutId.current = (
      setTimeout(() => setIsReached(true), time)
    );
  }, [timeInMs]);

  useEffect(() => {
    configTimeout({timestamp: initialTimestamp});
  }, [configTimeout]);
 

  const reset = useCallback(configTimeout, [configTimeout]);
  const cancel = useCallback(() => configTimeout({isCancel: true}), [configTimeout]);

  return [
    isReached,
    cancel,
    reset,
  ];
}


const useAuthToken = () => {
  const removeAuth = TokenService.removeAuthData;
  const [auth, setAuth] = useState(TokenService.getAuthData());
  const { jwt } = auth ?? {};

  const [sessionTimeout, cancelSessionTimeout, resetSessionTimeout] = useAuthTimeout({
    timeInMs: sessionConfig.timeoutInMs,
    initialTimestamp: auth?.timestamp
  });

  useEffect(() => {
    const authDataSubscription = TokenService.authDataChanges$.subscribe(
      (tokenServiceAuthData) => {
        debugMode(() => console.log('auth changed', tokenServiceAuthData));
        setAuth(tokenServiceAuthData);
      }
    );

    const watchAuthChangesInAnotherWindows = (e) => { 
      const {key, newValue } = e ?? {};
      try {
        const authDataChangedByAnotherWindow = JSON.parse(newValue);
        if (key === jwtConfig.key) {
          debugMode(() => console.log('auth changed by antoher window', authDataChangedByAnotherWindow));
          setAuth(authDataChangedByAnotherWindow);
        }
      } catch (e) {
        console.warn('Autenticação inválida ignorada');
      }
    };

    window.addEventListener('storage', watchAuthChangesInAnotherWindows, false);

    return () => {
      authDataSubscription.unsubscribe();
      window.removeEventListener('storage', watchAuthChangesInAnotherWindows, false);
    };
  }, []);

  useEffect(() => {
    auth 
    ? resetSessionTimeout({timestamp: auth?.timestamp})
    : doNothing(); // cancelSessionTimeout()
  }, [auth])

  useEffect(() => {
    if (sessionTimeout) {
      removeAuth();
    }
  }, [sessionTimeout])


  const payload = () => {
    try {
      return decode(jwt, jwtConfig.prefix) || decode(jwt);
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  const getCredentials = () => payload()?.jsonSiconfi;

  return {
    jwt,
    removeAuth,
    getCredentials,
  }
}

export default useAuthToken;