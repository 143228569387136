import Color from 'color';
import { px2em } from 'ksk/styledComponents/functions';
import { device, hoverActiveFocus } from 'ksk/styledComponents/mixins';
import { defaultThemeColor, kskTheme } from 'ksk/styledComponents/themes';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const menuBgColor = theme('mode', {
  padrao: Color(defaultThemeColor).saturationl(30).lightness(90).hsl(),
  altoContraste: 'black'
});

const menuFontColor = theme('mode', {
  padrao: Color(defaultThemeColor).desaturate(.20).darken(.15).hsl(),
  altoContraste: 'lime'
});

const hoverToggleMenuColor = theme('mode', {
  padrao: Color(defaultThemeColor).darken(.15).hsl(),
  altoContraste: 'magenta'
});

const toggleSubmenuColor = theme('mode', {
  padrao: Color(defaultThemeColor).saturate(.1).darken(.20).hsl(),
  altoContraste: 'cyan'
});

const menuBorderColor = theme('mode', {
  padrao: defaultThemeColor,
  altoContraste: 'white'
});

const Aside = styled.aside`
  position: relative;
  position: fixed;
  top: ${px2em(178)};
  z-index: 20;
  background: #f003;
  right: 100%;

  ${device.onlyPhone(css`
    top: ${px2em(98)};
  `)}
    
  .main-menu__container {
    background: #f003;
    position: relative;
    top: 0;
    right: 0;
    line-height: 1;
    padding: ${px2em(8)} 0; 
    background: ${menuBgColor};  
    color: ${menuFontColor};
    border: ${px2em(6)} solid;
    border-color: ${ menuBorderColor};
    border-radius: 0 0 ${px2em(12)} 0;
    transition: all 0.3s ease;
    
    box-shadow: ${px2em(3)} ${px2em(3)} ${px2em(8)} ${px2em(1)} rgba(0,0,0,.2);
    
    display: block;
  }

  &.main-menu--expanded {
    .main-menu__container {
      right: -100%;
      ${device.exceptPhone(css``)}
    }
  }

  .main-menu__toggle-btn {
    position: absolute;
    font-size: ${px2em(28)};    
    background: ${kskTheme.themeColor};  
    transition: all 0.3s ease;  
    
    box-shadow: ${px2em(4, 28)} ${px2em(4, 28)} ${px2em(12, 28)} ${px2em(2, 28)} rgba(0, 0, 0, 0.15);
    border: ${px2em(3)} solid ${menuBorderColor};
    
      width: ${px2em(48, 28)};
      height: ${px2em(56, 28)};    
      padding: ${px2em(4, 28)};
      text-align: left;
      top: ${px2em(-6, 28)};
      right: ${px2em(-52, 28)};
      border-radius: 0 ${kskTheme.borderRadius} ${kskTheme.borderRadius} 0;
    ${device.exceptPhone(css`
    `)}

    ${hoverActiveFocus(css`
      background: ${hoverToggleMenuColor};
      outline: none;
    `)}
  }

  .main-menu__icon {
    width: 100%;
    height: 100%;
  }

  .main-menu__features {
    min-width: 30ch;
    min-height: 3em;
  }

  .main-menu__features,
  .main-menu__list-items {
    padding: 0;
    margin: 0;
  }

  .main-menu__list-items {
    transition: max-height .3s ease-out;
    height: auto;
    max-height: 100vh;
    overflow: hidden;
  }

  .main-menu__list-items--collapsed {
    max-height: 0;
  }

  .main-menu__submenu,
  .main-menu__item {
    list-style: none;
  }

  .main-menu__submenu-label,
  .main-menu__item-label {
    margin-left: .4em;
  }

  .main_menu__toggle-submenu {
    display: flex;
    width: 100%;
    background-color: transparent;
    border: none;
    text-align: left;
  }

  .main_menu__toggle-submenu,
  .main-menu__item {
    color: ${toggleSubmenuColor};
    
    box-sizing: border-box;
  }

  .main-menu__item {
    display: block;
    padding: 0;
    display: flex;
  }

  .main-menu__item__link {
    padding: 0.5em 1.5em 0.5em 1.5em;
    white-space: nowrap;

    width: 100%;
    ${hoverActiveFocus(css`
      background: ${kskTheme.themeColor};
      color: white;
      outline: none;
    `)}
  }


  .main_menu__toggle-submenu {
    padding: ${px2em(4)} ${px2em(8)};
    font-weight: bold;
  }

  .main-menu__item {
    /* padding: ${px2em(2)} ${px2em(14)} ${px2em(4)} ${px2em(12)}; */
  }

`;

export const S = {
  Aside
};
