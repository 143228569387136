import { getFeature } from 'config/features.config';
import { getMenu } from 'config/main-menu.config';
import React, { useContext, useMemo } from 'react';
import { atom, useAtom, Provider as JotaiProvider } from 'jotai'
import { focusAtom } from 'jotai/optics'


const FeatureContext = React.createContext();

export const featureStoreAtom = atom({});

export const readOnlyModeAtom = focusAtom(featureStoreAtom, (optic) => optic.prop('readOnlyMode'));

export function useFeature() {
  return useContext(FeatureContext);
}

function FeatureProvider({featureId, children}) {
  const feature_ = getFeature(featureId);
  feature_.menu = getMenu(featureId);
  const feature = useMemo(() => feature_, [featureId]);

 /*  useEffect(() => {
    console.log('feature', feature);
  }, []); */

  const emptyStore = [
    featureStoreAtom, { readOnlyMode: false }
  ];

  return(
    <FeatureContext.Provider value={feature}>
      <JotaiProvider initialValues={[emptyStore]}>
        {children}
      </JotaiProvider>
    </FeatureContext.Provider>
  );
}

export default FeatureProvider;