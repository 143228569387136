import React from 'react';
import { S } from './MainContent.styles';

function MainContent({children}) {
  return (
    <S.Main tabIndex="0" id="main-content">      
      {children}
    </S.Main>
  )
}

export default MainContent;