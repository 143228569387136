import TextField from '@material-ui/core/TextField';
import { isFunction } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import InputMask from "react-input-mask";

/**
 * TODO verificar depois a migração para outra lib, pois "react-input-mask" não é mais atualizada.
 * Sugestão react-imask
 */
  

// Input mascarado, que pode tirar a máscara no onChange
const InputWithMask = React.forwardRef(({
  mask, 
  unmask, 
  defaultValue,
  onChange, onPaste, onMouseDown, onFocusCapture, onFocus, onBlur, value, disabled, readOnly, 
  inputProps
}, ref) => {
  const [unmaskOnMount, setUnmaskOnMount] = useState(false);
  const transform = (raw) => (raw != null) && mask && (unmask ? unmask(raw) : raw);

  const isEmptyOnFocusRef = useRef(true);
  
  const onChangeHandler = (e) => {
    if (readOnly) {
      return;
    }
    
    onChange(transform(e?.target?.value));
  }

  const onFocusCaptureHandler = (e) => {
    isFunction(onFocusCapture) && onFocusCapture(e);
    isEmptyOnFocusRef.current = !e.target.value || transform(e.target.value) === '';
  }

  const onFocusHandler = (e) => {
    isFunction(onFocus) && onFocus(e);

    const {target } = e
    if (!isEmptyOnFocusRef.current) {
      setTimeout(() => {
        target.setSelectionRange(0, target?.value?.length)
      },150)
    }
  }

  const onBlurHandler = (e) => {
    isFunction(onBlur) && onBlur(e);
    if (transform(e.target.value) === '') {
      requestAnimationFrame(() =>  {
        if (ref.current) {
            ref.current.value = ''
        }
      })
    } 
  }

  useEffect(()=> {
    unmask && setUnmaskOnMount(true);
  }, []);

  useEffect(()=> {
    unmaskOnMount && onChange(transform(value));
  },[unmaskOnMount]);

  if (value === null || value.length === 0) value = defaultValue;
  const inputMaskProps = {onMouseDown, value, disabled, readOnly, defaultValue};

  return (
    <InputMask 
      inputRef={ref}
      mask={isFunction(mask) ? mask(ref?.current?.value) : mask} 
      {...inputMaskProps}
      onChange={onChangeHandler}
      onFocusCapture={onFocusCaptureHandler}
      onFocus={ onFocusHandler }
      onBlur={ onBlurHandler }
      
      >
        <TextField {... inputProps} readOnly={readOnly}/>
    </InputMask>
  );
});

export default InputWithMask;

